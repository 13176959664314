import { BaseElement } from '../index';
import Quantity from '../types/Quantity';
import merge from 'lodash/merge';

/**
 * @property {Quantity} low
 * @property {Quantity} high
 */
export default class Range extends BaseElement {
    static __className = 'Range';

    __objectStructure = {
        low: Quantity,
        high: Quantity,
    };

    constructor(constructJson, className = 'Range') {
        super(constructJson, className);

        this.createAndPopulateStructure(this.__objectStructure, constructJson, this.__objectDefaults);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
