import { DomainResource } from './index';
import OperationOutcomeIssue from './OperationOutcomeIssue';

import merge from 'lodash/merge';

export default class OperationOutcome extends DomainResource {
    static __className = 'OperationOutcome';

    __objectStructure = {
        issue: [OperationOutcomeIssue],
    };

    constructor(constructJson, className = 'OperationOutcome') {
        super(constructJson, className);

        this.createAndPopulateStructure(this.__objectStructure, constructJson);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
