import { BackboneElement } from './index';
import merge from 'lodash/merge';

export default class AppointmentServiceModifier extends BackboneElement {
    static __className = 'AppointmentServiceModifier';

    __objectStructure = {
        code: String,
        position: Number,
    };

    constructor(constructJson, className = 'AppointmentServiceModifier') {
        super(constructJson, className);

        this.createAndPopulateStructure(this.__objectStructure, constructJson);

        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
