import merge from 'lodash/merge';
import { DomainResource } from './index';

export default class Concept extends DomainResource {
    static __className = 'Concept';

    __objectStructure = {
        code: String,
        definition: String,
        deprecated: Boolean,
        display: String,
        hierarchy: [String],
        system: String,
        valueset: [String],
    };

    constructor(constructJson, className = 'Concept') {
        super(constructJson, className);
        this.createAndPopulateStructure(this.__objectStructure, constructJson, this.__objectDefaults);

        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
