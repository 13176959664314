import createFhirResource from './FhirResourceFactory';
import { BackboneElement } from './index';
import BundleEntryRequest from './BundleEntryRequest';
import BundleEntrySearch from './BundleEntrySearch';
import BundleEntryResponse from './BundleEntryResponse';
import Resource from './Resource';

import merge from 'lodash/merge';

export default class BundleEntry extends BackboneElement {
    static __className = 'BundleEntry';

    __objectStructure = {
        link: [String], // Links related to this entry
        fullUrl: String, // URI for resource (Absolute URL server address or URI for UUID/OID)
        resource: Resource, // A resource in the bundle
        search: BundleEntrySearch, // Search related information
        request: BundleEntryRequest, // Additional execution information (transaction/batch/history)
        response: BundleEntryResponse, // Results of execution (transaction/batch/history)
    };

    constructor(constructJson, className = 'BundleEntry') {
        super(constructJson, className);

        this.createAndPopulateStructure(this.__objectStructure, constructJson);

        // Re-define getter and setter for resource
        Object.defineProperty(this, 'resource', {
            get() {
                return this.__resource;
            },
            set(value) {
                if (value instanceof Resource) {
                    this.__resource = value;
                    return;
                }

                if (value.resourceType) {
                    this.__resource = createFhirResource(value.resourceType, value);
                } else {
                    this.__resource = value;
                }
            },
        });

        if (constructJson && 'resource' in constructJson) {
            this.resource = constructJson.resource;
        }

        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
