import merge from 'lodash/merge';
import { Element } from '../index';

export default class Money extends Element {
    static __className = 'Money';

    __objectStructure = {
        value: Number, // Numerical value (with implicit precision)
        currency: String, // ISO 4217 Currency Code
    };

    constructor(constructJson, className = 'Money') {
        super(constructJson, className);

        this.createAndPopulateStructure(this.__objectStructure, constructJson);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
