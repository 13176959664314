import { BackboneElement } from './index';

import get from 'lodash/get';
import merge from 'lodash/merge';

export default class HoursOfOperation extends BackboneElement {
    static __className = 'HoursOfOperation';

    constructor(hoursOfOperationJson, className = 'HoursOfOperation') {
        // ToDo: Nothing was being passed to super
        super(hoursOfOperationJson, className);

        this._daysOfWeek = get(hoursOfOperationJson, 'daysOfWeek', []);

        this._allDay = get(hoursOfOperationJson, 'allDay', undefined);
        this._openingTime = get(hoursOfOperationJson, 'openingTime', undefined);
        this._closingTime = get(hoursOfOperationJson, 'closingTime', undefined);

        this.originalObjJson = this.toJSON();
    }

    // Properties

    get daysOfWeek() {
        return this._daysOfWeek;
    }

    set daysOfWeek(value) {
        if (Array.isArray(value)) {
            this._daysOfWeek = value;
            return;
        }

        this._daysOfWeek.push(value);
    }

    get allDay() {
        return this._allDay;
    }

    set allDay(value) {
        this._allDay = value;
    }

    get openingTime() {
        return this._openingTime;
    }

    set openingTime(value) {
        this._openingTime = value;
    }

    get closingTime() {
        return this._closingTime;
    }

    set closingTime(value) {
        this._closingTime = value;
    }

    toJSON() {
        return merge(
            super.toJSON(this),
            this.removeUndefinedObjProps({
                daysOfWeek: this.daysOfWeek,
                allDay: this.allDay,
                openingTime: this.openingTime,
                closingTime: this.closingTime,
            }),
        );
    }
}
