import { DomainResource } from './index';
import merge from 'lodash/merge';
import Reference from './types/Reference';
import Coding from './types/Coding';
import BN_SuperBillInsurance from './BN_SuperBillInsurance';
import BN_SuperBillOccurenceCodeList from './BN_SuperBillOccurenceCodeList';
import BN_SuperBillOccurenceSpanCodeList from './BN_SuperBillOccurenceSpanCodeList';
import BN_SuperBillValueCodeList from './BN_SuperBillValueCodeList';
import BN_SuperBillProcedureCode from './BN_SuperBillProcedureCode';
import BN_SuperbillDiagnosis from './BN_SuperBillDiagnosis';
import BN_SuperBillSelf from './BN_SuperBillSelf';

export default class BN_SuperBill extends DomainResource {
    static __className = 'BN_SuperBill';

    __objectStructure = {
        patient: Reference, // "Patient"
        encounter: Reference, // "Encounter"
        episodeOfCare: Reference, // "EpisodeOfCare"
        renderingProvider: Reference, // "Practitioner"
        encounterCode: Coding,
        encounterType: Coding,
        encounterPriority: Coding,
        referringProvider: Reference, // "Practitioner"
        supervisingProvider: Reference, // "Practitioner"
        billingProvider: Reference, // "Organization", "Practitioner"
        insurance: [BN_SuperBillInsurance],
        comments: String,
        providerSignature: Boolean,
        patientSignature: Boolean,
        signatureDate: String,
        status: String, // Draft, Submitted
        statusReason: String, // Provider-InProgress/Provider-Complete/Biller-InProgress/Biller-Complete
        claimType: Coding, // "Concept"
        admissionStartDate: String,
        admissionHour: String, // 24 hour format
        dischargeHour: String, // 24 hour format
        admissionPriority: Coding, // "Concept"
        admissionOrigin: Coding, // "Concept"
        dischargeStatus: Coding, // "Concept"
        diagnosisAndProcedureCodeQualifierCode: String,
        principalDiagnosisCode: Coding,
        otherDiagnosisCode: [Coding],
        admittingDiagnosisCode: Coding,
        patientReasonDiagnosisCode: Coding,
        prospectivePaymentSystemCode: Coding,
        principleProcedureCode: Coding,
        principleProcedureDate: String,
        billType: Coding, // "Concept"
        conditionCode: [Coding], // "Concept"
        occurenceCodeList: [BN_SuperBillOccurenceCodeList],
        occurenceSpanCodeList: [BN_SuperBillOccurenceSpanCodeList],
        valueCodeList: [BN_SuperBillValueCodeList],
        treatmentAuthorizationCode: String,
        SuperBillType: String, // Professional ,Institutional
        otherProcedureCode: [BN_SuperBillProcedureCode],
        isLineItemSelected: Boolean,
        class: Coding,
        facility: Reference, // "Facility"
        claims: [Reference], // "claims"
        brand: Reference,
        financialClass: Reference,

        placeOfService: Coding,
        dateOfServiceStart: String,
        dateOfServiceEnd: String,
        diagnosis: [BN_SuperbillDiagnosis],
        selfPay: BN_SuperBillSelf,
    };

    constructor(constructJson, className = 'BN_SuperBill') {
        super(constructJson, className);
        this.createAndPopulateStructure(this.__objectStructure, constructJson);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
