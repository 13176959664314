import { DomainResource } from './index';
import merge from 'lodash/merge';
import Reference from './types/Reference';

export default class BN_EncounterType extends DomainResource {
    static __className = 'BN_EncounterType';

    __objectStructure = {
        active: Boolean,
        title: String,
        token: String,
        appointmentColor: String,
        defaultDuration: Number,
        form: [String],
        service: [Reference],
        agency: Reference,
        url: String,
        derivedFrom: String,
        group: Boolean,
    };

    __objectDefaults = {
        experimental: false,
    };

    constructor(constructJson, className = 'BN_EncounterType') {
        super(constructJson, className);
        this.createAndPopulateStructure(this.__objectStructure, constructJson, this.__objectDefaults);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
