import { DomainResource } from './index';
import merge from 'lodash/merge';
import Address from './Address';
import Reference from './types/Reference';

export default class BN_PayorAddress extends DomainResource {
    static __className = 'BN_PayorAddress';

    __objectStructure = {
        payor: Reference,
        address: Address,
    };

    constructor(constructJson, className = 'BN_PayorAddress') {
        super(constructJson, className);
        this.createAndPopulateStructure(this.__objectStructure, constructJson);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
