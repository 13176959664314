import { BackboneElement } from './index';

import merge from 'lodash/merge';

export default class BundleEntrySearch extends BackboneElement {
    static __className = 'BundleEntrySearch';

    __objectStructure = {
        mode: String, // match | include | outcome - why this is in the result set
        score: Number, //Search ranking (between 0 and 1)
    };

    constructor(constructJson, className = 'BundleEntrySearch') {
        super(constructJson, className);

        this.createAndPopulateStructure(this.__objectStructure, constructJson);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
