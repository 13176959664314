<script>
import DataProvider from '@/components/DataProvider.js';
import BnToolbarBtn from '@/components/BnToolbarBtn.vue';
import BnInfo from '@/components/BnInfo.vue';
import BnLoading from '@/components/BnLoading.vue';
import { $httpFhirApi } from '@/common/api/httpFhir.service.js';
import { FORM_RESTRICTION_GROUPS, isLibraryDatabase } from '@/common/config';
import { SPECIAL_VARIABLES } from '@/common/api/specialVariables.api';
import { debounce, isEmpty } from 'lodash';
import { HealthcareService } from '@/fhirworks';
import { useProfileFlow } from '@/common/workflowEngine/useProfileFlow';
import BnFooter from '@/components/BnFooter';
import { addRefToBackBtnListiner, removeRefToBackBtnListiner } from '@/common/nativeBackButton';

export default {
    name: 'BnResourceSelector',
    inheritAttrs: false,
    components: {
        BnLoading,
        BnInfo,
        BnToolbarBtn,
        DataProvider,
        BnFooter,
    },
    data: function () {
        return {
            // dialog management props
            dialog: false,
            resolve: null,
            reject: null,
            options: null,
            baseOptions: {
                header: 'BestNotes',
                width: 400,
                persistent: true,
                filter: true,
                multiple: true,
                info: undefined,
                warning: undefined,
                group: {},
            },
            props: {},
            agency: undefined,
            dpResource: [],

            // filter/search
            search: undefined,
            remoteSearch: false,
            loadingMoreData: false,

            // constants
            formRestrictionGroups: FORM_RESTRICTION_GROUPS,
            specialVariables: SPECIAL_VARIABLES,
        };
    },
    setup() {
        return {
            isEmpty,
        };
    },
    watch: {
        search: debounce(
            function () {
                if (this.remoteSearch) {
                    this.generateQuery(this.props);
                }
            },
            250,
            { maxWait: 500 },
        ),
    },
    computed: {
        moreDataUrl() {
            if (this.loadingMoreData) {
                // leave this here to maintain infinite scrolling reactivity
            }
            return this.$refs.dpResource?.link?.find((item) => item.relation === 'next')?.url;
        },
        resources() {
            /**
             * TODO: update search to modify the actual query so that we can search
             * more than just the first 100 rows
             */
            const resources = this.dpResource.filter((item) => {
                let search;
                if (!this.search) return true;
                else if (this.props.filterByAgency) {
                    if (this.agency === item[this.props.filterByAgency]?.id) {
                        // multi-prop search
                        if (Array.isArray(this.props.itemTitle)) {
                            let found = false;
                            for (const prop of this.props.itemTitle) {
                                if (!item[prop]) continue;
                                search = item[prop].toLowerCase();
                                found = found || search.includes(this.search.toLowerCase());
                            }
                            return found;
                        }
                        // regular search
                        else {
                            if (!item[this.props.itemTitle]) return false;
                            search = item[this.props.itemTitle].toLowerCase();
                            return search.includes(this.search.toLowerCase());
                        }
                    }
                } else {
                    // multi-prop search
                    if (Array.isArray(this.props.itemTitle)) {
                        let found = false;
                        for (const prop of this.props.itemTitle) {
                            if (!item[prop]) continue;
                            search = item[prop].toLowerCase();
                            found = found || search.includes(this.search.toLowerCase());
                        }
                        return found;
                    }
                    // regular search
                    else {
                        if (!item[this.props.itemTitle]) return false;
                        search = item[this.props.itemTitle].toLowerCase();
                        return search.includes(this.search.toLowerCase());
                    }
                }
                return true;
            });

            // group questionnaires by subjectType
            if (this.props.preset === 'Questionnaire') {
                const subjectTypeMap = { Patient: 'Patient', Person: 'Individual', Practitioner: 'Professional', Organization: 'Business', CareTeam: 'Employee' };
                let questionnaires = resources.filter((e) => e.resourceType === 'Questionnaire');
                const collection = resources.filter((e) => e.resourceType === 'BN_List');

                if (this.props.group !== null) {
                    questionnaires = questionnaires.filter((item) => (this.props.group ? item.groupNote : !item?.groupNote));
                }

                return questionnaires
                    .map((item) => {
                        item.collectionName = collection
                            .filter((c) => c.item.find((i) => item.url === i.value.uri))
                            .sort((a, b) => (a > b ? 1 : -1))
                            .map((e) => e.name)
                            .join(', ');
                        item.subTitle = item.subjectType.map((e) => subjectTypeMap[e.toString()]).join(', ');
                        return item;
                    })
                    .sort((a, b) => (a.title > b.title ? 1 : -1));
            }

            if (this.props.preset === 'EncounterType') {
                let encounterTypes = resources.filter((e) => e.resourceType === 'BN_EncounterType');
                encounterTypes = encounterTypes.filter((encounterType) => {
                    if (encounterTypes.find((et) => et.derivedFrom === encounterType.url)) {
                        return false;
                    }

                    return true;
                });
                return encounterTypes;
            }

            // group special variables
            if (this.props.preset === 'specialVariables') {
                return resources.flatMap((item) => {
                    if (item.header) {
                        item.icon = 'layer-group';
                    }

                    return item;
                });
            }

            // no customization just return the resources
            else {
                return resources;
            }
        },
    },
    methods: {
        async show(options, props) {
            this.search = undefined;
            this.remoteSearch = undefined;
            this.dpResource = [];
            this.options = { ...this.baseOptions, ...options };

            // set item prop defaults
            props.items = props.items || [];
            props.itemKey = props.itemKey || 'id';
            props.itemIcon = props.itemIcon || ['far', 'circle'];

            // define data query and add additional props as required
            if (!props.query) {
                props = await this.generateQuery(props);
            }
            // set all other props
            this.props = { ...props, selectedResources: [] };

            // show dialog
            this.dialog = true;
            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },
        done() {
            if (this.props.variation && this.props.selectedResources.length === 1 && this.props.selectedResources[0].transform) {
                addRefToBackBtnListiner(this.$refs.bnDialog, 'close', 'bnDialog');
                this.$refs.bnDialog.show({ ...this.props.variation }).then((response) => {
                    removeRefToBackBtnListiner('bnDialog');
                    if (response) {
                        let transform = this.transformResource(this.props.selectedResources, response);
                        if (!this.options.multiple) {
                            transform = transform[0];
                        }
                        this.close(transform);
                    } else {
                        this.props.selectedResources = [];
                    }
                });
            } else if (!this.options.multiple) {
                this.close(this.props.selectedResources[0]);
            } else {
                this.close(this.props.selectedResources);
            }
        },
        close(resolve) {
            this.props = {};
            this.search = undefined;
            this.remoteSearch = undefined;
            this.dpResource = [];
            this.resolve(resolve);
            this.dialog = false;
            this.options = undefined;
        },
        async generateQuery(props) {
            // set agency prop if required
            if (props.agency) {
                if (!Array.isArray(props.agency)) {
                    props.agency = [props.agency];
                }
            }
            // set agency if location is specified
            else if (props.location?.id) {
                await $httpFhirApi.get('Location/' + props.location.id).then((response) => {
                    props.agency = [response.data?.managingOrganization];
                });
            }
            // default to empty
            else {
                props.agency = [];
            }
            // set agency filter
            this.agency = props.agency[0]?.id;

            // normalize agencies
            props.agency = props.agency.map((item) => ({ id: item.id, display: item.display || item.name, resourceType: 'Organization' }));

            // create agency query string
            props.agencies = props.agency.length ? props.agency.map((item) => item.id).join(',') : undefined;

            // Defined preset query
            if (props.preset) {
                // Form (Questionnaire)
                if (props.preset === 'Questionnaire') {
                    props.itemTitle = 'title';
                    props.itemSubTitle = 'subTitle';
                    props.itemIcon = ['far', 'file-contract'];
                    props.itemKey = 'url';
                    props.titleChip = true;
                    props.subTitleChip = true;
                    // props.itemType should be passed in: form, live
                    props.itemType = props.itemType || 'form';

                    this.options.group.info = 'Forms are grouped by the contact type they serve. Forms may appear in multiple groups';
                    props.query = {
                        query: '_elements=title,url,bnOwner,subjectType,name,version,groupNote&deprecate:missing=true&status=active&formType=' + props.itemType,
                        resourceType: 'Questionnaire',
                    };
                    // status defaults to 'active' (published)
                    const status = props.status || 'active';
                    props.query.query += '&status=' + status;
                    // subjectType filters by target contact type
                    if (props.subjectType) {
                        props.query.query += '&subject-type=' + props.subjectType;
                    }
                    // infinite scroll / search
                    // props.query.query += '&_count=30';

                    this.remoteSearch = true;
                    if (this.search) {
                        props.query.query += '&title:contains=' + this.search;
                    }

                    if (!props.includeProfileResources) {
                        let options = {};
                        if (props.itemType === 'live') {
                            options = { query: '&formType=live' };
                        }

                        const { getProfiledQuestionnaires } = useProfileFlow();
                        let formsToQuery = await getProfiledQuestionnaires(options);

                        props.query = [
                            {
                                query: props.query.query,
                                resourceType: 'Questionnaire',
                            },
                        ];

                        props.query = props.query.concat(formsToQuery);
                    }
                    const getCollections = {
                        query: 'type=formCollection',
                        resourceType: 'BN_List',
                    };
                    props.query = props.query.concat(getCollections);
                }
                // Collections
                else if (props.preset === 'collection') {
                    props.itemTitle = 'name';
                    props.itemIcon = ['far', 'layer-group'];
                    props.itemKey = 'url';
                    // props.itemType should be passed in: AutoNoteCollection, FormCollection, LiveFormCollection
                    props.itemType = props.itemType || 'undefined';
                    props.query = {
                        query: '_sort=name&_elements=name,url,owner&type=' + props.itemType,
                        resourceType: 'BN_List',
                    };
                    // filter by agency
                    if (props.agencies) {
                        props.query.query += '&owner=' + props.agencies;
                    }
                    // infinite scroll / search
                    props.query.query += '&_count=30';
                    this.remoteSearch = true;
                    if (this.search) {
                        props.query.query += '&name:contains=' + this.search;
                    }
                }
                // AutoNote
                else if (props.preset === 'AutoNote') {
                    props.itemTitle = 'title';
                    props.itemIcon = ['far', 'wand-magic-sparkles'];
                    props.itemKey = 'url';
                    props.query = {
                        query: '_sort=title&_elements=title,url,bnOwner&formType=autonote',
                        resourceType: 'Questionnaire',
                    };
                    // filter by agency
                    if (props.agencies) {
                        props.query.query += '&bnOwner=' + props.agencies;
                    }
                    // infinite scroll / search
                    props.query.query += '&_count=30';
                    this.remoteSearch = true;
                    if (this.search) {
                        props.query.query += '&title:contains=' + this.search;
                    }
                }
                // EncounterTypes
                else if (props.preset === 'EncounterType') {
                    props.itemTitle = 'title';
                    props.itemIcon = ['far', 'tags'];
                    props.itemKey = 'url';
                    props.query = [
                        {
                            query: '_sort=title&active=true',
                            resourceType: 'BN_EncounterType',
                        },
                    ];

                    if (props.encounterTypeLibraryItems) {
                        props.encounterTypeLibraryItems.forEach((url) => {
                            props.query.push({ url: url });
                        });
                    }

                    // filter by agency
                    if (props.agencies) {
                        props.filterByAgency = 'agency';
                        props.query.query += '&agency=' + props.agencies;
                    }
                }
                // Locations
                else if (props.preset === 'Location') {
                    props.query = {
                        query: '_sort=name&status=active&partof:missing=true',
                        resourceType: 'Location',
                    };

                    if (props.includeRooms) {
                        props.query = {
                            query: '_sort=name&status=active',
                            resourceType: 'Location',
                        };
                    }

                    props.itemTitle = 'name';
                    props.itemIcon = ['far', 'map-marker-alt'];
                    props.itemKey = 'id';
                    // filter by agency
                    if (props.agencies) {
                        props.filterByAgency = 'managingOrganization';
                        props.query.query += '&organization=' + props.agencies;
                    }
                }
                // Programs
                else if (props.preset === 'Program') {
                    props.query = {
                        query: 'isProgram=true&isAddOn:missing=true&active=true&_sort=name',
                        resourceType: 'HealthcareService',
                    };
                    props.itemTitle = 'name';
                    props.itemIcon = ['far', 'route'];
                    props.itemKey = 'id';
                    // filter by agency
                    if (props.agencies) {
                        props.filterByAgency = 'providedBy';
                        props.query.query += '&organization=' + props.agencies;
                    }
                }
                // Services
                else if (props.preset === 'Service') {
                    props.query = {
                        query: 'isProgram:missing=true&isAddOn:missing=true&active=true&_sort=name',
                        resourceType: 'HealthcareService',
                    };
                    props.itemTitle = 'name';
                    props.itemIcon = ['far', 'clipboard-list-check'];
                    props.itemKey = 'id';
                }

                // Place of Service
                else if (props.preset === 'PlaceOfService') {
                    props.query = {
                        query: 'system=http://code.system/cms-place-of-service&_sort=code',
                        resourceType: 'Concept',
                        terminology: true,
                    };
                    props.itemTitle = ['code', 'display'];
                    props.itemIcon = ['far', 'map-marker-alt'];
                    props.itemKey = 'code';
                }

                // Special variables - comes from a CONSTANT - SPECIAL_VARIABLES
                else if (props.preset === 'specialVariables') {
                    props.query = {};
                    // Done so that the data provider is not used because the call to
                    // CreateFHIRResource was causing and error because of the recordType
                    // property (Attempt to create a Patient when it is really just a JSON object).
                    this.dpResource = this.specialVariables;
                    props.itemTitle = 'label';
                    props.itemSubTitle = 'token';
                    props.itemIcon = ['far', 'brackets-curly'];
                    props.itemKey = 'token';
                    props.variation = {
                        type: 'radio',
                        options: [
                            { text: 'Uppercase', value: 'upper' },
                            { text: 'Lowercase', value: 'lower' },
                        ],
                        value: 'upper',
                        width: 300,
                        header: 'Capitalization',
                        message: 'First letter should be:',
                        confirm: 'Insert',
                        cancel: 'Cancel',
                    };
                }
            }
            // ResourceType
            else if (props.resourceType) {
                props.query = {
                    resourceType: props.resourceType,
                };
            }
            return props;
        },
        // scroll detection to load more resources
        loadMoreData(event) {
            if (!this.moreDataUrl) return;
            const { scrollTop, scrollHeight, clientHeight } = event.target;
            if (scrollTop + clientHeight >= scrollHeight - 5 && this.moreDataUrl && !this.loadingMoreData) {
                this.loadingMoreData = true;
                this.$refs.dpResource.fetchItems({ url: this.moreDataUrl });
            }
        },
        selectResource(resource) {
            const alreadySelected = this.props.selectedResources.findIndex((item) => item[this.props.itemKey] === resource[this.props.itemKey]);
            if (alreadySelected > -1) {
                this.props.selectedResources.splice(alreadySelected, 1);
            } else {
                this.props.selectedResources.push(resource);
            }
            if (!this.options.multiple) this.done();
        },
        transformResource(resource, variation) {
            const transformedResource = JSON.parse(JSON.stringify(resource));
            if (this.props.preset === 'specialVariables') {
                transformedResource[0].case = variation;
                if (variation === 'lower') {
                    transformedResource[0].token = transformedResource[0].token.replace('{X', '{x');
                } else {
                    transformedResource[0].token = transformedResource[0].token.replace('{x', '{X');
                }
            }
            return transformedResource;
        },

        getItemTitle(item, separator = ' - ') {
            // simple string
            if (typeof item === 'string') {
                return { text: item };
            }

            // If the item being passed is a HealthcareService return the request format
            if (item instanceof HealthcareService && item.billingCode) {
                return { text: item.name + ' ( ' + item.billingCode + ' )' };
            }

            // all other types
            if (typeof this.props.itemTitle === 'object' && !Array.isArray(this.props.itemTitle)) {
                const title = item[this.props.itemTitle.title];
                return typeof title === 'object' ? title : { text: title };
            } else if (this.props.itemTitle) {
                if (this.props.itemTitle === 'url') {
                    return { text: this.getDisplay(item?.url) };
                }
                if (Array.isArray(this.props.itemTitle) && this.props.itemTitle.length >= 2) {
                    let stringFromArray = '';
                    this.props.itemTitle.forEach((e, index) => {
                        if (item[e.toString()]) {
                            stringFromArray += item[e.toString()];
                            if (index !== this.props.itemTitle.length - 1) {
                                stringFromArray += separator;
                            }
                        }
                    });
                    return { text: stringFromArray };
                }
                return { text: item[this.props.itemTitle] };
            } else {
                return { text: item?.display || item?.text || item?.name };
            }
        },
        getItemSubTitle(item) {
            const subtitle = item[this.props.itemTitle?.subtitle];
            return typeof subtitle === 'object' ? subtitle : { text: subtitle };
        },
        isManagedResource(resource) {
            if (['Questionnaire', 'BN_EncounterType'].includes(resource.resourceType)) {
                return !isLibraryDatabase() && (resource.url?.includes('bnprofile.fhir') || resource.derivedFrom?.includes('bnprofile.fhir'));
            } else if (resource.resourceType === 'HealthcareService') {
                return !!resource.derivedFrom;
            }

            return false;
        },
    },
};
</script>

<template>
    <v-dialog v-if="options" v-model="dialog" :width="options.width" scrollable :persistent="options.persistent" :fullscreen="$vuetify.display.xs">
        <v-card flat v-bind="$attrs" class="d-flex flex-column">
            <div class="flex-grow-0">
                <ion-header>
                    <ion-toolbar>
                        <v-toolbar density="compact">
                            <v-toolbar-title>{{ options.header }}</v-toolbar-title>
                            <template v-if="options.multiple">
                                <bn-toolbar-btn type="text" :icon="options.cancelIcon || 'times'" :label="options.cancel || 'Cancel'" @click="close" data-cy="resourceSelectorCloseButton" />
                                <bn-toolbar-btn
                                    type="outlined"
                                    right
                                    color="primary"
                                    :icon="options.confirmIcon || 'check'"
                                    :label="options.confirm || 'Done'"
                                    :disabled="props.selectedResources && !props.selectedResources.length"
                                    @click="done"
                                    data-cy="resourceSelectorDoneButton"
                                />
                            </template>
                            <bn-toolbar-btn v-else type="outlined" right color="primary" icon="times" label="Cancel" @click="close" data-cy="resourceSelectorCloseButton" />
                        </v-toolbar>
                    </ion-toolbar>
                </ion-header>
                <v-divider></v-divider>
                <!-- Search/Filter Bar -->
                <template v-if="options.filter">
                    <div class="d-flex bn-color-list-filter">
                        <v-text-field ref="search" v-model="search" autofocus placeholder="Search" clearable hide-details background-color="bn-color-list-filter" data-cy="resourceSelectorSearch">
                            <template #prepend-inner>
                                <font-awesome-icon :icon="['far', 'search']" class="align-self-center mr-2" />
                            </template>
                        </v-text-field>
                    </div>
                    <v-divider></v-divider>
                </template>
                <!-- Agency Selector (if more than 1) -->
                <template v-if="props.filterByAgency && props.agency.length > 1">
                    <div class="d-flex bn-color-list-filter no-select">
                        <v-select
                            v-model="agency"
                            :items="props.agency"
                            item-value="id"
                            item-label="display"
                            hide-details
                            background-color="bn-color-list-filter"
                            @update:modelValue="options.filter ? $refs.search.focus() : undefined"
                        >
                            <template #prepend-inner>
                                <font-awesome-icon :icon="['far', 'building']" class="align-self-center mr-2" />
                            </template>
                        </v-select>
                    </div>
                    <v-divider></v-divider>
                </template>
                <!-- Info/Warning Bars -->
                <div v-if="options.info || options.warning" class="no-select mb-4">
                    <bn-info v-if="options.info" type="outlined">
                        {{ options.info }}
                    </bn-info>
                    <bn-info v-if="options.warning" type="outlined" color="warning">
                        {{ options.warning }}
                    </bn-info>
                </div>
            </div>
            <div class="scroll-on no-select" :min-height="$vuetify.display.mdAndUp ? 400 : undefined" :max-height="$vuetify.display.mdAndUp ? 800 : undefined" @scroll="loadMoreData">
                <data-provider
                    v-if="!isEmpty(props.query) || !isEmpty(props.resource)"
                    ref="dpResource"
                    v-model="dpResource"
                    collection
                    refresh-on-change
                    :resource="props.resource"
                    :query="props.query"
                    @loaded="loadingMoreData = false"
                    @loadedItems="loadingMoreData = false"
                >
                </data-provider>

                <bn-loading v-if="loadingMoreData" card class="pa-4" />

                <v-list v-else class="pa-0" data-cy="resourceSelectorList">
                    <template v-for="(resource, index) in resources" :key="index">
                        <!-- GroupBy header -->
                        <template v-if="resource.header">
                            <v-divider v-if="index"></v-divider>
                            <v-list-item class="bn-color-list-group font-weight-medium pr-0">
                                <template v-if="resource.icon" #prepend>
                                    <font-awesome-icon v-if="resource.icon" class="mr-2" :icon="['far', resource.icon]" />
                                </template>

                                <v-list-item-title class="bn-section-header">{{ resource.header }}</v-list-item-title>
                            </v-list-item>
                        </template>
                        <template v-else>
                            <v-divider v-if="index"></v-divider>
                            <v-list-item
                                color="bn-color-list-item"
                                class="pr-0"
                                :disabled="props.items.some((item) => [item[props.itemKey]].includes(resource[props.itemKey]))"
                                :active="props.selectedResources.some((item) => item[props.itemKey] === resource[props.itemKey])"
                                @click="selectResource(resource)"
                                data-cy="resourceSelectorListItem"
                            >
                                <template #prepend="{ isActive }">
                                    <v-icon v-if="typeof resource.icon === 'string' && resource.icon.startsWith('$')" :icon="resource.icon" class="mr-2" :class="{ 'text-black': !isActive }"></v-icon>
                                    <i
                                        v-else-if="typeof resource.icon === 'string' && resource.icon.startsWith('fa-kit')"
                                        class="v-icon v-icon--size-default mr-2"
                                        :class="resource.icon + (!isActive ? ' text-black' : '')"
                                    ></i>
                                    <font-awesome-icon v-else-if="resource.icon" :class="{ 'text-black': !isActive }" class="v-icon mr-2" :icon="resource.icon" size="lg" />
                                    <font-awesome-icon v-else class="v-icon mr-2" :class="{ 'text-black': !isActive }" :icon="props.itemIcon" size="lg" />
                                </template>

                                <div>
                                    <v-list-item-title>
                                        {{ getItemTitle(resource).text }}
                                        <template v-if="props.titleChip && resource.name">
                                            <v-chip size="x-small" label class="mr-2">{{ resource.name }}</v-chip>
                                        </template>
                                    </v-list-item-title>
                                    <v-list-item-subtitle v-if="getItemSubTitle(resource).text">{{ resource[props.itemSubTitle] }}</v-list-item-subtitle>
                                    <v-list-item-subtitle v-if="props.subTitleChip">
                                        <v-chip color="success" variant="outlined" size="x-small" class="flex-grow-0">
                                            <span v-if="resource.version">Version {{ resource.version }}</span>
                                        </v-chip>
                                        <v-chip v-show="resource.collectionName" class="ml-1" variant="outlined" size="x-small">
                                            <font-awesome-icon :icon="['far', 'layer-group']" class="mr-1"></font-awesome-icon>{{ resource.collectionName }}
                                        </v-chip>
                                    </v-list-item-subtitle>
                                    <v-spacer></v-spacer>
                                </div>
                                <template #append="{ isActive }">
                                    <div v-if="isManagedResource(resource)">
                                        <bn-info :header="'Managed ' + resource.resourceType" icon="file-certificate" type="dialog">
                                            This item is based on a BestNotes Managed item.
                                        </bn-info>
                                    </div>
                                    <div v-if="!props.items.some((item) => [item[props.itemKey]].includes(resource[props.itemKey]))" class="ma-3" :class="{ 'text-grey-lighten-2': !isActive }">
                                        <font-awesome-icon :icon="['far', 'check']" size="lg" />
                                    </div>
                                    <v-chip v-else size="small" class="mx-2">added</v-chip>
                                </template>
                            </v-list-item>
                        </template>
                    </template>
                </v-list>

                <bn-dialog ref="bnDialog"></bn-dialog>
            </div>
            <!-- ios ample touch footer bar -->
            <bn-footer class="dialog-footer"></bn-footer>
        </v-card>
    </v-dialog>
</template>
