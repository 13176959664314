import { BackboneElement } from './index';
import CodeableConcept from './types/CodeableConcept';
import CoverageEligibilityRequestItemDiagnosis from './CoverageEligibilityRequestItemDiagnosis';
import Money from './types/Money';
import Reference from './types/Reference';

import merge from 'lodash/merge';

export default class CoverageEligibilityRequestItem extends BackboneElement {
    static __className = 'CoverageEligibilityRequestItem';

    __objectStructure = {
        supportingInfoSequence: [Number],
        category: CodeableConcept,
        productOrService: CodeableConcept,
        modifier: [CodeableConcept],
        provider: Reference,
        quantity: Number,
        unitPrice: Money,
        facility: Reference,
        diagnosis: [CoverageEligibilityRequestItemDiagnosis],
        detail: [Reference],
    };

    constructor(constructJson, className = 'CoverageEligibilityRequestItem') {
        super(constructJson, className);
        this.createAndPopulateStructure(this.__objectStructure, constructJson);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
