import { FHIRObjectBase } from './index';
import Meta from './Meta';

import get from 'lodash/get';

export default class Resource extends FHIRObjectBase {
    static __className = 'Resource';

    constructor(resourceJson, className = 'Resource') {
        super(className);

        this.__id = get(resourceJson, 'id', undefined);
        this.__meta = new Meta(get(resourceJson, 'meta', undefined));
        this.__implicitRules = get(resourceJson, 'implicitRules', undefined);
        this.__language = get(resourceJson, 'language', undefined);

        this.originalObjJson = this.toJSON();
    }

    // Read only properties

    get id() {
        return this.__id;
    }

    // Properties

    get meta() {
        return this.__meta;
    }

    set meta(value) {
        if (value instanceof Meta) {
            this.__meta = value;
            return;
        }

        this.__meta = new Meta(value);
    }

    get implicitRules() {
        return this.__implicitRules;
    }

    set implicitRules(value) {
        this.__implicitRules = value;
    }

    get language() {
        return this.__language;
    }

    set language(value) {
        this.__language = value;
    }

    toJSON() {
        return this.removeUndefinedObjProps({
            id: this.id,
            meta: this.meta.toJSON(),
            implicitRules: this.implicitRules,
            language: this.language,
        });
    }
}
