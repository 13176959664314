import { DomainResource } from './index';
import merge from 'lodash/merge';
import BN_FinancialClassService from './BN_FinancialClassService';
import Reference from './types/Reference';
import Coding from './types/Coding';

export default class BN_FinancialClass extends DomainResource {
    static __className = 'BN_FinancialClass';

    __objectStructure = {
        name: String,
        code: String,
        description: String,
        active: Boolean,
        currency: String,
        organization: Reference,
        service: [BN_FinancialClassService],
        participatingProviders: [Reference],
        payor: [Reference],
        createdBy: String,
        claimType: Coding, // professional,institutional
        selfpay: Boolean,
        location: [Reference],
    };

    __objectDefaults = {
        experimental: false,
    };

    constructor(constructJson, className = 'BN_FinancialClass') {
        super(constructJson, className);
        this.createAndPopulateStructure(this.__objectStructure, constructJson, this.__objectDefaults);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
