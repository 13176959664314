import merge from 'lodash/merge';
import { BackboneElement } from './index';
import CodeableConcept from '@/fhirworks/types/CodeableConcept';
import Reference from '@/fhirworks/types/Reference';

export default class ConditionStage extends BackboneElement {
    static __className = 'ConditionStage';

    __objectStructure = {
        summary: CodeableConcept,
        assessment: [Reference],
        type: CodeableConcept,
    };

    constructor(constructJson, className = 'ConditionStage') {
        super(constructJson, className);
        this.createAndPopulateStructure(this.__objectStructure, constructJson, this.__objectDefaults);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
