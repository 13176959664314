import { DomainResource } from './index';
import merge from 'lodash/merge';
import Identifier from './types/Identifier';
import Reference from './types/Reference';
import Period from './types/Period';
import CodeableConcept from './types/CodeableConcept';
import AccountCoverage from './AccountCoverage';
import AccountGuarantor from './AccountGuarantor';

export default class Account extends DomainResource {
    static __className = 'Account';

    __objectStructure = {
        identifier: [Identifier],
        status: String, // active | inactive | entered-in-error | on-hold | unknown
        type: CodeableConcept,
        name: String,
        subject: [Reference], // The entity that caused the expenses
        servicePeriod: Period,
        coverage: [AccountCoverage],
        owner: Reference,
        description: String,
        guarantor: [AccountGuarantor],
        partOf: Reference,
        /**
         * First Class extensions
         */
        unAppliedAmount: Number,
    };

    constructor(constructJson, className = 'Account') {
        super(constructJson, className);
        this.createAndPopulateStructure(this.__objectStructure, constructJson);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
