import merge from 'lodash/merge';
import { FHIRObjectBase } from './index';
import Reference from './types/Reference';
import Quantity from './types/Quantity';
import Coding from './types/Coding';
import Attachment from './types/Attachment';
import ValueX from './types/ValueX';

export default class QuestionnaireInitialValue extends FHIRObjectBase {
    static __className = 'QuestionnaireInitialValue';

    __objectStructure = {
        //Aidbox Types
        value: ValueX,

        //FHIR Types
        valueBoolean: Boolean,
        valueDecimal: Number,
        valueInteger: Number,
        valueDate: String,
        valueDateTime: String,
        valueTime: String,
        valueString: String,
        valueUri: String,
        valueAttachment: Attachment,
        valueCoding: Coding,
        valueQuantity: Quantity,
        valueReference: Reference,
    };

    constructor(constructJson, className = 'QuestionnaireInitialValue') {
        super(constructJson, className);
        this.createAndPopulateStructure(this.__objectStructure, constructJson, this.__objectDefaults);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
