import { BaseElement } from '../index';
import merge from 'lodash/merge';

/**
 * @property {String} value
 * @property {String} unit
 * @property {String} system
 * @property {String} code
 */
export default class Age extends BaseElement {
    static __className = 'Age';

    __objectStructure = {
        value: String,
        unit: String,
        system: String,
        code: String,
    };

    constructor(constructJson, className = 'Age') {
        super(constructJson, className);

        this.createAndPopulateStructure(this.__objectStructure, constructJson, this.__objectDefaults);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
