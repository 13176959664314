import merge from 'lodash/merge';
import QuestionnaireResponseItem from './QuestionnaireResponseItem';
import ValueX from './types/ValueX';
import { Element } from './index';
import { htmlToText, isHtml } from '@/common/core';

/**
 * @property {ValueX} value
 * @property {QuestionnaireResponseItem}
 */
export default class QuestionnaireResponseItemAnswer extends Element {
    static __className = 'QuestionnaireResponseItemAnswer';

    __objectStructure = {
        value: ValueX,
        item: [QuestionnaireResponseItem],
        /**
         * first-class extensions
         */
        renderingXhtml: String,
        score: Number,
    };

    constructor(constructJson, className = 'QuestionnaireResponseItemAnswer') {
        super(constructJson, className);

        this.createAndPopulateStructure(this.__objectStructure, constructJson);

        this.originalObjJson = this.toJSON();
    }

    get bnValue() {
        // renderingXhtml is only set for string property with HTML
        if (this.renderingXhtml) return this.renderingXhtml;

        if (!(this.value instanceof ValueX)) {
            return undefined;
        }

        // Use Null Coalesce to ensure we return falsy values, like 0 or empty strings.
        return (
            this.value.string ??
            this.value.boolean ??
            this.value.decimal ??
            this.value.integer ??
            this.value.date ??
            this.value.dateTime ??
            this.value.time ??
            this.value.unsignedInt ??
            this.value.uri ??
            this.value.Age ??
            this.value.Attachment ??
            this.value.CodeableConcept ??
            this.value.Coding ??
            this.value.Money ??
            this.value.Period ??
            this.value.Quantity ??
            this.value.Range ??
            this.value.Reference ??
            null
        );
    }

    set bnValue(value) {
        if (!value) {
            return;
        }
        let params = value;
        if (typeof value === 'string') {
            params = JSON.parse(value);
        }

        if (!(this.value instanceof ValueX)) {
            this.value = new ValueX();
        }

        // Clear all existing values
        for (let propName in this.value.__objectStructure) {
            let properPropertyName = 'value' + propName.charAt(0).toUpperCase() + propName.slice(1);
            this.value[properPropertyName] = undefined;
        }

        let propertyType = 'value' + params.type.charAt(0).toUpperCase() + params.type.slice(1);
        this.renderingXhtml = undefined;
        if (params.type === 'string') {
            if (isHtml(params.value)) {
                this.value[propertyType] = htmlToText(params.value);
                this.renderingXhtml = params.value;
            } else {
                this.value[propertyType] = params.value;
            }
        } else {
            this.value[propertyType] = params.value;
        }
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
