import { BaseElement } from '../index';
import merge from 'lodash/merge';

/**
 * @property {String} system
 * @property {String} version
 * @property {String} code
 * @property {String} display
 * @property {Boolean} userSelected
 */
export default class Coding extends BaseElement {
    static __className = 'Coding';

    __objectStructure = {
        system: String,
        version: String,
        code: String,
        display: String,
        userSelected: Boolean,
        id: String,
    };

    constructor(constructJson, className = 'Coding') {
        super(constructJson, className);

        this.createAndPopulateStructure(this.__objectStructure, constructJson, this.__objectDefaults);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
