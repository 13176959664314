import { BackboneElement } from './index';
import merge from 'lodash/merge';
import Coding from './types/Coding';

export default class BN_ERA_glossary extends BackboneElement {
    static __className = 'BN_ERA_glossary';

    __objectStructure = {
        CO: [Coding],
        MOA: [Coding],
        OA: [Coding],
        PI: [Coding],
        PR: [Coding],
    };

    constructor(constructJson, className = 'BN_ERA_PatientProcedureCodeInfo') {
        super(constructJson, className);
        this.createAndPopulateStructure(this.__objectStructure, constructJson);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
