import { BackboneElement } from './index';
import CodeableConcept from '@/fhirworks/types/CodeableConcept';
import Reference from '@/fhirworks/types/Reference';
import merge from 'lodash/merge';

export default class ConditionEvidence extends BackboneElement {
    static __className = 'ConditionEvidence';

    __objectStructure = {
        code: [CodeableConcept],
        detail: [Reference],
    };

    constructor(constructJson, className = 'ConditionEvidence') {
        super(constructJson, className);
        this.createAndPopulateStructure(this.__objectStructure, constructJson, this.__objectDefaults);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
