import { BackboneElement } from '../index';
import merge from 'lodash/merge';
import Period from '../types/Period';
import Reference from './Reference';

/**
 * @property {Number} repetitions
 * @property {Period} period
 * @property {Reference[]} recipient
 */
export default class TaskRestriction extends BackboneElement {
    static __className = 'TaskRestriction';

    __objectStructure = {
        repetitions: Number,
        period: Period,
        recipient: [Reference],
    };

    constructor(constructJson, className = 'TaskRestriction') {
        super(constructJson, className);

        this.createAndPopulateStructure(this.__objectStructure, constructJson, this.__objectDefaults);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
