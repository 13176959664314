import { BaseElement } from '../index';
import merge from 'lodash/merge';
import Age from '../types/Age';
import Attachment from '../types/Attachment';
import CodeableConcept from '../types/CodeableConcept';
import Coding from '../types/Coding';
import Money from '../types/Money';
import Period from '../types/Period';
import Quantity from '../types/Quantity';
import Range from '../types/Range';
import Reference from './Reference';
import FHIRObjectBase from '../FHIRObjectBase';

const lowerCaseVars = ['boolean', 'canonical', 'date', 'dateTime', 'decimal', 'integer', 'string', 'time', 'uri', 'unsignedInt'];

/**
 * @property {Boolean} boolean
 * @property {Number} decimal
 * @property {Number} integer
 * @property {String} date
 * @property {String} dateTime
 * @property {String} time
 * @property {String} string
 * @property {Number} unsignedInt
 * @property {String} uri
 * @property {Attachment} Attachment
 * @property {CodeableConcept} CodeableConcept
 * @property {Coding} Coding
 * @property {Period} Period
 * @property {Money} Money
 * @property {Quantity} Quantity
 * @property {Reference} Reference
 */
export default class ValueX extends BaseElement {
    static __className = 'ValueX';

    __objectStructure = {
        boolean: Boolean,
        canonical: String,
        decimal: Number,
        integer: Number,
        date: String,
        dateTime: String,
        time: String,
        string: String,
        unsignedInt: Number,
        uri: String,
        instant: String,
        Age: Age,
        Attachment: Attachment,
        CodeableConcept: CodeableConcept,
        Coding: Coding,
        Money: Money,
        Period: Period,
        Quantity: Quantity,
        Range: Range,
        Reference: Reference,
    };

    // Any addition to supported FHIR entries need a migration that will address supporting
    // that type in any custom entities.  Currently, I only know of one entity, BN_ListItem
    constructor(constructJson, baseProperty = 'value', className = 'ValueX') {
        super(constructJson, className);

        this.createAndPopulateStructure(this.__objectStructure, constructJson, this.__objectDefaults);
        this.originalObjJson = this.toJSON();

        let basePropertyLength = baseProperty.length;
        for (let key in constructJson) {
            if (Object.prototype.hasOwnProperty.call(constructJson, key)) {
                let prop = key.toLowerCase();
                if (prop.slice(0, basePropertyLength) === baseProperty) {
                    let string = key.slice(basePropertyLength);
                    let stringLower = string.charAt(0).toLowerCase() + string.substring(1);

                    if (lowerCaseVars.includes(stringLower)) {
                        string = stringLower;
                    }
                    if (Object.prototype.hasOwnProperty.call(this, string)) {
                        // eslint-disable-next-line security/detect-object-injection
                        this[string] = constructJson[key];
                    }
                }
            }
        }

        // Define local getter and setters for the various object type
        for (let propName in this.__objectStructure) {
            let properPropertyName = baseProperty + propName.charAt(0).toUpperCase() + propName.slice(1);
            Object.defineProperty(this, properPropertyName, {
                get() {
                    // eslint-disable-next-line security/detect-object-injection
                    return this[propName];
                },
                set(value) {
                    // eslint-disable-next-line security/detect-object-injection
                    this[propName] = value;
                },
            });
        }
    }

    // Returns the first value property with a set value
    get internalValue() {
        for (let prop of Object.keys(this.__objectStructure)) {
            if (this[prop] !== null && this[prop] !== undefined) {
                return this[prop];
            }
        }
        return undefined;
    }

    // internalValue Setter ONLY supports Boolean, Decimal, Integer and String along with the FHIR types:
    // Age, Attachment,CodeableConcept, Coding, Money, Period, Quantity, Range and Reference.
    // Assignment of FHIR type MUST be a FHIR entity NOT JSON.
    // Access to other ValueX supported type required standard ValueX access.
    set internalValue(value) {
        let passedValueType = typeof value;
        if (passedValueType === 'object') {
            if (value instanceof FHIRObjectBase) {
                passedValueType = value.__className;
            } else {
                throw 'Invalid Value';
            }
        } else if (passedValueType === 'number') {
            passedValueType = 'integer';
            if (value % 1 !== 0) {
                passedValueType = 'decimal';
            }
        }

        Object.keys(this.__objectStructure).forEach((prop) => {
            let newValue = undefined;
            if (prop === passedValueType) {
                newValue = value;
            }
            this['value' + prop.charAt(0).toUpperCase() + prop.slice(1)] = newValue;
        });
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
