import { Element } from './index';
import Coding from './types/Coding';

import get from 'lodash/get';
import merge from 'lodash/merge';

export default class Meta extends Element {
    static __className = 'Meta';

    constructor(metaJson, className = 'Meta') {
        // ToDo: Nothing was being passed to super
        super(metaJson, className);

        this.__versionId = get(metaJson, 'versionId', undefined);
        this.__createdAt = get(metaJson, 'createdAt', undefined);
        this.__lastUpdate = get(metaJson, 'lastUpdated', undefined);
        this.__source = get(metaJson, 'source', undefined);

        this.__security = [];
        let securityInfo = get(metaJson, 'security', []);
        securityInfo.forEach((securityItem) => {
            this.__security.push(new Coding(securityItem));
        });

        this.__tag = [];
        let tagInfo = get(metaJson, 'tag', []);
        tagInfo.forEach((tagitem) => {
            this.__tag.push(new Coding(tagitem));
        });

        this.originalObjJson = this.toJSON();
    }

    // Properties

    get versionId() {
        return this.__versionId;
    }

    set versionId(value) {
        this.__versionId = value;
    }

    get createdAt() {
        return this.__createdAt;
    }

    get lastUpdated() {
        return this.__lastUpdate;
    }

    set lastUpdated(value) {
        this.__lastUpdate = value;
    }

    get source() {
        return this.__source;
    }

    set source(value) {
        this.__source = value;
    }

    get security() {
        return this.__security;
    }

    set security(value) {
        if (Array.isArray(value)) {
            this.__security = value;
            return;
        }

        if (value instanceof Coding) {
            this.__security.push(value);
            return;
        }

        this.__security.push(new Coding(value));
    }

    get tag() {
        return this.__tag;
    }

    set tag(value) {
        if (Array.isArray(value)) {
            this.__tag = value;
            return;
        }

        if (value instanceof Coding) {
            this.__tag.push(value);
            return;
        }

        this.__tag.push(new Coding(value));
    }

    toJSON() {
        return merge(
            super.toJSON(this),
            this.removeUndefinedObjProps({
                versionId: this.versionId,
                createdAt: this.createdAt,
                lastUpdated: this.lastUpdated,
                source: this.source,
                security: this.arrayPropertyToJson(this.security),
                tag: this.arrayPropertyToJson(this.tag),
            }),
        );
    }
}
