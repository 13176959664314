import { BackboneElement } from './index';
import Period from './types/Period';

import merge from 'lodash/merge';

const episodeOfCareStatusHistoryStatusValues = {
    planned: 'planned',
    waitlist: 'waitlist',
    active: 'active',
    onhold: 'onhold',
    finished: 'finished',
    cancelled: 'cancelled',
    enteredinerror: 'entered-in-error',
};

export default class EpisodeOfCareStatusHistory extends BackboneElement {
    static __className = 'EpisodeOfCareStatusHistory';

    __objectStructure = {
        status: String, // planned | waitlist | active | onhold | finished | cancelled | entered-in-error
        period: Period, // Duration the EpisodeOfCare was in the specified status
    };

    constructor(constructJson, className = 'EpisodeOfCareStatusHistory') {
        super(constructJson, className);

        this.createAndPopulateStructure(this.__objectStructure, constructJson);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}

export { episodeOfCareStatusHistoryStatusValues, EpisodeOfCareStatusHistory };
