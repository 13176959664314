import { BackboneElement } from './index';
import CoverageEligibilityResponseInsuranceItem from './CoverageEligibilityResponseInsuranceItem';
import CoverageEligibilityResponseInsuranceBenefitPeriod from './CoverageEligibilityResponseInsuranceBenefitPeriod';
// import Period from './types/Period';
import Reference from './types/Reference';

import merge from 'lodash/merge';

export default class CoverageEligibilityResponseInsurance extends BackboneElement {
    static __className = 'CoverageEligibilityResponseInsurance';

    __objectStructure = {
        coverage: Reference,
        inforce: Boolean,
        effectiveBenefitPeriod: [CoverageEligibilityResponseInsuranceBenefitPeriod],
        item: [CoverageEligibilityResponseInsuranceItem],
    };

    constructor(constructJson, className = 'CoverageEligibilityResponseInsurance') {
        super(constructJson, className);
        this.createAndPopulateStructure(this.__objectStructure, constructJson);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
