import merge from 'lodash/merge';
import { Element } from '../index';

export default class Expression extends Element {
    static __className = 'Expression';

    __objectStructure = {
        description: String,
        id: String,
        language: String,
        expression: String,
        reference: String,
    };

    constructor(constructJson, className = 'Expression') {
        super(constructJson, className);

        this.createAndPopulateStructure(this.__objectStructure, constructJson);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
