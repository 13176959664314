import { BackboneElement } from './index';
import CodeableConcept from './types/CodeableConcept';
import Coding from './types/Coding';
import Period from './types/Period';
import Reference from './types/Reference';

import merge from 'lodash/merge';
import get from 'lodash/get';

/**
 * @property {CodeableConcept[]} type // Role of participant in the appointment
 * @property {Reference} actor //Patient | Practitioner  PractitionerRole | RelatedPerson | Device | HealthcareService | Location
 * @property {String} required // required | optional | information-only
 * @property {String} status // REQUIRED accepted | declined | tentative | needs-action
 * @property {Period} period //Participation period of the actor
 */
export default class AppointmentParticipant extends BackboneElement {
    static __className = 'AppointmentParticipant';

    __objectStructure = {
        type: [CodeableConcept], // Role of participant in the appointment
        actor: Reference, //Patient | Practitioner  PractitionerRole | RelatedPerson | Device | HealthcareService | Location
        required: String, // required | optional | information-only
        status: String, // REQUIRED: accepted | declined | tentative | needs-action
        period: Period, //Participation period of the actor
        /**
         * First Class extensions
         */
        statusReason: CodeableConcept,
        episodeOfCare: Reference,
        encounter: Reference,
        serviceRequest: Reference,
        signer: Boolean,
        selfPay: Boolean,
        appointmentParticipantStatus: String,
        groupId: String,
    };

    constructor(constructJson, className = 'AppointmentParticipant') {
        super(constructJson, className);

        this.createAndPopulateStructure(this.__objectStructure, constructJson, this.__objectDefaults);

        this.__role = [];

        this.internalCallToStatusSetter = false;

        Object.defineProperty(this, 'appointmentParticipantStatus', {
            configurable: true,
            get() {
                return this.__appointmentParticipantStatus;
            },
            set(value) {
                if (value === undefined) {
                    this.__appointmentParticipantStatus = undefined;
                    this.internalCallToStatusSetter = true;
                    this.status = undefined;
                    this.internalCallToStatusSetter = false;
                    return;
                }

                // 	accepted | declined | tentative | needs-action
                this.internalCallToStatusSetter = true;
                let statusValue = value;
                switch (value) {
                    case 'pending':
                        statusValue = 'tentative';
                        break;
                    case 'booked':
                    case 'arrived':
                    case 'checked-in':
                        statusValue = 'accepted';
                        break;
                    case 'noshow':
                    case 'cancelled':
                        statusValue = 'declined';
                        break;
                }
                this.status = statusValue;
                this.internalCallToStatusSetter = false;

                this.__appointmentParticipantStatus = value;
            },
        });

        // Over right getter/setter properties defined in FHIRObjectBase
        Object.defineProperty(this, 'status', {
            configurable: true,
            get() {
                return this['__status'];
            },
            set(value) {
                if (value === '' || value === null) {
                    this['__status'] = undefined;
                } else {
                    this['__status'] = value;
                }
            },
        });

        // Must be done or the new setter will not be run when the object is created
        const constructorValue = get(constructJson, 'appointmentParticipantStatus', undefined);
        if (constructorValue) {
            this.appointmentParticipantStatus = constructorValue;
        }

        this.originalObjJson = this.toJSON();
    }

    get role() {
        this.__role.splice(0);
        // ONLY codings from the 1ST codeableConcept are loaded
        // into the role property
        if (this.__type.length) {
            this.__type[0].coding.forEach((coding) => {
                this.__role.push(coding);
            });
        }
        return this.__role;
    }

    set role(value) {
        if (Array.isArray(value)) {
            this.__type.splice(0);
            this.__role.splice(0);

            value.forEach((coding) => {
                if (this.__type.length) {
                    this.__type[0].coding.push(new Coding(coding));
                } else {
                    this.__type.push(new CodeableConcept({ coding: [coding] }));
                }

                this.__role.push(new Coding(coding));
            });
            return;
        }

        if (value instanceof CodeableConcept) {
            if (this.__type.length) {
                this.__type[0].coding = value.coding[0];
            } else {
                this.__type.push(value);
            }
            this.__role.push(value.coding[0]);
            return;
        }

        if (this.__type.length) {
            this.__type[0].coding.push(new Coding(value));
        } else {
            this.__type.push(new CodeableConcept({ coding: [value] }));
        }
        this.__role.push(new Coding(value));
    }

    removeRole(searchCoding) {
        if (!(searchCoding instanceof Coding)) {
            return false;
        }

        if (!this.__type.length) {
            return false;
        }

        let codingIndex = this.__type[0].coding.findIndex((codingElement) => {
            if (searchCoding.id) {
                return codingElement.id === searchCoding.id;
            }

            return codingElement.code === searchCoding.code && codingElement.display === searchCoding.display;
        });

        if (codingIndex !== -1) {
            this.__role.splice(codingIndex, 1);
            this.__type[0].coding.splice(codingIndex, 1);
        }

        return codingIndex !== -1;
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
