import HumanName from '../HumanName';

const humanNameTraits = function () {
    Object.defineProperty(this, 'firstName', {
        get() {
            if (this.name.length === 0) {
                return '';
            }
            return this.name.find((e) => e.use === 'official')?.firstName || '';
        },
        set(value) {
            let officalName = this.name.find((e) => e.use === 'official');
            if (officalName) {
                officalName.firstName = value;
                return;
            }

            this.name = new HumanName({ use: 'official', given: [value] });
        },
    });

    Object.defineProperty(this, 'middleName', {
        get() {
            if (this.name.length === 0) {
                return '';
            }
            return this.name.find((e) => e.use === 'official')?.middleName || '';
        },
        set(value) {
            let officalName = this.name.find((e) => e.use === 'official');
            if (officalName) {
                officalName.middleName = value;
                return;
            }

            this.name = new HumanName({ use: 'official', given: [undefined, value] });
        },
    });

    Object.defineProperty(this, 'lastName', {
        get() {
            if (this.name.length === 0) {
                return '';
            }
            return this.name.find((e) => e.use === 'official')?.lastName || '';
        },
        set(value) {
            let officalName = this.name.find((e) => e.use === 'official');
            if (officalName) {
                officalName.lastName = value;
                return;
            }

            this.name = new HumanName({ use: 'official', family: value });
        },
    });

    Object.defineProperty(this, 'givenPhoneticSpelling', {
        get() {
            if (this.name.length === 0) {
                return '';
            }
            return this.name[0].givenPhoneticSpelling || '';
        },
        set(value) {
            if (this.name.length > 0) {
                this.name[0].givenPhoneticSpelling = value;
                return;
            }

            this.name = new HumanName({ givenPhoneticSpelling: value });
        },
    });

    Object.defineProperty(this, 'preferredName', {
        get() {
            if (this.name.length === 0) {
                return '';
            }
            return this.name.find((e) => e.use === 'nickname')?.given[0] || '';
        },
        set(value) {
            let officalName = this.name.find((e) => e.use === 'nickname');
            if (officalName) {
                officalName.given = [value];
                return;
            }

            this.name = new HumanName({ use: 'nickname', given: [value] });
        },
    });

    Object.defineProperty(this, 'maidenName', {
        get() {
            if (this.name.length === 0) {
                return '';
            }
            return this.name.find((e) => e.use === 'maiden')?.family || '';
        },
        set(value) {
            let maidenName = this.name.find((e) => e.use === 'maiden');
            if (maidenName) {
                maidenName.family = value;
                return;
            }

            this.name = new HumanName({ use: 'maiden', family: value });
        },
    });   
    
    Object.defineProperty(this, 'fullName', {
        get() {
            return this.buildNameString([this.firstName, this.middleName, this.lastName]);
        },
    });

    Object.defineProperty(this, 'fullNameLastFirst', {
        get() {
            let lastName = this.lastName;
            lastName += lastName && (this.firstName || this.middleName) ? ',' : '';
            return this.buildNameString([lastName, this.firstName, this.middleName]);
        },
    });

    Object.defineProperty(this, 'nameDisplay', {
        get() {
            let middleInitial = this.middleName.substr(0, 1);
            middleInitial += middleInitial ? '.' : '';
            const preferredName = this.preferredName ? '"' + this.preferredName + '"' : '';
            const givenPhoneticSpelling = this.givenPhoneticSpelling ? '(' + this.givenPhoneticSpelling + ')' : '';

            if (this.preferredName) {
                return this.buildNameString([this.firstName, preferredName, middleInitial, this.lastName]);
            }
            if (!this.preferredName && this.givenPhoneticSpelling) {
                return this.buildNameString([this.firstName, givenPhoneticSpelling, middleInitial, this.lastName]);
            }
            return this.buildNameString([this.firstName, middleInitial, this.lastName]).trim() || this.resource?.fullName || this.resource?.name || this.resource?.display;
        },
    });

    Object.defineProperty(this, 'nameDisplayLastFirst', {
        get() {
            let middleInitial = this.middleName.substr(0, 1);
            middleInitial += middleInitial ? '.' : '';
            const preferredName = this.preferredName ? '"' + this.preferredName + '"' : '';
            const givenPhoneticSpelling = this.givenPhoneticSpelling ? '(' + this.givenPhoneticSpelling + ')' : '';
            let lastName = this.lastName;
            lastName += lastName && (this.firstName || this.middleName || preferredName || givenPhoneticSpelling) ? ',' : '';

            if (this.preferredName) {
                return this.buildNameString([lastName, this.firstName, preferredName, middleInitial]);
            }
            if (!this.preferredName && this.givenPhoneticSpelling) {
                return this.buildNameString([lastName, this.firstName, givenPhoneticSpelling, middleInitial]);
            }
            return this.buildNameString([lastName, this.firstName, middleInitial]) || this.resource?.fullName || this.resource?.name || this.resource?.display;
        },
    });

    this.buildNameString = function (nameParts = []) {
        if (!Array.isArray(nameParts)) {
            nameParts = [nameParts];
        }
        let returnName = '';
        nameParts.forEach((part) => {
            if (!part) return;
            returnName = returnName ? returnName + ' ' + part : part;
        });
        return returnName;
    };

    this.getNameByIndex = function (indexNumber = 0) {
        if (indexNumber > this.name.length) {
            return undefined;
        }

        return this.name[indexNumber];
    };

    return this;
};

export default humanNameTraits;
