import { DomainResource } from './index';
import merge from 'lodash/merge';
import Reference from './types/Reference';
import CodeableConcept from './types/CodeableConcept';

export default class BN_PriorAuthorization extends DomainResource {
    static __className = 'BN_PriorAuthorization';

    __objectStructure = {
        coverage: Reference, // Coverage
        renderingProvider: Reference, // Practitioner
        healthcareService: [Reference], // office code, used to be BN_PriorAuthorizationService
        diagnosis: CodeableConcept, // originally stored in BN_PriorAuthorizationService
        responseStartDate: String,
        responseEndDate: String,
        authorizationNumber: String,
        allowedUnits: Number,
        consumedUnits: String,
        allowedDollarAmount: Number,
        comment: String,
        patient: Reference,
        active: Boolean,
        // below are the properties that were originally intended to be sent to a clearing house

        // parent: Reference,
        // facility: Reference, // Location
        // frequencyInstructions: String,
        // reviewType: String,
        // requestType: String,
        // type: [String],
        // requestingProvider: Reference, // Practitioner
        // units
    };

    constructor(constructJson, className = 'BN_PriorAuthorization') {
        super(constructJson, className);
        this.createAndPopulateStructure(this.__objectStructure, constructJson);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
