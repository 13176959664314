import { Extension, Narrative, Reference, Resource, traits } from './index';

import get from 'lodash/get';
import merge from 'lodash/merge';
import createFhirResource from './FhirResourceFactory';

export default class DomainResource extends Resource {
    static __className = 'DomainResource';

    constructor(domainResourceJson, className = 'DomainResource') {
        super(domainResourceJson, className);

        traits.extensionTraits.call(this);

        this.__resourceType = get(domainResourceJson, 'resourceType', this.__className);
        this.__resourceId = this.resourceType + '_' + this.id;

        this.__text = new Narrative(get(domainResourceJson, 'text', undefined));

        this.__contained = [];
        let containedInfo = get(domainResourceJson, 'contained', []);
        containedInfo.forEach((containedItem) => {
            this.contained = containedItem;
        });

        this.__extension = [];
        let extensionInfo = get(domainResourceJson, 'extension', []);
        extensionInfo.forEach((extensionItem) => {
            this.__extension.push(new Extension(extensionItem));
        });

        this.__modifierExtension = [];
        let modifierExtensionInfo = get(domainResourceJson, 'modifierExtension', []);
        modifierExtensionInfo.forEach((_modifierExtensionItem) => {
            this.__modifierExtension.push(new Extension(_modifierExtensionItem));
        });

        this.__bn = {};
        let dynamicExtensionValue = this.getDynamicExtensionValue('bn');
        if (dynamicExtensionValue) {
            this.extensionToJson(this.__bn, dynamicExtensionValue);
        }

        this.__temp = get(domainResourceJson, 'temp', {});

        this.originalObjJson = this.toJSON();
        this.elementConstructorCompleted = true;
    }

    // Read only properties

    get resourceId() {
        return this.__resourceId;
    }

    get resourceType() {
        return this.__resourceType;
    }

    // Properties

    get text() {
        return this.__text;
    }

    set text(value) {
        this.__text = value;
    }

    get contained() {
        return this.__contained;
    }

    set contained(value) {
        if (Array.isArray(value)) {
            this.__contained = value;
            return;
        }

        if (value instanceof Resource) {
            this.__contained.push(value);
            return;
        }

        if (value.resourceType) {
            this.__contained.push(createFhirResource(value.resourceType, value));
        } else {
            this.__contained.push(value);
        }
    }

    get extension() {
        return this.__extension;
    }

    set extension(value) {
        if (Array.isArray(value)) {
            this.__extension = value;
            return;
        }

        if (value instanceof Extension) {
            this.__extension.push(value);
            return;
        }

        this.__extension.push(new Extension(value));
    }

    get modifierExtension() {
        return this.__modifierExtension;
    }

    set modifierExtension(value) {
        if (Array.isArray(value)) {
            this.__modifierExtension = value;
            return;
        }

        if (value instanceof Extension) {
            this.__modifierExtension.push(value);
            return;
        }

        this.__modifierExtension.push(new Extension(value));
    }

    // Methods

    getReference() {
        return new Reference({ id: this.id, type: this.resourceType, resourceType: this.resourceType });
    }

    get bn() {
        if (this.__bn === undefined) {
            this.__bn = {};
        }
        return this.__bn;
    }

    set bn(value) {
        this.__bn = value;
    }

    get temp() {
        if (this.__temp === undefined || this.__temp === null) {
            this.__temp = {};
        }
        return this.__temp;
    }

    set temp(value) {
        this.__temp = value;
    }

    toJSON() {
        this.handleDynamicExtensionToJson?.('bn');

        return merge(
            super.toJSON(this),
            this.removeUndefinedObjProps({
                resourceType: this.resourceType,
                text: this.propertyToJson(this.text),
                contained: this.arrayPropertyToJson(this.contained),
                extension: this.arrayPropertyToJson(this.extension),
                modifierExtension: this.arrayPropertyToJson(this.modifierExtension),
            }),
        );
    }
}
