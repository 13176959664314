import { DomainResource } from './index';
import merge from 'lodash/merge';
import AppointmentServiceModifier from '@/fhirworks/AppointmentServiceModifier';
import AppointmentServiceAddOn from '@/fhirworks/AppointmentServiceAddOn';

export default class AppointmentService extends DomainResource {
    static __className = 'AppointmentService';

    __objectStructure = {
        id: String,
        officeCode: String,
        billingCode: String,
        description: String,
        unit: Number,
        modifier: [AppointmentServiceModifier],
        addOn: [AppointmentServiceAddOn],
    };

    constructor(constructJson, className = 'AppointmentService') {
        super(constructJson, className);

        this.createAndPopulateStructure(this.__objectStructure, constructJson);

        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
