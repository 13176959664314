import { FHIRObjectBase } from '../index';
import merge from 'lodash/merge';

export default class BaseElement extends FHIRObjectBase {
    static __className = 'BaseElement';

    constructor(elementJson, className = 'BaseElement') {
        super(className);

        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
