import { BackboneElement } from './index';

import merge from 'lodash/merge';

export default class BundleEntryRequest extends BackboneElement {
    static __className = 'BundleEntryRequest';

    __objectStructure = {
        method: String, // GET | HEAD | POST | PUT | DELETE | PATCH
        url: String, // URL for HTTP equivalent of this entry
        ifNoneMatch: String, // For managing cache currency
        ifModifiedSince: String, // For managing cache currency
        ifMatch: String, // For managing update contention
        ifNoneExist: String, // For conditional creates
    };

    constructor(constructJson, className = 'BundleEntryRequest') {
        super(constructJson, className);

        this.createAndPopulateStructure(this.__objectStructure, constructJson);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
