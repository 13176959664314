import { Element } from './index';
import Period from './types/Period';
import merge from 'lodash/merge';

export default class ContactPoint extends Element {
    static __className = 'ContactPoint';

    __objectStructure = {
        system: String, // phone | fax | email | pager | url | sms | other
        value: String, // The actual contact point details
        use: String, // home | work | temp | old | mobile - purpose of this contact point
        rank: Number, // Specify preferred order of use (1 = highest)
        period: Period, // Time period when the contact point was/is in use
        /**
         * AidBox First Class extensions
         */
        doNotContact: Boolean,
    };

    constructor(constructJson, className = 'ContactPoint') {
        super(constructJson, className);

        this.createAndPopulateStructure(this.__objectStructure, constructJson);

        this.originalObjJson = this.toJSON();
    }

    // *************** Custom Getters/Setters ***************

    isPhone() {
        return this.__system === 'phone';
    }

    isEmail() {
        return this.__system === 'email';
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
