import { BaseElement } from '../index';
import Coding from '../types/Coding';
import merge from 'lodash/merge';
import codeableConceptTraits from '../trait/codeableConceptTraits';

/**
 * @property {String} text
 * @property {Coding} coding
 */
export default class CodeableConcept extends BaseElement {
    static __className = 'CodeableConcept';

    __objectStructure = {
        text: String,
        coding: [Coding],
    };

    constructor(constructJson, className = 'CodeableConcept') {
        super(constructJson, className);

        codeableConceptTraits.call(this);

        this.createAndPopulateStructure(this.__objectStructure, constructJson, this.__objectDefaults);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
