import { DomainResource } from './index';
import merge from 'lodash/merge';
import Reference from './types/Reference';
import BN_ProfileManagedItem from '@/fhirworks/BN_ProfileManagedItem';

export default class BN_ProfileManaged extends DomainResource {
    static __className = 'BN_ProfileManaged';

    __objectStructure = {
        url: String,
        sourceProgramFullUrl: String,
        category: String,
        title: String,
        profileOwner: Reference,
        item: [BN_ProfileManagedItem], // Bn_ProfileItem
        status: String, // active, archived
        version: String, // 1,2
    };

    constructor(constructJson, className = 'BN_ProfileManaged') {
        super(constructJson, className);
        this.createAndPopulateStructure(this.__objectStructure, constructJson);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
