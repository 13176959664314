import { BackboneElement } from './index';
import CodeableConcept from './types/CodeableConcept';
import Reference from './types/Reference';

import merge from 'lodash/merge';

export default class ClaimCareTeam extends BackboneElement {
    static __className = 'ClaimCareTeam';

    __objectStructure = {
        sequence: Number,
        provider: Reference,
        responsible: Boolean,
        role: CodeableConcept,
        qualification: CodeableConcept,
    };

    constructor(constructJson, className = 'ClaimCareTeam') {
        super(constructJson, className);

        this.createAndPopulateStructure(this.__objectStructure, constructJson);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
