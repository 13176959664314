import { DomainResource } from './index';
import merge from 'lodash/merge';
import Reference from './types/Reference';
import CodeableConcept from './types/CodeableConcept';
import ValueX from './types/ValueX';
import EffectiveX from './types/EffectiveX';

export default class Observation extends DomainResource {
    static _className = 'Observation';

    _objectStructure = {
        partOf: [Reference],
        status: String,
        category: [CodeableConcept],
        code: CodeableConcept,
        subject: Reference,
        focus: [Reference],
        encounter: Reference,
        issued: String,
        performer: [Reference],
        value: ValueX,
        derivedFrom: [Reference],
        effective: EffectiveX,
        hasMember: [Reference],
    };

    _objectDefaults = {
        experimental: false,
    };

    constructor(constructJson, className = 'Observation') {
        super(constructJson, className);
        this.createAndPopulateStructure(this._objectStructure, constructJson, this._objectDefaults);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this._objectStructure));
    }
}
