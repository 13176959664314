import { DomainResource } from './index';
import merge from 'lodash/merge';
import Reference from './types/Reference';
import CodeableConcept from './types/CodeableConcept';
import MedicationX from '@/fhirworks/types/MedicationX';
import Dosage from '@/fhirworks/types/Dosage';
import Period from '@/fhirworks/types/Period';
import ReportedX from '@/fhirworks/types/ReportedX';
import Coding from './types/Coding';

export default class MedicationRequest extends DomainResource {
    static __className = 'MedicationRequest';

    __objectStructure = {
        status: String, // active | on-hold | cancelled | completed | entered-in-error | stopped | draft | unknown
        statusReason: [CodeableConcept],
        intent: String, // proposal | plan | order | original-order | reflex-order | filler-order | instance-order | option
        category: [CodeableConcept],
        reported: ReportedX,
        medication: MedicationX,
        subject: Reference,
        encounter: Reference,
        supportingInformation: [Reference],
        authoredOn: String,
        requester: Reference,
        performer: Reference,
        performerType: CodeableConcept,
        recorder: Reference,
        dosageInstruction: [Dosage],
        reasonCode: [CodeableConcept],

        // first class extensions
        effective: Period,
        isMedicationEffective: Coding,
        takenAsDirected: Coding,
    };

    constructor(constructJson, className = 'MedicationRequest') {
        super(constructJson, className);
        this.createAndPopulateStructure(this.__objectStructure, constructJson);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
