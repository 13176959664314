import merge from 'lodash/merge';
import { BackboneElement } from './index';
import Period from './types/Period';

export default class PractitionerRoleNotAvailable extends BackboneElement {
    static __className = 'PractitionerRoleNotAvailable';

    __objectStructure = {
        description: String, // Reason presented to the user explaining why time not available
        during: Period, // Service not available from this date
    };

    constructor(constructJson, className = 'PractitionerRoleNotAvailable') {
        super(constructJson, className);

        this.createAndPopulateStructure(this.__objectStructure, constructJson);

        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
