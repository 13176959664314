import { BackboneElement } from './index';
import CoverageEligibilityResponseInsuranceItemBenefit from './CoverageEligibilityResponseInsuranceItemBenefit';
import CodeableConcept from './types/CodeableConcept';
import Reference from './types/Reference';
import Period from './types/Period';
import Money from './types/Money';

import merge from 'lodash/merge';

export default class CoverageEligibilityResponseInsuranceItem extends BackboneElement {
    static __className = 'CoverageEligibilityResponseInsuranceItem';

    __objectStructure = {
        category: CodeableConcept,
        productOrService: CodeableConcept,
        modifier: [CodeableConcept],
        provider: Reference,
        excluded: Boolean,
        name: String,
        description: String,
        network: CodeableConcept,
        unit: CodeableConcept,
        term: CodeableConcept,
        benefit: [CoverageEligibilityResponseInsuranceItemBenefit],
        authorizationRequired: Boolean,
        authorizationSupporting: [CodeableConcept],
        authorizationUrl: String, // Preauthorization requirements endpoint
        /**
         * AidBox First Class extensions
         */
        quantity: String,
        period: Period,
        remaining: Money,
        year: String,
    };

    constructor(constructJson, className = 'CoverageEligibilityResponseInsuranceItem') {
        super(constructJson, className);
        this.createAndPopulateStructure(this.__objectStructure, constructJson);

        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
