import { BackboneElement } from './index';
import merge from 'lodash/merge';
import Reference from './types/Reference';
import Period from "./types/Period";

export default class GroupMember extends BackboneElement {
    static __className = 'GroupMember';

    __objectStructure = {
        entity: Reference,
        period: Period,
        inactive: Boolean,
    };

    constructor(constructJson, className = 'GroupMember') {
        super(constructJson, className);
        this.createAndPopulateStructure(this.__objectStructure, constructJson);
        this.originalObjJson = this.toJSON();
    }
    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
