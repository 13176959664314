import { Element, Extension } from './index';

import get from 'lodash/get';
import merge from 'lodash/merge';

/**
 * @property {Extension} modifierExtension
 */
export default class BackboneElement extends Element {
    static __className = 'BackboneElement';

    constructor(backboneElementJson, className = 'BackboneElement') {
        super(backboneElementJson, className);

        this._modifierExtension = [];
        let modifierExtensionInfo = get(backboneElementJson, 'modifierExtension', []);
        modifierExtensionInfo.forEach((_modifierExtensionItem) => {
            this._modifierExtension.push(new Extension(_modifierExtensionItem));
        });

        this.originalObjJson = this.toJSON();
    }

    // Properties

    get modifierExtension() {
        return this._modifierExtension;
    }

    set modifierExtension(value) {
        if (Array.isArray(value)) {
            this._modifierExtension = value;
            return;
        }

        if (value instanceof Extension) {
            this._modifierExtension.push(value);
            return;
        }

        this._modifierExtension.push(new Extension(value));
    }

    toJSON() {
        return merge(
            super.toJSON(this),
            this.removeUndefinedObjProps({
                modifierExtension: this.arrayPropertyToJson(this.modifierExtension),
            }),
        );
    }
}
