import { BackboneElement } from './index';
import merge from 'lodash/merge';
import ValueSetComposeIncludeConcept from '@/fhirworks/ValueSetComposeIncludeConcept';
import ValueSet from '@/fhirworks/ValueSet';
import ValueSetComposeIncludeFilter from '@/fhirworks/ValueSetComposeIncludeFilter';

export default class ValueSetComposeInclude extends BackboneElement {
    static __className = 'ValueSetComposeInclude';

    __objectStructure = {
        system: String, // The system the codes come from
        version: String, // Specific version of the code system referred to
        concept: [ValueSetComposeIncludeConcept], // A concept defined in the system
        filter: [ValueSetComposeIncludeFilter], // Select codes/concepts by their properties (including relationships)
        valueSet: [ValueSet], // Select the contents included in this value set
    };

    constructor(constructJson, className = 'ValueSetComposeInclude') {
        super(constructJson, className);
        this.createAndPopulateStructure(this.__objectStructure, constructJson);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
