import { Address, BackboneElement, ContactPoint, HumanName, Reference } from './index';
import merge from 'lodash/merge';
import CodeableConcept from './types/CodeableConcept';
import { contactPointSystemValues, contactPointTraits, contactPointUseValues } from './trait/contactPointTraits';
import { addressTraits } from './trait/addressTraits';
import Period from './types/Period';
import humanNameTraits from './trait/humanNameTraits';
import Coding from '@/fhirworks/types/Coding';

export default class PatientContact extends BackboneElement {
    static __className = 'PatientContact';

    __objectStructure = {
        relationship: [CodeableConcept],
        name: HumanName,
        telecom: [ContactPoint],
        address: Address,
        gender: String,
        organization: Reference,
        period: Period,
        /**
         * First Class extensions
         */
        sendSms: String,
    };

    constructor(constructJson, className = 'PatientContact') {
        super(constructJson, className);
        addressTraits.call(this);
        contactPointTraits.call(this);
        humanNameTraits.call(this);

        this.createAndPopulateStructure(this.__objectStructure, constructJson);
        this.defaultContactPointSystemValue = contactPointSystemValues.phone;
        this.defaultContactPointUseType = contactPointUseValues.mobile;

        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
