import { BaseElement, FHIRObjectBase, traits } from './index';
import createFhirResource from './FhirResourceFactory';

import get from 'lodash/get';
import merge from 'lodash/merge';

const fhirVersion = 'r4';
const defaultBNExtensionStructureDefinitionUrlBase = 'http://fhir.bestnotes.com/' + fhirVersion + '/StructureDefinition/';
const defaultFHIRExtensionStructureDefinitionUrlBase = 'http://hl7.org/fhir/StructureDefinition/';
const defaultFHIRUSCoreExtensionStructureDefinitionUrlBase = 'http://hl7.org/fhir/us/core/StructureDefinition/';

export default class Extension extends BaseElement {
    static __className = 'Extension';

    constructor(extensionJson, className = 'Extension') {
        super(extensionJson, className);

        traits.extensionTraits.call(this);

        // Object keys will not process the required property values
        // if extensionJson is an Extension, was causing issues in
        // Rest method of FHIRObjectBase
        if (extensionJson instanceof Extension) {
            extensionJson = extensionJson.toJSON();
        }

        this.__id = get(extensionJson, 'id', undefined);
        this.__extension = [];
        let extensionInfo = get(extensionJson, 'extension', []);
        extensionInfo.forEach((extensionItem) => {
            this.__extension.push(new Extension(extensionItem));
        });

        this.__url = get(extensionJson, 'url', undefined);

        this.__extensionValuePropertyName = Object.keys(extensionJson).filter((value) => {
            return value.startsWith('value') || value === 'extension';
        })[0];

        // No value property was passed
        if (!this.__extensionValuePropertyName) {
            this.originalObjJson = this.toJSON();
            return;
        }

        this.__extensionValueType = this.__extensionValuePropertyName.replace('value', '');
        let extensionValueTypeJson = get(extensionJson, this.__extensionValuePropertyName, undefined);

        this.__value = this.buildValuePropertyObj(this.__extensionValueType, extensionValueTypeJson);

        this.originalObjJson = this.toJSON();
    }

    // Read only properties

    get url() {
        return this.__url;
    }

    set url(value) {
        this.__url = value;
    }

    get value() {
        return this.__value;
    }

    set value(value) {
        if (typeof value !== 'object') {
            throw 'Extension value requires object';
        }
        let valueType = value.valueType;
        let valueJson = value.valueJson;

        if (!valueType) {
            throw 'ValueType is required';
        }

        this.__extensionValueType = valueType;
        this.__extensionValuePropertyName = 'value' + valueType;

        this.__value = this.buildValuePropertyObj(valueType, valueJson);
    }

    buildValuePropertyObj(valueType, valueJson) {
        if (!valueType) {
            throw 'ValueType is required';
        }
        if (typeof valueJson === 'undefined' || valueJson === '') {
            return valueType === 'extension' ? [] : undefined;
        }

        let returnValue = '';
        switch (valueType) {
            case 'extension':
                returnValue = [];
                valueJson.forEach((extensionItem) => {
                    returnValue.push(new Extension(extensionItem));
                });

                break;
            case 'String':
                returnValue = String(valueJson);
                break;
            case 'Boolean':
                if (typeof valueJson === 'boolean') {
                    returnValue = valueJson;
                } else {
                    returnValue = valueJson.toLowerCase().includes('true');
                }
                break;
            case 'Integer':
                returnValue = parseInt(valueJson);
                break;
            case 'Decimal':
                returnValue = parseFloat(valueJson);
                break;
            // Was matching CodeableConcept, done so that Exact match is used
            case valueType === 'Code' ? 'Code' : '':
                returnValue = String(valueJson);
                break;
            default:
                returnValue = createFhirResource(valueType, valueJson);
                if (!(returnValue instanceof FHIRObjectBase)) {
                    throw 'Extension Type: ' + valueType + ' NOT Found';
                }
        }
        return returnValue;
    }

    get extension() {
        return this.__extension;
    }

    set extension(value) {
        // Array
        if (Array.isArray(value)) {
            this.__extension = [];

            let addToValues = false;
            if (Array.isArray(this.__value)) {
                this.__value = [];
                addToValues = true;
            }

            // Done so that they are 2 independent arrays
            value.forEach((item) => {
                this.__extension.push(item);
                if (addToValues) {
                    this.__value.push(item);
                }
            });
            return;
        }

        // FHIR Extension
        if (value instanceof Extension) {
            this.__extension.push(value);

            if (Array.isArray(this.__value)) {
                this.__value.push(value);
            }
            return;
        }

        // undefined
        if (value === undefined) {
            this.__extension = [];
            if (Array.isArray(this.__value)) {
                this.__value = [];
            }
            return;
        }

        // JSON
        let newValue = new Extension(value);
        this.__extension.push(newValue);
        if (Array.isArray(this.__value)) {
            this.__value.push(newValue);
        }
    }

    // Read only properties

    get id() {
        return this.__id;
    }

    toJSON() {
        // Determine property name,  valueextension is not valid, extension IS
        let propertyName = this.__extensionValuePropertyName === 'valueextension' ? 'extension' : this.__extensionValuePropertyName;
        let extensionValue = Array.isArray(this.value) ? this.arrayPropertyToJson(this.value) : this.propertyToJson(this.value);
        let jsonObj = {
            url: this.url,
            [propertyName]: extensionValue,
        };
        // If the propertyName is NOT extension, extension needs to be added
        if (propertyName !== 'extension') {
            jsonObj.extension = this.arrayPropertyToJson(this.extension);
        }

        return merge(super.toJSON(this), this.removeUndefinedObjProps(jsonObj));
    }
}

export { defaultBNExtensionStructureDefinitionUrlBase, defaultFHIRExtensionStructureDefinitionUrlBase, defaultFHIRUSCoreExtensionStructureDefinitionUrlBase, Extension };
