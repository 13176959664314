const MyPlugin = {
    install(Vue) {
        Vue.directive('focus', {
            inserted: function (el) {
                el.focus();
            },
        });
    },
};

export default MyPlugin;
