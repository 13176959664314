/* eslint-disable security/detect-object-injection */
import { useSlots } from 'vue';
import { $httpFhirApi } from '@/common/api/httpFhir.service';
import { createFhirResource, Resource } from '@/fhirworks';
import config, { isRetryableError } from '@/common/autoSaveConfig';
import { getCacheHeaders } from '@/common/api/cacheHeaders.config';

import debounce from 'lodash/debounce';
import differenceWith from 'lodash/differenceWith';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import uniqBy from 'lodash/uniqBy';
import isEmpty from 'lodash/isEmpty';
import { getUrlFromCanonical } from '@/common/core';

const object2QueryString = (query) => {
    if (typeof query === 'string') return '?' + encodeURI(query);

    let queryString = '';

    if (query) {
        queryString = Object.keys(query)
            .map((key) => key + '=' + query[key])
            .join('&');
    }
    if (queryString) {
        queryString = '?' + queryString;
    }
    return queryString;
};

const parseResource = (resource) => {
    if (!resource || isEmpty(resource) || resource instanceof Resource || (Array.isArray(resource) && resource.every((r) => r instanceof Resource))) {
        return resource;
    }

    if (Array.isArray(resource)) {
        return resource.map((r) => createFhirResource(null, r));
    }

    return createFhirResource(null, resource);
};

export default {
    name: 'DataProvider',
    props: {
        modelValue: Object | Array,
        resource: Object | Array,
        link: Object | Array,
        loading: Boolean,
        saving: Boolean,
        query: Object | Array,
        autosave: Boolean,
        autosaveConfig: Object,
        autosaveWhen: Function,
        saveTimeout: Number, // timeout for saves in ms, 0 for no timeout, default is 30 defined in httpFhir.service.js
        validator: Object | Array,
        collection: Boolean,
        subscribe: String | Array,
        /**
         * This prop is used to refresh the data-provider when the :query changes.
         * for example getting related contacts for a contact in a select box.  you want to refresh every time you change the contact dropdown.
         */
        refreshOnChange: Boolean,
        errorHandle: {
            type: Boolean,
            default: true,
        },
    },
    emits: [
        'update:modelValue',
        'update:link',
        'update:loading',
        'update:saving',
        'saved',
        'saving',
        'needsSaved',
        'saveError',
        'loaded',
        'loading',
        'loadError',
        'serverPush',
        'loadingItems',
        'loadedItems',
        'error',
        'itemRemoved',
    ],
    data() {
        return {
            data: null,
            saveFailed: false,
            isLoading: true,
            isSaving: false, // value for other to tie into to see if data-provider is saving
            isSavingToServer: false, // value to see if we are actively sending data to server
            hasPendingSave: false, // used to determine if autosave functionality has a pending save so it can ignore subsequent requests.
            loadError: null,
            saveError: null,
            autosaveFunc: [],
        };
    },
    created() {
        if (this.collection) {
            this.data = [];
            this.saveError = [];
        }
        if (this.resource) {
            if (!this.collection && this.query) {
                throw new Error('Invalid props for non collection provider, You can’t have both resource and query props without a collection prop');
            }
            this.data = parseResource(this.resource);
            this.isLoading = false;
            this.$emit('update:loading', false);
            this.$emit('update:modelValue', this.data);
        }
    },
    mounted() {
        if (this.resource && !this.query) {
            this.$emit('loaded');
        }
    },
    watch: {
        query: {
            handler(newVal, oldVal) {
                if (!newVal || isEqual(newVal, oldVal)) return;
                this.isSaving = false;
                !this.collection ? this.fetch() : this.fetchCollection(this.refreshOnChange);
            },
            immediate: true,
            deep: true,
        },
        resource: {
            handler(newVal, oldVal) {
                // if the value needsSaved() we know the watcher is from the resource being changed so return;
                if (!newVal || isEqual(newVal, oldVal) || (!this.collection && newVal?.needsSaved())) return;

                if (!this.collection) {
                    this.data = parseResource(this.resource) || null;
                    if (this.query) {
                        this.fetch();
                    }
                } else {
                    this.data = parseResource(this.resource) || [];
                    if (this.query) {
                        this.fetchCollection(this.refreshOnChange);
                    }
                }
            },
            deep: true,
        },
        data: {
            handler() {
                if (typeof this.data?.needsSaved === 'function') {
                    this.$emit('needsSaved', this.data?.needsSaved());
                }

                // if ANY item needs save, update needsSaved
                if (this.collection && Array.isArray(this.data) && this.data?.some((d) => typeof d?.needsSaved === 'function')) {
                    this.$emit(
                        'needsSaved',
                        this.data?.some((d) => typeof d?.needsSaved === 'function' && d?.needsSaved()),
                    );
                }
            },
            deep: true,
        },
        computedData: {
            handler(newVal, oldVal) {
                this.$emit('update:modelValue', this.data);
                if (!oldVal || !this.autosave) return;

                this.isSaving = true;
                this.$emit('update:saving', true);
                this.$emit('saving');

                let indexes = [0];
                if (this.collection) {
                    if (newVal && oldVal && newVal.length !== oldVal.length) {
                        this.isSaving = false;
                        this.$emit('update:saving', false);
                        return;
                    }
                    // get the differences in items in data provider to process.
                    let QRsToProcess = differenceWith(newVal, oldVal, isEqual);

                    indexes = QRsToProcess.map((qr) => newVal.findIndex((obj) => isEqual(obj, qr)));
                    let changedIndexes = indexes;

                    for (let i = 0; i < changedIndexes.length; i++) {
                        let index = changedIndexes[i];

                        let newValWithoutJson = cloneDeep(newVal[index]);
                        let oldValWithoutJson = cloneDeep(oldVal[index]);

                        if (
                            newValWithoutJson &&
                            oldValWithoutJson &&
                            Object.prototype.hasOwnProperty.call(newValWithoutJson, 'originalObjJson') &&
                            Object.prototype.hasOwnProperty.call(oldValWithoutJson, 'originalObjJson')
                        ) {
                            newValWithoutJson.originalObjJson = null;
                            oldValWithoutJson.originalObjJson = null;

                            // Make sure changing of originalObjJson (altered when saving), doesn't trigger another save.
                            if (!isEqual(newVal[index].originalObjJson, oldVal[index].originalObjJson) && isEqual(newValWithoutJson, oldValWithoutJson)) {
                                indexes = indexes.filter((i) => i !== index);
                            }
                        }
                    }
                } else {
                    // not a collection
                    let newValWithoutJson = cloneDeep(newVal);
                    let oldValWithoutJson = cloneDeep(oldVal);

                    if (Object.prototype.hasOwnProperty.call(newValWithoutJson, 'originalObjJson') && Object.prototype.hasOwnProperty.call(oldValWithoutJson, 'originalObjJson')) {
                        newValWithoutJson.originalObjJson = null;
                        oldValWithoutJson.originalObjJson = null;

                        // Make sure changing of originalObjJson (altered when saving), doesn't trigger another save.
                        if (!isEqual(newVal.originalObjJson, oldVal.originalObjJson) && isEqual(newValWithoutJson, oldValWithoutJson)) {
                            this.isSaving = false;
                            this.$emit('update:saving', false);
                            return;
                        }
                    }
                }

                if (!indexes.length) {
                    this.isSaving = false;
                    this.$emit('update:saving', false);
                    return;
                }

                for (let i = 0; i < indexes.length; i++) {
                    let index = indexes[i];

                    if (
                        (this.autosave && !this.collection) ||
                        (this.autosave && this.collection && typeof this.autosaveWhen !== 'function') ||
                        (this.autosave && this.collection && typeof this.autosaveWhen === 'function' && this.autosaveWhen(this.data[index]))
                    ) {
                        if (typeof this.autosaveFunc[index] !== 'function') {
                            this.autosaveFunc[index] = debounce(
                                async (itemToSave) => {
                                    if (!this.isSavingToServer) {
                                        await this.save(itemToSave, this.autosaveConfig);
                                    } else if (this.hasPendingSave) {
                                        return;
                                    } else {
                                        this.hasPendingSave = true;
                                        let waitForSavedToServer = new Promise((resolve) => {
                                            let interval = setInterval(() => {
                                                if (this.isSavingToServer) {
                                                    return;
                                                }

                                                clearInterval(interval);
                                                resolve();
                                            }, 100);
                                        });

                                        await waitForSavedToServer;
                                        await this.save(itemToSave, this.autosaveConfig);
                                        this.hasPendingSave = false;
                                    }
                                },
                                config.debounceTime,
                                { maxWait: config.maxTime },
                            );
                        }

                        this.autosaveFunc[index](this.collection ? this.data[index] : null);
                    }
                }
            },
            deep: true,
        },
    },
    computed: {
        computedData() {
            return this.data ? JSON.parse(JSON.stringify(this.data)) : undefined;
        },
    },
    methods: {
        emitServerPush(data) {
            this.$emit('serverPush', data);
        },
        getByType(type) {
            return this.getBy((item) => item.resourceType === type);
        },
        getBy(filter) {
            if (!this.data) return;
            return this.data.filter(filter);
        },
        getOneById(id) {
            return this.getOneBy((item) => item.id === id);
        },
        getOneBy(filter) {
            return this.data.find(filter);
        },
        refresh() {
            !this.collection ? this.fetch() : this.fetchCollection(true);
        },
        async waitForSave() {
            return new Promise((resolve) => {
                let interval = setInterval(() => {
                    if (this.isSaving) {
                        return;
                    }

                    clearInterval(interval);
                    resolve();
                }, 100);
            });
        },
        async waitForLoad() {
            return new Promise((resolve) => {
                let interval = setInterval(() => {
                    if (this.isLoading) {
                        return;
                    }

                    clearInterval(interval);
                    resolve();
                }, 100);
            });
        },
        fetch() {
            if (Array.isArray(this.query)) {
                throw new Error('You cannot use an array with query prop for non collection autosave.');
            }

            if (!this.query?.id && !this.query?.resourceType && !this.query?.url) {
                return;
            }

            this.isLoading = true;
            this.$emit('update:loading', true);
            this.$emit('loading');

            // allow for custom queries rather than just simple fetch by ID
            let args = [];
            if (this.query.url) {
                // You can pass a fully qualified URL as the query and it will be loaded.
                // Helpful when querying an external resource such as the library server
                // { query: { url: 'https://fhir.bestnotes.com/Entity?something=somethingelse' } }
                args.push(this.query.url);
            } else if (this.query.id) {
                args.push(this.query.resourceType + '/' + this.query.id);
            } else {
                args.push(this.query.resourceType + '?' + this.query.query);
            }
            let config = {};

            if (this.query?.terminology) {
                let cacheHeaders = getCacheHeaders(3600);
                config.headers = cacheHeaders;

                if (!import.meta.env.VITE_TERMINOLOGY_LOCAL) {
                    config.baseURL = import.meta.env.VITE_TERMINOLOGY_URL || 'https://terminology.fhir.bestnotesfhir.com';
                }
            }

            if (this.query?.cache !== null && this.query?.cache !== undefined) {
                let time = typeof this.query?.cache === 'number' ? this.query?.cache : 0;
                let cacheHeaders = getCacheHeaders(time);
                if (cacheHeaders) config.headers = cacheHeaders;
            }

            if (!this.errorHandle) {
                config.errorHandle = false;
            }

            if (!isEmpty(config)) {
                args.push(config);
            }

            $httpFhirApi
                .get(...args)
                .then((response) => {
                    this.loadError = null;
                    // custom queries return data as an array by default - return item 0
                    this.data = Array.isArray(response.data) ? response.data[0] : response.data;
                    this.$emit('update:modelValue', this.data);
                    this.isLoading = false;
                    this.$emit('update:loading', false);
                    this.$nextTick(() => {
                        this.$emit('loaded');
                    });
                })
                .catch((error) => {
                    this.loadError = error;
                    this.isLoading = false;
                    this.$emit('update:loading', false);
                    this.$nextTick(() => {
                        this.$emit('loadError', error);
                    });
                });
        },
        fetchCollection(isRefresh = false) {
            this.isLoading = true;
            this.$emit('update:loading', true);
            this.$emit('loading');
            this.fetchItemPromise(this.query)
                .then(async (results) => {
                    this.loadError = null;
                    let existingResults = this.data;
                    if (isRefresh) {
                        existingResults = parseResource(this.resource) || [];
                    }

                    this.data = existingResults.concat(results.data || results);
                    // remove duplicates (can be caused by overlapping queries - specifically include/revinclude since they are not automatically de-duped.)
                    this.data = uniqBy(this.data, 'id');
                    // include pagination links
                    // this.link = results.link;

                    this.$emit('update:link', results.link);
                    this.$emit('update:modelValue', this.data);
                    this.isLoading = false;
                    this.$emit('update:loading', false);
                    await this.$nextTick();
                    this.$emit('loaded');
                })
                .catch((error) => {
                    this.loadError = error;
                    this.isLoading = false;
                    this.$emit('update:loading', false);
                    this.$emit('loadError', error);
                });
        },
        fetchItems(query) {
            this.$emit('loadingItems');
            this.fetchItemPromise(query)
                .then((results) => {
                    this.data = this.data.concat(results.data || results);
                    // remove duplicates (can be caused by overlapping queries - specifically include/revinclude since they are not automatically de-duped.)
                    this.data = uniqBy(this.data, 'id');
                    // include pagination links
                    // this.link = results.link;

                    this.$emit('update:link', results.link);
                    this.$emit('update:modelValue', this.data);
                    this.$nextTick(() => {
                        this.$emit('loadedItems', results.link);
                    });
                })
                .catch((error) => {
                    this.$emit('error', error);
                });
        },
        fetchItemPromise(query) {
            let queries = !Array.isArray(query) ? [query] : query;

            let promises = [];
            let data = [];
            let link = [];

            queries
                .filter((query) => query?.resourceType || query?.url || query?.canonicalUrl)
                .forEach((query) => {
                    let args = [];
                    let config = {};

                    if (query.id) {
                        args.push(query.resourceType + '?id=' + query.id);
                    } else if (query.canonicalUrl) {
                        let baseUrl = getUrlFromCanonical(query.canonicalUrl).fullUrl;
                        // Finally, add any query information passed
                        if (query.query) {
                            baseUrl += '&' + object2QueryString(query.query).substr(1);
                        }
                        args.push(baseUrl);
                    } else if (query.url) {
                        args.push(query.url);
                    } else if (query.query) {
                        args.push(query.resourceType + object2QueryString(query.query));
                    } else {
                        args.push(query.resourceType);
                    }

                    if (query?.terminology) {
                        let cacheHeaders = getCacheHeaders(3600);
                        config.headers = cacheHeaders;
                        config.terminology = true;

                        if (!import.meta.env.VITE_TERMINOLOGY_LOCAL) {
                            config.baseURL = import.meta.env.VITE_TERMINOLOGY_URL || 'https://terminology.fhir.bestnotesfhir.com';
                        }
                    }

                    if (query?.cache !== null && query?.cache !== undefined) {
                        let time = typeof query?.cache === 'number' ? query?.cache : 0;
                        let cacheHeaders = getCacheHeaders(time);
                        if (cacheHeaders) config.headers = cacheHeaders;
                    }

                    if (!this.errorHandle) {
                        config.errorHandle = false;
                    }

                    if (!isEmpty(config)) {
                        args.push(config);
                    }

                    promises.push($httpFhirApi.get(...args));
                });

            if (!promises.length) {
                return Promise.reject();
            }

            // return new Promise((resolve, reject) => {
            //     Promise.all(promises)
            //         .then((values) => {
            //             values.forEach((value) => {
            //                 data = data.concat(value.data);
            //                 link = link.concat(value.originalData?.link);
            //             });
            //             resolve({ data: data, link: link });
            //         })
            //         .catch((error) => {
            //             reject(error);
            //         });
            // });

            let waitForSave = new Promise((resolve) => {
                let timesRan = 0;
                const waitForSaveFn = () => {
                    // give it 10 seconds timeout to save, 200 * 50 = 10000
                    if (this.isSaving && timesRan < 50) {
                        timesRan++;
                        setTimeout(function () {
                            waitForSaveFn();
                        }, 200);
                    } else {
                        resolve();
                    }
                };
                waitForSaveFn();
            });

            return new Promise((resolve, reject) => {
                Promise.all([waitForSave]).then(() => {
                    Promise.all(promises)
                        .then((values) => {
                            values.forEach((value) => {
                                data = data.concat(value.data);
                                link = link.concat(value.originalData?.link);
                            });
                            resolve({ data: data, link: link });
                        })
                        .catch((error) => {
                            reject(error);
                        });
                });
            });
        },
        async addItem(item, save) {
            let newItem;
            if (this.collection) {
                this.data.push(item);
                // if autosave == false then we can save the new item by passing in bool 'save' as second param
                if (save) {
                    newItem = await this.save(item);
                }
            }
            return newItem;
        },
        async removeItem(item) {
            // if item removed was the only item then return
            if (!Array.isArray(this.data)) return;

            // Allows for removing and item if it doesn't have an id defined
            let itemIndex = this.data.indexOf(item);
            if (itemIndex !== -1) {
                let [itemRemoved] = this.data.splice(itemIndex, 1);
                this.$emit('itemRemoved', itemRemoved);
                return;
            }

            // Item not found see if we can look it up based on id and type
            if (typeof item.id === 'string' && typeof item.resourceType === 'string') {
                itemIndex = this.data.findIndex((elem) => elem.resourceType === item.resourceType && elem.id === item.id);

                if (itemIndex !== -1) {
                    let [itemRemoved] = this.data.splice(itemIndex, 1);
                    this.$emit('itemRemoved', itemRemoved);
                }
            }
        },
        async deleteItem(itemToDelete) {
            let data = itemToDelete || this.data;
            let config = { errorHandle: false };

            let id, resourceType, responseStatus;
            if (typeof data === 'object') {
                id = data.id;
                resourceType = data.resourceType;
            }

            if (typeof id === 'string' && typeof resourceType === 'string') {
                try {
                    let response = await $httpFhirApi.delete(resourceType + '/' + id, config);
                    responseStatus = response.status === 200 || response.status === 204;
                } catch (error) {
                    this.$emit('error', error);
                    return;
                }
            }

            if (responseStatus === undefined || responseStatus) {
                return this.removeItem(itemToDelete);
            }
        },
        async save(itemToSave, propertyConfig) {
            let data = itemToSave || this.data;
            let returnItem = undefined;
            this.isSaving = true;
            this.isSavingToServer = true;
            this.$emit('update:saving', true);
            this.$emit('saving');

            let config = { headers: { ContentType: 'application/json-patch+json' }, errorHandle: false };

            if (this.saveTimeout) {
                config.timeout = this.saveTimeout;
            }

            // if autosave is turned on.  We want to ALWAYS save even if it's invalid, so ignore validation rules here
            if (this.validator && !this.autosave) {
                let validator = this.validator;
                if (Object.prototype.hasOwnProperty.call(validator, '$invalid') && validator.$invalid) {
                    this.$emit('update:saving', false);
                    this.isSaving = false;
                    this.isSavingToServer = false;
                    return;
                }

                if (!Array.isArray(validator)) {
                    validator = [validator];
                }

                let isValid = true;
                validator.forEach((v) => {
                    if (Object.prototype.hasOwnProperty.call(v, 'filter') && v.filter(data) && v.validator.$invalid) {
                        isValid = false;
                    }
                });

                if (!isValid) {
                    this.$emit('update:saving', false);
                    this.isSaving = false;
                    this.isSavingToServer = false;
                    return;
                }
            }

            if (data.id && !data.temp.isNew) {
                /**
                 * data.temp.isNew = true will bypass this section and force a new resource to be created
                 * with the specified ID. This allows us to use data-provider to create a new record with an
                 * ID generated in the application that can be used to link to other resources.
                 */

                // The resourceJsonPatch method uses the originalObjJson property to generate
                // the proper JsonPatch so we have to make sure that represents the object that is on the server.
                let patch = data.resourceJsonPatch();
                if (!patch.length) {
                    this.$emit('update:saving', false);
                    this.$emit('saved');
                    this.isSaving = false;
                    this.isSavingToServer = false;
                    return;
                }

                if (propertyConfig?.exclude) {
                    patch = patch.filter((i) => !propertyConfig.exclude.some((j) => i.path.match(j)));
                }

                if (propertyConfig?.include) {
                    patch = patch.filter((i) => propertyConfig.include.some((j) => i.path.match(j)));
                }

                try {
                    await $httpFhirApi.patch(data.resourceType + '/' + data.id + '?_method=json-patch', patch, config);
                    data.setObjectSaved(patch);
                    returnItem = data;

                    if (itemToSave?.id) {
                        let index = this.data.findIndex((e) => e.id === itemToSave.id);
                        this.data[index] = itemToSave;
                    }
                    if (this.collection) {
                        let index = this.data.findIndex((obj) => isEqual(obj, data));
                        this.saveError[index] = null;
                    } else {
                        this.saveError = null;
                    }
                } catch (error) {
                    if (this.collection) {
                        let index = this.data.findIndex((obj) => isEqual(obj, data));
                        this.saveError[index] = error;
                    } else {
                        this.saveError = error;
                    }
                    this.$emit('saveError', error);
                    if (!isRetryableError(error)) {
                        this.$emit('update:saving', false);
                        this.isSaving = false;
                        this.isSavingToServer = false;
                        return;
                    }

                    // store.dispatch('addSaveToRetry', {
                    //     name: module.toString() + '-' + itemName.toString(),
                    //     save: async () => {
                    //         await store.dispatch(module + '/patch', { id: formItem.id, name: itemName, data: patch, config: config });
                    //     },
                    // });
                }
            } else {
                try {
                    let savedItem = await $httpFhirApi.post(data.resourceType, data.toJSON(), config);
                    let newItem = createFhirResource(savedItem.data.resourceType, savedItem.data.toJSON());
                    returnItem = newItem;

                    if (this.collection) {
                        let index = this.data.findIndex((obj) => isEqual(obj, data));
                        if (index !== -1) {
                            this.data.splice(index, 1, newItem);
                            this.saveError[index] = null;
                        } else {
                            this.data.push(newItem);
                        }
                    } else {
                        this.data = newItem;
                        this.saveError = null;
                    }
                    this.$emit('update:modelValue', this.data);
                } catch (error) {
                    if (this.collection) {
                        let index = this.data.findIndex((obj) => isEqual(obj, data));
                        this.saveError[index] = error;
                    } else {
                        this.saveError = error;
                    }
                    this.$emit('saveError', error);
                    if (!isRetryableError(error)) {
                        this.$emit('update:saving', false);
                        this.isSaving = false;
                        this.isSavingToServer = false;
                        return;
                    }

                    // store.dispatch('addSaveToRetry', {
                    //     name: module.toString() + '-' + itemName.toString(),
                    //     save: async () => {
                    //         await store.dispatch(module + '/patch', { id: formItem.id, name: itemName, data: patch, config: config });
                    //     },
                    // });
                }
            }

            this.$emit('update:saving', false);
            this.$emit('saved');
            this.isSaving = false;
            this.isSavingToServer = false;
            return returnItem;
        },
    },
    beforeDestroy() {
        this.autosaveFunc.forEach((func) => func.flush());
    },
    render() {
        // if data is provided to it directly. we don't need these slots here
        const slots = useSlots();
        if (!this.resource) {
            if (this.isLoading && slots.loading) {
                return slots.loading({
                    isLoading: this.isLoading,
                });
            }

            if (this.loadError && slots.error) {
                return slots.error({
                    refresh: this.refresh,
                    error: this.loadError,
                    loadError: this.loadError,
                });
            }
        }

        if (!this.isLoading && this.collection && this.data && this.data.length === 0 && slots.noData) {
            return slots.noData({
                refresh: this.refresh,
                fetchItems: this.fetchItems,
                addItem: this.addItem,
            });
        }

        if (!this.isLoading && slots.default) {
            return slots.default({
                data: this.data,
                link: this.link,
                isSaving: this.isSaving,
                saveError: this.saveError,

                refresh: this.refresh,
                fetchItems: this.fetchItems,
                save: this.save,
                addItem: this.addItem,
                removeItem: this.removeItem,
                deleteItem: this.deleteItem,
                getByType: this.getByType,
                getBy: this.getBy,
                getOneBy: this.getOneBy,
                getOneById: this.getOneById,
                waitForSave: this.waitForSave,
                waitForLoad: this.waitForLoad,
            });
        }
    },
};
