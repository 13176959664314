<script>
import { useAuthStore } from '@/stores/auth';

export default {
    name: 'DevOnlyMixin',
    methods: {
        devOnly(...additionalDatabases) {
            const allowedProductionDatabases = ['freshqa', ...additionalDatabases];
            const authStore = useAuthStore();
            return import.meta.env.DEV || allowedProductionDatabases?.some((e) => authStore.account.fhirApiUri.includes(e));
        },
        isBnSupport() {
            const authStore = useAuthStore();
            return authStore.isBnSupport;
        },
        isBnSupportUser() {
            const authStore = useAuthStore();
            return authStore.isBnSupportUser;
        },
    },
};
</script>
