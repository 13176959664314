import merge from 'lodash/merge';
import { BackboneElement } from './index';

export default class PractitionerRoleAccess extends BackboneElement {
    static __className = 'PractitionerRoleAccess';

    __objectStructure = {
        healthcareService: String,
        provider: Boolean,
        active: Boolean,
        past: Boolean,
        inquiry: Boolean,
        restricted: Boolean,
    };

    constructor(constructJson, className = 'PractitionerRoleAccess') {
        super(constructJson, className);

        this.createAndPopulateStructure(this.__objectStructure, constructJson);

        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
