import BundleEntry from './BundleEntry';
import BundleLink from './BundleLink';
import { Resource } from './index';
import Identifier from './types/Identifier';
import Signature from './Signature';
import merge from 'lodash/merge';

export default class Bundle extends Resource {
    static __className = 'Bundle';

    __objectStructure = {
        identifier: [Identifier], // Persistant identifier for the bundle
        type: String, // *required document|message|transaction|transaction-response|batch|batch-response|history|searchset|collection
        timestamp: String, // When the bundle was assembled
        total: Number, // If searched, total number of matches
        link: [BundleLink], // Links related to this bundle
        entry: [BundleEntry], // Entry in the bundle - will have a resource or information
        signature: Signature,
    };

    constructor(constructJson, className = 'Bundle') {
        super(constructJson, className);
        this.createAndPopulateStructure(this.__objectStructure, constructJson, this.__objectDefaults);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
