<script>
import { compile } from 'vue';

export default {
    name: 'CompileVue',
    props: {
        template: String,
    },
    computed: {
        output() {
            let template = this.template;

            // determine if template has an element, if not wrap it in a span for it to compile
            let regex = new RegExp('^<[a-zA-Z]*>');
            if (!regex.test(template)) {
                template = '<span>' + template + '</span>';
            }

            return compile(template);
        },
    },
};
</script>

<template>
    <component :is="output"></component>
</template>
