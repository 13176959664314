import Focus from './focus';
import scrollspy from './scroll-spy';
import decimal from './decimal';

export default {
    install(Vue) {
        Vue.use(Focus);
        Vue.use(scrollspy);
        Vue.use(decimal);
    },
};

export { Focus };
