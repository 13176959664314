import { DomainResource } from './index';
import merge from 'lodash/merge';
import Reference from './types/Reference';

export default class BN_File extends DomainResource {
    static _className = 'BN_File';

    _objectStructure = {
        name: String,
        url: String,
        subject: Reference, // Patient, Person, Practitioner, Organization
        accessGroup: [Reference], // BN_AccessGroup
        tag: [String],
        uploadedBy: Reference, // Practitioner
    };

    _objectDefaults = {
        experimental: false,
    };

    constructor(constructJson, className = 'BN_File') {
        super(constructJson, className);
        this.createAndPopulateStructure(this._objectStructure, constructJson, this._objectDefaults);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this._objectStructure));
    }
}
