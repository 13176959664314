import { BackboneElement } from './index';
import Period from './types/Period';
import CodeableConcept from './types/CodeableConcept';
import Reference from './types/Reference';

import merge from 'lodash/merge';

export default class EncounterLocation extends BackboneElement {
    static __className = 'EncounterLocation';

    __objectStructure = {
        location: Reference,
        status: String, // planned | active | reserved | completed
        physicalType: CodeableConcept,
        period: Period,
    };

    constructor(constructJson, className = 'EncounterLocation') {
        super(constructJson, className);
        this.createAndPopulateStructure(this.__objectStructure, constructJson);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
