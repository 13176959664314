import { BackboneElement } from './index';
import merge from 'lodash/merge';
import Reference from '@/fhirworks/types/Reference';

export default class ConsentProvision extends BackboneElement {
    static __className = 'ConsentProvision';

    __objectStructure = {
        who: Reference,
        when: String, // Actions controlled by this consent
        reason: String, // Context of activities covered by this consent
    };

    constructor(constructJson, className = 'ConsentProvision') {
        super(constructJson, className);

        this.createAndPopulateStructure(this.__objectStructure, constructJson);

        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
