import { DomainResource } from './index';
import GoalTarget from './GoalTarget';
import GoalAchievementStatusHistory from './GoalAchievementStatusHistory';
import CodeableConcept from './types/CodeableConcept';
import Identifier from './types/Identifier';
import Reference from './types/Reference';
import merge from 'lodash/merge';
import { contactPointTraits } from './trait/contactPointTraits';
import StartX from './types/StartX';
import get from 'lodash/get';

export default class Goal extends DomainResource {
    static __className = 'Goal';

    __objectStructure = {
        identifier: [Identifier], // External Ids for this item
        lifecycleStatus: String, // proposed | active | suspended | inactive | entered-in-error
        achievementStatus: CodeableConcept, // Type of team

        category: [CodeableConcept], // Name of the team, such as crisis assessment team
        continuous: Boolean, // Who care team is for
        description: CodeableConcept, // 	Encounter created as part of
        subject: Reference, // Time period team covers
        start: StartX, // Members of the team
        target: [GoalTarget],
        statusDate: String, // Why the care team exists
        statusReason: String, // Organization responsible for the care team
        source: Reference, // A contact detail for the care team (that applies to all members)
        addresses: [Reference], // Comments made about the CareTeam

        // first-class extensions
        achievementStatusHistory: [GoalAchievementStatusHistory],
        order: Number,
        clientStated: Boolean,
        libraryId: String,
        resolvedDate: String,
    };

    __objectDefaults = {
        experimental: false,
    };

    constructor(constructJson, className = 'Goal') {
        super(constructJson, className);

        contactPointTraits.call(this);

        this.createAndPopulateStructure(this.__objectStructure, constructJson, this.__objectDefaults);

        let startXStartDate = get(constructJson, 'startDate', undefined);
        if (startXStartDate) {
            this.startDate = startXStartDate;
        }

        this.originalObjJson = this.toJSON();
    }

    get startDate() {
        if (!this.start) {
            return undefined;
        }
        return this.start.startDate;
    }
    set startDate(val) {
        if (!this.start) {
            return new StartX();
        }
        this.start.startDate = val;
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
