import merge from 'lodash/merge';
import { BackboneElement } from './index';

export default class PractitionerRoleAvailableTime extends BackboneElement {
    static __className = 'PractitionerRoleAvailableTime';

    __objectStructure = {
        daysOfWeek: [String], // mon | tue | wed | thu | fri | sat | sun
        allDay: Boolean, // Always available? e.g. 24 hour service
        availableStartTime: String, // Opening time of day (ignored if allDay = true)
        availableEndTime: String, // Opening time of day (ignored if allDay = true)
    };

    constructor(constructJson, className = 'PractitionerRoleAvailableTime') {
        super(constructJson, className);

        this.createAndPopulateStructure(this.__objectStructure, constructJson);

        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
