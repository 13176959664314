import { BackboneElement } from './index';
import merge from 'lodash/merge';
import Coding from '@/fhirworks/types/Coding';

export default class ValueSetComposeIncludeConceptDesignation extends BackboneElement {
    static __className = 'ValueSetComposeIncludeConceptDesignation';

    __objectStructure = {
        language: String, // Human language of the designation
        use: Coding, // Types of uses of designations
        value: String, // !R The text value for this designation
    };

    constructor(constructJson, className = 'ValueSetComposeIncludeConceptDesignation') {
        super(constructJson, className);
        this.createAndPopulateStructure(this.__objectStructure, constructJson);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
