import { Element } from './index';
import CodeableConcept from './types/CodeableConcept';
import Period from './types/Period';
import Reference from './types/Reference';

import merge from 'lodash/merge';

/**
 * @property {CodeableConcept[]} role // Type of involvement
 * @property {Reference} member // Who is involved
 * @property {Reference} onBehalfOf // Organization of the practitioner
 * @property {Period} period // Time period of participant
 */
export default class CareTeamParticipant extends Element {
    static __className = 'CareTeamParticipant';

    __objectStructure = {
        role: [CodeableConcept], // Type of involvement
        member: Reference, // Who is involved
        onBehalfOf: Reference, // Organization of the practitioner
        period: Period, // Time period of participant
    };

    constructor(constructJson, className = 'CareTeamParticipant') {
        super(constructJson, className);

        this.createAndPopulateStructure(this.__objectStructure, constructJson, this.__objectDefaults);

        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
