import { BackboneElement } from './index';
import Reference from './types/Reference';
import merge from 'lodash/merge';

export default class PersonLink extends BackboneElement {
    static __className = 'PersonLink';

    __objectStructure = {
        target: Reference,
        assurance: String,
    };

    constructor(constructJson, className = 'PersonLink') {
        super(constructJson, className);

        this.createAndPopulateStructure(this.__objectStructure, constructJson);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
