import { DomainResource } from './index';
import Annotation from './types/Annotation';
import ContactPoint from './ContactPoint';
import CareTeamParticipant from './CareTeamParticipant';
import CodeableConcept from './types/CodeableConcept';
import Identifier from './types/Identifier';
import Period from './types/Period';
import Reference from './types/Reference';
import merge from 'lodash/merge';
import { contactPointTraits } from './trait/contactPointTraits';

export default class CareTeam extends DomainResource {
    static __className = 'CareTeam';

    __objectStructure = {
        identifier: [Identifier], // External Ids for this item
        status: String, // proposed | active | suspended | inactive | entered-in-error
        category: [CodeableConcept], // Type of team
        name: String, // Name of the team, such as crisis assessment team
        subject: Reference, // Who care team is for
        encounter: Reference, // 	Encounter created as part of
        period: Period, // Time period team covers
        participant: [CareTeamParticipant], // Members of the team
        reasonCode: [CodeableConcept], // Why the care team exists
        reasonReference: [Reference], // Why the care team exists
        managingOrganization: [Reference], // Organization responsible for the care team
        telecom: [ContactPoint], // A contact detail for the care team (that applies to all members)
        note: [Annotation], // Comments made about the CareTeam
    };

    __objectDefaults = {
        experimental: false,
    };

    constructor(constructJson, className = 'CareTeam') {
        super(constructJson, className);

        contactPointTraits.call(this);

        this.createAndPopulateStructure(this.__objectStructure, constructJson, this.__objectDefaults);

        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
