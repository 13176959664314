import { DomainResource } from './index';
import ContactPoint from './ContactPoint';
import Coding from './types/Coding';
import Identifier from './types/Identifier';
import Reference from './types/Reference';
import Period from './types/Period';
import CodeableConcept from './types/CodeableConcept';

import get from 'lodash/get';
import merge from 'lodash/merge';

export default class Endpoint extends DomainResource {
    static __className = 'Endpoint';

    constructor(endpointJson, className = 'Endpoint') {
        super(endpointJson, className);

        this.__identifier = [];
        let identifierList = get(endpointJson, 'identifier', []);
        identifierList.forEach((identifierItem) => {
            this.__identifier.push(new Identifier(identifierItem));
        });

        this.__status = get(endpointJson, 'status', undefined);

        this.__connectionType = new Coding(get(endpointJson, 'connectionType', undefined));
        this.__name = get(endpointJson, 'name', undefined);
        this.__managingOrganization = new Reference(get(endpointJson, 'managingOrganization', undefined));

        this.__contact = [];
        let contactInfo = get(endpointJson, 'contact', []);
        contactInfo.forEach((contactItem) => {
            this.__contact.push(new ContactPoint(contactItem));
        });

        this.__period = new Period(get(endpointJson, 'period', undefined));

        this.__payloadType = [];
        let payloadTypeInfo = get(endpointJson, 'payloadType', []);
        payloadTypeInfo.forEach((payloadTypeItem) => {
            this.__payloadType.push(new CodeableConcept(payloadTypeItem));
        });

        this.__payloadMimeType = get(endpointJson, 'payloadMimeType', []);
        this.__address = get(endpointJson, 'address', undefined);
        this.__header = get(endpointJson, 'header', []);

        this.originalObjJson = this.toJSON();
    }

    // Properties

    get identifier() {
        return this.__identifier;
    }

    set identifier(value) {
        if (Array.isArray(value)) {
            this.__identifier = value;
            return;
        }

        if (value instanceof Identifier) {
            this.__identifier.push(value);
            return;
        }

        this.__identifier.push(new Identifier(value));
    }

    get status() {
        return this.__status;
    }

    set status(value) {
        this.__status = value;
    }

    get connectionType() {
        return this.__connectionType;
    }

    set connectionType(value) {
        if (value instanceof Coding) {
            this.__connectionType = value;
            return;
        }

        this.__connectionType = new Coding(value);
    }

    get name() {
        return this.__name;
    }

    set name(value) {
        this.__name = value;
    }

    get managingOrganization() {
        return this.__managingOrganization;
    }

    set managingOrganization(value) {
        if (value instanceof Reference) {
            this.__managingOrganization = value;
            return;
        }

        this.__managingOrganization = new Reference(value);
    }

    get contact() {
        return this.__contact;
    }

    set contact(value) {
        if (Array.isArray(value)) {
            this.__contact = value;
            return;
        }

        if (value instanceof ContactPoint) {
            this.__contact.push(value);
            return;
        }

        this.__contact.push(new ContactPoint(value));
    }

    get period() {
        return this.__period;
    }

    set period(value) {
        if (value instanceof Period) {
            this.__period = value;
            return;
        }

        this.__period = new Period(value);
    }

    get payloadType() {
        return this.__payloadType;
    }

    set payloadType(value) {
        if (Array.isArray(value)) {
            this.__payloadType = value;
            return;
        }

        if (value instanceof CodeableConcept) {
            this.__payloadType.push(value);
            return;
        }

        this.__payloadType.push(new CodeableConcept(value));
    }

    get payloadMimeType() {
        return this.__payloadMimeType;
    }

    set payloadMimeType(value) {
        if (Array.isArray(value)) {
            this.__payloadMimeType = value;
            return;
        }

        this.__payloadMimeType.push(value);
    }

    get address() {
        return this.__address;
    }

    set address(value) {
        this.__address = value;
    }

    get header() {
        return this.__header;
    }

    set header(value) {
        if (Array.isArray(value)) {
            this.__header = value;
            return;
        }

        this.__header.push(value);
    }

    // Methods

    toJSON() {
        return merge(
            super.toJSON(this),
            this.removeUndefinedObjProps({
                identifier: this.arrayPropertyToJson(this.identifier),
                status: this.status,
                connectionType: this.propertyToJson(this.connectionType),
                name: this.name,
                managingOrganization: this.propertyToJson(this.managingOrganization),
                contact: this.arrayPropertyToJson(this.contact),
                period: this.propertyToJson(this.period),
                payloadType: this.arrayPropertyToJson(this.payloadType),
                payloadMimeType: this.payloadMimeType,
                address: this.address,
                header: this.header,
            }),
        );
    }
}
