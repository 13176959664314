import { defineStore } from 'pinia';
import { $api } from '@/common/api';
import { Practitioner, User, Organization, PractitionerRole, Patient } from '@/fhirworks';
import { isEmpty, upperFirst } from 'lodash';
import { parseISO, isValid } from 'date-fns';
import { $httpFhirApi } from '@/common/api/httpFhir.service';
import VueCookies from 'vue-cookies';

export const useAuthStore = defineStore('auth', {
    state() {
        return {
            token: '',
            user: {},
            practitioner: {},
            practitionerRoles: [],
            patient: {},
            permissions: [],
            organizationAccount: {},
            account: {},
            brand: {},
            userAgencies: [],
            accountsCount: null,
            lockoutLastActivityTime: new Date(),
            lockoutPage: null,
            tokenRefreshTimeMins: 12,
            lastTokenRefreshTime: null,
            loginStatus: {
                loading: false,
                success: false,
                errors: [],
            },
        };
    },
    getters: {
        isAuthenticated: (state) => !!state.token,
        isLockedOut: (state) => !state.token && !isEmpty(state.user),
        hasAccessGroup: (state) => (groups) => {
            let assignedAccessGroupIds = state.user.user.accessGroups?.map((group) => group.id) || [];
            if (!Array.isArray(groups)) {
                groups = [groups];
            }
            const found = groups.some((searchGroup) => assignedAccessGroupIds.includes(searchGroup.id));
            return found;
        },
        hasPermission: (state) => (permissions) => {
            // we want to accomplish arrays and a single string.  If none is input.  filter out those below
            if (!Array.isArray(permissions)) {
                permissions = [permissions].filter((perm) => perm);
            }
            return permissions.some((permission) => state.permissions.includes(permission));
        },
        hasPermissions: (state) => (permissions) => {
            // we want to accomplish arrays and a single string.  If none is input.  filter out those below
            if (!Array.isArray(permissions)) {
                permissions = [permissions].filter((perm) => perm);
            }
            return permissions.every((permission) => state.permissions.includes(permission));
        },
        aidboxPersonResource: (state) => {
            return state.practitioner?.id ? state.practitioner : state.patient;
        },
        aidboxPersonReference() {
            return { id: this.aidboxPersonResource?.id, resourceType: this.aidboxPersonResource?.resourceType, display: this.aidboxPersonResource?.fullName };
        },
        fullName() {
            return (this.aidboxPersonResource?.fullName || '').trim();
        },
        firstName() {
            return (this.aidboxPersonResource?.firstName || '').trim();
        },
        lockoutTime: (state) => (state.organizationAccount?.sessionTimeOut ? state.organizationAccount?.sessionTimeOut : 5) * 60000,
        timeSlot: (state) => (state.organizationAccount?.calendarTimeSlot ? state.organizationAccount?.calendarTimeSlot : 30),
        tokenRefreshTime: (state) => state.tokenRefreshTimeMins * 60000,
        clientVocab: (state) => (state.organizationAccount?.clientVocab ? upperFirst(state.organizationAccount?.clientVocab) : 'Patient'),
        inputDateFormat: (state) => (state.organizationAccount?.inputDateFormat ? state.organizationAccount?.inputDateFormat : 'MM/dd/yyyy'),
        outputDateFormat: (state) => (state.organizationAccount?.outputDateFormat ? state.organizationAccount?.outputDateFormat : 'MMM do yyyy'),
        outputTimeFormat: (state) => (state.militaryTime ? ' kk:mm' : ' h:mm a'),
        militaryTime: (state) => (state.organizationAccount?.militaryTime ? state.organizationAccount?.militaryTime : false),
        showSwitchAccount: (state) => state.accountsCount > 1,
        fhirApiUri: (state) => state.account.fhirApiUri,
        isBnSupport: (state) => state.user.apiUser?.isBnSupport,
        isBnSupportUser: (state) => state.user.user?.id === 'bnsupport-user',
    },
    actions: {
        setToken(token) {
            VueCookies.set('token', token, '20m');
            this.token = token;
        },
        removeToken() {
            VueCookies.remove('token');
            this.token = '';
        },
        setUser(user) {
            if (user.user && !(user.user instanceof User)) {
                user.user = new User(user.user);
            }

            VueCookies.set('user', JSON.stringify(user));
            this.user = user;
        },
        removeUser() {
            VueCookies.remove('user');
            this.user = {};
        },
        setPractitioner(practitioner) {
            if (practitioner && !(practitioner instanceof Practitioner)) {
                practitioner = new Practitioner(practitioner);
            }

            VueCookies.set('practitioner', JSON.stringify(practitioner));
            this.practitioner = practitioner;
        },
        removePractitioner() {
            VueCookies.remove('practitioner');
            this.practitioner = {};
        },
        // for Patient Portal
        setPatient(patient) {
            if (patient && !(patient instanceof Patient)) {
                patient = new Patient(patient);
            }

            VueCookies.set('patient', JSON.stringify(patient));
            this.patient = patient;
        },
        removePatient() {
            VueCookies.remove('patient');
            this.patient = {};
        },
        setPermissions(permissions) {
            if (typeof permissions === 'string') {
                permissions = JSON.parse(permissions);
            }

            this.permissions = Object.values(permissions);
        },
        removePermissions() {
            this.permissions = [];
        },
        setPractitionerRoles(practitionerRoles) {
            if (typeof practitionerRoles === 'string') {
                practitionerRoles = JSON.parse(practitionerRoles);
            }

            localStorage.setItem('practitionerRoles', JSON.stringify(practitionerRoles));
            this.practitionerRoles = practitionerRoles;
        },
        removePractitionerRoles() {
            localStorage.removeItem('practitionerRoles');
            this.practitionerRoles = [];
        },
        setAccountData(account) {
            VueCookies.set('account', JSON.stringify(account));
            this.account = account;
        },
        removeAccount() {
            VueCookies.remove('account');
            this.account = {};
        },
        setBrand(brand) {
            const activeBrand = {
                id: brand.id,
                name: brand.display || brand.name,
                location: brand.location,
            };
            VueCookies.set('brand', JSON.stringify(activeBrand));
            this.brand = activeBrand;
        },
        removeBrand() {
            VueCookies.remove('brand');
            this.brand = {};
        },
        setUserAgencies(userAgencies) {
            localStorage.setItem('userAgencies', JSON.stringify(userAgencies));
            this.userAgencies = userAgencies;
        },
        removeUserAgencies() {
            localStorage.removeItem('userAgencies');
            this.userAgencies = [];
        },
        setOrganizationAccount(account) {
            if (!(account instanceof Organization)) {
                account = new Organization(account);
            }
            VueCookies.set('organizationAccount', JSON.stringify(account));
            this.organizationAccount = account;
        },
        removeOrganizationAccount() {
            VueCookies.remove('organizationAccount');
            this.organizationAccount = {};
        },
        setAccountDetails(details) {
            if (!Array.isArray(details)) details = [details];
            details.forEach((item) => {
                if (item.value) {
                    if (item.key === 'name') {
                        this.account.name = item.value;
                        VueCookies.set('account', JSON.stringify(this.account));

                        this.organizationAccount.name = item.value;
                        VueCookies.set('organizationAccount', JSON.stringify(this.organizationAccount));
                        return;
                    }
                    if (item.key === 'logo') {
                        this.account.logo = item.value;
                        VueCookies.set('account', JSON.stringify(this.account));
                    }
                    this.organizationAccount[item.key] = item.value;
                    VueCookies.set('organizationAccount', JSON.stringify(this.organizationAccount));
                }
            });
        },
        setAccountsCount(count) {
            localStorage.setItem('accounts-count', count);
            this.accountsCount = count;
        },
        removeAccountsCount() {
            localStorage.removeItem('accounts-count');
            this.accountsCount = null;
        },
        setLastTokenRefreshTime(time) {
            if (isValid(time)) {
                localStorage.setItem('lastTokenRefreshTime', time.toISOString());
            }
            this.lastTokenRefreshTime = time;
        },
        removeLastTokenRefreshTime() {
            localStorage.removeItem('lastTokenRefreshTime');
            this.lastTokenRefreshTime = null;
        },
        setLockoutPage(page) {
            localStorage.setItem('lockoutPage', page?.fullPath);
            this.lockoutPage = page?.fullPath;
        },
        removeLockoutPage() {
            localStorage.removeItem('lockoutPage');
            this.lockoutPage = null;
        },
        /**
         * If credentials are provided then login, else...
         * If the user is already authenticated then set the data in the store
         *
         * @param commit
         * @param credentials
         * @returns {Promise<void>}
         */
        async login(credentials) {
            try {
                this.loginStatus.loading = true;
                this.loginStatus.success = false;
                this.loginStatus.errors = [];

                await this.clearAuth(); // Attempt to logout the current user before logging in
                await this.getAuthToken(credentials);
                // await this.logNotice({ summary: 'Login Successful' });

                this.loginStatus.loading = false;
                this.loginStatus.success = true;
            } catch (errs) {
                await this.clearAuth();
                return Promise.reject(errs);
            }
        },

        async refreshCredentials() {
            // Grab user and token in cases of page refresh
            let token = VueCookies.get('token');
            let user = VueCookies.get('user');
            let practitioner = VueCookies.get('practitioner');
            let patient = VueCookies.get('patient');
            let account = VueCookies.get('account');
            let organizationAccount = VueCookies.get('organizationAccount');
            let brand = VueCookies.get('brand');
            let practitionerRoles = localStorage.getItem('practitionerRoles');
            let lastTokenRefreshTime = localStorage.getItem('lastTokenRefreshTime');
            let accountsCount = localStorage.getItem('accounts-count');
            let userAgencies;
            if (localStorage.getItem('userAgencies')) {
                userAgencies = JSON.parse(localStorage.getItem('userAgencies'));
            }
            let lockoutPage;
            if (localStorage.getItem('lockoutPage')) {
                lockoutPage = localStorage.getItem('lockoutPage');
            }

            if (token) {
                this.setToken(token);

                let tokenData = JSON.parse(atob(token.split('.')[1]));
                if (tokenData?.permissions) {
                    this.setPermissions(tokenData.permissions);
                }
            }
            if (user) {
                this.setUser(user);
            }
            if (lastTokenRefreshTime) {
                this.setLastTokenRefreshTime(parseISO(lastTokenRefreshTime));
            }
            if (practitioner) {
                this.setPractitioner(practitioner);
            }
            if (patient) {
                this.setPatient(patient);
            }
            if (practitionerRoles) {
                this.setPractitionerRoles(practitionerRoles);
            }
            if (account) {
                this.setAccountData(account);
            }
            if (organizationAccount) {
                this.setOrganizationAccount(organizationAccount);
            }
            if (brand && account) {
                this.setBrand(brand);
            }
            if (userAgencies && account) {
                this.setUserAgencies(userAgencies);
            }
            if (accountsCount) {
                this.setAccountsCount(accountsCount);
            }
            if (lockoutPage) {
                this.setLockoutPage(lockoutPage);
            }
        },

        async getAuthToken(credentials) {
            // If account exists send it, so we can get a token tied to the account
            let config = this.account.id ? { params: { jwtTokenAccount: this.account.id }, errorHandle: false } : { errorHandle: false };
            let res;

            if (window.page === 'ehr') {
                res = await $api.auth.login(credentials, config);
            } else {
                res = await $api.auth.portalLogin(credentials, config);
            }
            let token = res?.data?.token;

            let tokenData = JSON.parse(atob(token.split('.')[1]));

            this.setToken(token); // Set the token before calling the getMe api endpoint

            if (tokenData?.permissions) {
                this.setPermissions(tokenData.permissions);
            }

            let now = new Date();
            this.setLastTokenRefreshTime(now);
        },

        async refreshAuthToken() {
            // If user is not authenticated, do nothing
            if (!this.isAuthenticated) {
                return;
            }

            let config = this.account.id ? { params: { jwtTokenAccount: this.account.id } } : null;

            let res;
            if (window.page === 'ehr') {
                res = await $api.auth.refreshToken(config);
            } else {
                res = await $api.auth.refreshTokenPortal(config);
            }
            let token = res.data.token;

            if (token) {
                let now = new Date();

                let tokenData = JSON.parse(atob(token.split('.')[1]));

                this.setToken(token);
                if (tokenData?.permissions) {
                    this.setPermissions(tokenData.permissions);
                }
                this.setLastTokenRefreshTime(now);
            }
        },

        async getMeUser() {
            let aidboxUserResources;

            let tokenData = JSON.parse(atob(this.token.split('.')[1]));

            let apiUser = {
                email: tokenData.username,
                id: tokenData.sub,
                isBnSupport: tokenData?.isSupport,
            };

            if (window.page === 'ehr') {
                aidboxUserResources = await $api.users.getMe(apiUser.id);
            } else {
                aidboxUserResources = await $api.users.getMePortal(apiUser.id);
            }

            let storeUser = { apiUser: apiUser };

            if (window.page === 'ehr') {
                let practitioner, practitionerRoles;

                if (aidboxUserResources?.length) {
                    let userResource = aidboxUserResources.find((entry) => entry.resourceType === 'User');

                    let practitionerResource = aidboxUserResources.find((entry) => entry.resourceType === 'Practitioner');
                    let practitionerRoleResources = aidboxUserResources.filter((entry) => entry.resourceType === 'PractitionerRole');

                    if (userResource) {
                        storeUser.user = userResource;
                    }
                    if (practitionerResource) {
                        practitioner = practitionerResource;
                    }
                    if (practitionerRoleResources) {
                        if (!(practitionerRoleResources[0] instanceof PractitionerRole)) {
                            practitionerRoleResources = practitionerRoleResources.map((role) => new PractitionerRole(role));
                        }

                        practitionerRoles = practitionerRoleResources.map((role) => ({
                            programId: role.healthcareService[0]?.id,
                            locationId: role.location[0]?.id,
                            provider: role?.healthcareServiceAccess[0]?.provider,
                            inquiry: role?.healthcareServiceAccess[0]?.inquiry,
                            active: role?.healthcareServiceAccess[0]?.active,
                            past: role?.healthcareServiceAccess[0]?.past,
                            restricted: role?.healthcareServiceAccess[0]?.restricted,
                        }));

                        practitionerRoleResources.forEach((role) => {
                            role.healthcareService.forEach((hcs) => {
                                practitionerRoles.push({
                                    programId: hcs.id,
                                    locationId: role.location[0].id,
                                    provider: role?.healthcareServiceAccess.find((hsa) => hsa.healthcareService === hcs.id)?.provider,
                                    inquiry: role?.healthcareServiceAccess.find((hsa) => hsa.healthcareService === hcs.id)?.inquiry,
                                    active: role?.healthcareServiceAccess.find((hsa) => hsa.healthcareService === hcs.id)?.active,
                                    past: role?.healthcareServiceAccess.find((hsa) => hsa.healthcareService === hcs.id)?.past,
                                    restricted: role?.healthcareServiceAccess.find((hsa) => hsa.healthcareService === hcs.id)?.restricted,
                                });
                            });
                        });

                        const primaryLocation = practitionerResource?.primaryLocation;
                        const primaryLocationBrand = practitionerResource?.primaryLocationBrand;
                        const location = aidboxUserResources.find((item) => item.id === primaryLocation?.id) || aidboxUserResources.find((item) => item.resourceType === 'Location');
                        const brand = aidboxUserResources.find((item) => item.id === primaryLocationBrand?.id) || aidboxUserResources.find((item) => item.id === location?.managingOrganization?.id);

                        let brandLocation;
                        let brands = [];

                        let userBrands = aidboxUserResources.filter((item) => item.resourceType === 'Organization').sort((a, b) => (a.name > b.name ? 1 : -1));

                        userBrands?.forEach((brand) => {
                            let locations = [];
                            aidboxUserResources
                                .filter((item) => item.organization?.id === brand.id)
                                .sort((a, b) => (a.name > b.name ? 1 : -1))
                                ?.forEach((item) => {
                                    const location = item.location[0];
                                    locations.push({ id: location.id, display: location.display, resourceType: 'Location' });
                                });
                            brands.push({ id: brand.id, name: brand.name, resourceType: 'Organization', locations: locations.sort((a, b) => (a.display > b.dispaly ? 1 : -1)) });
                        });

                        if (primaryLocationBrand?.id && brand?.id && location?.id) {
                            brandLocation = {
                                id: brand.id,
                                name: brand.name,
                                resourceType: brand.resourceType,
                                location: {
                                    id: location.id,
                                    display: location.name,
                                    resourceType: location.resourceType,
                                },
                            };
                        } else if (brands.length) {
                            const defaultBrand = brands[0];
                            const defaultLocation = brands[0].locations[0];
                            brandLocation = {
                                id: defaultBrand.id,
                                name: defaultBrand.name,
                                resourceType: defaultBrand.resourceType,
                                location: {
                                    id: defaultLocation.id,
                                    display: defaultLocation.display,
                                    resourceType: defaultLocation.resourceType,
                                },
                            };
                        }

                        if (brandLocation) {
                            this.setBrand(brandLocation);
                            this.setUserAgencies(brands);
                        }
                    }
                } else if (storeUser.apiUser.isBnSupport) {
                    storeUser.user = { id: 'bnsupport-user' };

                    practitioner = new Practitioner({
                        id: 'bnsupport-practitioner',
                        name: [
                            {
                                use: 'official',
                                given: ['BN Support'],
                            },
                        ],
                    });
                    practitionerRoles = [];
                }

                this.setUser(storeUser); // Set the token before calling the getMe api endpoint
                this.setPractitioner(practitioner);
                this.setPractitionerRoles(practitionerRoles);
            } else {
                // Is portal user
                let patient;

                if (aidboxUserResources?.length) {
                    let userResource = aidboxUserResources.find((entry) => entry.resourceType === 'User');
                    let patientResource = aidboxUserResources.find((entry) => entry.resourceType === 'Patient');

                    if (userResource) {
                        storeUser.user = userResource;
                    }
                    if (patientResource) {
                        patient = patientResource;
                    }

                    this.setUser(storeUser); // Set the token before calling the getMe api endpoint
                    this.setPatient(patient);
                }
            }
        },

        async clearAuth(switchingAccount = false) {
            if (!switchingAccount) {
                this.removeToken();
                this.removeUser();
                // account needs to be here so we don't get logged out to login page when switching accounts
                this.removeAccount();
                this.removeOrganizationAccount();
                this.removeAccountsCount();
                this.removePractitioner();
                this.removePractitionerRoles();
                this.removePatient();
            }
            this.removeBrand();
            this.removeUserAgencies();
            this.removePermissions();
            this.removeLockoutPage();
            this.removeLastTokenRefreshTime();
        },

        resetLockoutActivityTime() {
            let time = new Date();
            localStorage.setItem('lockoutLastActivityTime', time.toISOString());
            this.lockoutLastActivityTime = time;
        },

        setLockoutLastActivityTime(time) {
            this.lockoutLastActivityTime = time;
        },

        async lockScreen(lockoutPage) {
            this.setLockoutLastActivityTime(null);
            lockoutPage = Object.assign({}, this.lockoutPage || lockoutPage);

            if (isEmpty(lockoutPage) || lockoutPage.name === 'lockout') {
                lockoutPage = { name: 'home' };
            }

            delete lockoutPage.matched;

            this.setLockoutPage(lockoutPage);
            // allow time for background requests to complete (save state) before removing token
            // there may be a better way to do this... Justin?
            setTimeout(() => {
                this.removeToken();
            }, 3000);
        },

        async unlockScreen(password) {
            this.resetLockoutActivityTime();
            await this.getAuthToken({
                email: this.user.apiUser.email,
                password: password,
            });
            if (!this.isLockedOut) {
                this.removeLockoutPage();
            }
        },

        async setAccount(account) {
            await this.setAccountData(account);
            await this.refreshAuthToken();
            let organizationAccount = await $httpFhirApi.get('Organization/' + account.id);
            if (organizationAccount?.data) {
                await this.setOrganizationAccount(organizationAccount.data);
            }
        },
        async requestPasswordResetAuth(data) {
            return await $api.auth.requestResetPassword(data);
        },
        async requestPasswordResetPortalAuth(data) {
            return await $api.auth.requestResetPasswordPortal(data);
        },
        async passwordResetPortalAuth(data) {
            return await $api.auth.resetPasswordPortal(data);
        },
        async passwordResetAuth(data) {
            return await $api.auth.resetPassword(data);
        },
    },
});
