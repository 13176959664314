import { BackboneElement } from './index';
import CodeableConcept from './types/CodeableConcept';
import ServicedX from './types/ServicedX';
import LocationX from './types/LocationX';
import Quantity from './types/Quantity';
import Money from './types/Money';
import Reference from './types/Reference';
import DiagnosisItem from './DiagnosisItem';
import merge from 'lodash/merge';
import get from 'lodash/get';

export default class ClaimItem extends BackboneElement {
    static __className = 'ClaimItem';

    __objectStructure = {
        sequence: Number,
        careTeamSequence: [Number],
        diagnosisSequence: [Number],
        procedureSequence: [Number],
        informationSequence: [Number],
        revenue: CodeableConcept,
        category: CodeableConcept,
        productOrService: CodeableConcept,
        modifier: [CodeableConcept],
        programCode: [CodeableConcept],
        serviced: ServicedX,
        location: LocationX,
        quantity: Quantity,
        unitPrice: Money,
        // factor: Number,
        net: Money,
        // udi: Reference,
        bodySite: CodeableConcept,
        subSite: [CodeableConcept],
        encounter: [Reference],
        superbillLineItem: Reference,
        // detail: [],
        /**
         * AidBox First Class extensions
         */
        balance: Number,
        diagnosis: [DiagnosisItem],
        refLineItemControlNumber: String,
        insuranceBalance: Number,
        patientBalance: Number,
    };

    constructor(constructJson, className = 'ClaimItem') {
        super(constructJson, className);

        this.createAndPopulateStructure(this.__objectStructure, constructJson);

        let fhirServicedDate = get(constructJson, 'servicedDate', undefined);
        if (fhirServicedDate) {
            this.servicedDate = fhirServicedDate;
        }

        let fhirServicedPeriod = get(constructJson, 'servicedPeriod', undefined);
        if (fhirServicedPeriod) {
            this.servicedPeriod = fhirServicedPeriod;
        }

        let fhirLocationReference = get(constructJson, 'locationReference', undefined);
        if (fhirLocationReference) {
            this.locationReference = fhirLocationReference;
        }

        let fhirlocationCodeableConcept = get(constructJson, 'locationCodeableConcept', undefined);
        if (fhirlocationCodeableConcept) {
            this.locationCodeableConcept = fhirlocationCodeableConcept;
        }

        this.originalObjJson = this.toJSON();
    }

    get servicedDate() {
        if (!this.serviced) {
            return undefined;
        }
        return this.serviced.servicedDate;
    }

    set servicedDate(value) {
        if (!this.serviced) {
            this.serviced = new ServicedX();
        }
        this.serviced.servicedDate = value;
    }

    get servicedPeriod() {
        if (!this.serviced) {
            return undefined;
        }
        return this.serviced.servicedPeriod;
    }
    set servicedPeriod(value) {
        if (!this.serviced) {
            this.serviced = new ServicedX();
        }
        this.serviced.servicedPeriod = value;
    }

    get locationReference() {
        if (!this.location) {
            return undefined;
        }
        return this.location.locationReference;
    }
    set locationReference(value) {
        if (!this.location) {
            this.location = new LocationX();
        }
        this.location.locationReference = value;
    }

    get locationCodeableConcept() {
        if (!this.location) {
            return undefined;
        }
        return this.location.locationCodeableConcept;
    }
    set locationCodeableConcept(value) {
        if (!this.location) {
            this.location = new LocationX();
        }
        this.location.locationCodeableConcept = value;
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
