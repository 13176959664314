import { DomainResource } from './index';
import merge from 'lodash/merge';
import Reference from './types/Reference';
import CodeableConcept from './types/CodeableConcept';
import MedicationX from '@/fhirworks/types/MedicationX';
import EffectiveX from '@/fhirworks/types/EffectiveX';
import Annotation from '@/fhirworks/types/Annotation';
import Dosage from '@/fhirworks/types/Dosage';

export default class MedicationStatement extends DomainResource {
    static __className = 'MedicationStatement';

    __objectStructure = {
        status: String, // active | completed | entered-in-error | intended | stopped | on-hold | unknown | not-taken
        statusReason: [CodeableConcept],
        category: [CodeableConcept],
        medication: MedicationX,
        subject: Reference,
        effective: EffectiveX,
        dateAsserted: String,
        informationSource: Reference,
        reasonCode: [CodeableConcept],
        note: [Annotation],
        dosage: [Dosage],

        // first class extensions
        dateOfLastUse: String,
        firstUsedAge: String,
        symptoms: String,
        obtained: String,
        drugOfChoice: Boolean,
        overdoseInstance: Boolean,
        withdrawalSymptoms: Boolean,
        overdoseInstanceDetails: String,
        speakWithProvider: Boolean,
    };

    constructor(constructJson, className = 'MedicationStatement') {
        super(constructJson, className);
        this.createAndPopulateStructure(this.__objectStructure, constructJson);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
