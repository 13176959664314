import { BackboneElement } from './index';
import Coding from './types/Coding';
import merge from 'lodash/merge';

export default class BN_SuperBillOccurenceSpanCodeList extends BackboneElement {
    static __className = 'BN_SuperBillOccurenceSpanCodeList';

    __objectStructure = {
        code: Coding,
        fromDate: String,
        throughDate: String,
    };

    constructor(constructJson, className = 'BN_SuperBillOccurenceSpanCodeList') {
        super(constructJson, className);
        this.createAndPopulateStructure(this.__objectStructure, constructJson);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
