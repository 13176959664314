import { DomainResource } from './index';
import merge from 'lodash/merge';
import Reference from './types/Reference';
import UserInvite from './UserInvite';

export default class User extends DomainResource {
    static __className = 'User';

    __objectStructure = {
        systemId: String,
        active: Boolean,
        practitioner: Reference,
        portalUser: Reference,
        bnRoles: [Reference],
        accessGroups: [Reference],
        invitation: UserInvite,
        /**
         * First Class extensions
         */
        runOnce: [String],
        inviteCreated: String,
        inviteEmail: String,
        inviteId: String,
    };

    __objectDefaults = {
        experimental: false,
    };

    constructor(constructJson, className = 'User') {
        super(constructJson, className);

        this.createAndPopulateStructure(this.__objectStructure, constructJson, this.__objectDefaults);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
