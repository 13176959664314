import { BackboneElement, Reference } from './index';
import merge from 'lodash/merge';

export default class HealthcareServiceAddOn extends BackboneElement {
    static __className = 'HealthcareServiceAddOn';

    __objectStructure = {
        addOnCode: Reference,
        unit: Number,
        includeByDefault: Boolean,
    };

    constructor(constructJson, className = 'HealthcareServiceAddOn') {
        super(constructJson, className);

        this.createAndPopulateStructure(this.__objectStructure, constructJson);

        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
