import { DomainResource } from './index';
import CodeableConcept from './types/CodeableConcept';
import Identifier from './types/Identifier';
import merge from 'lodash/merge';
import ContactDetail from './ContactDetail';
import QuestionnaireItem from './QuestionnaireItem';
import Period from './types/Period';
import UsageContext from './UsageContext';
import Coding from './types/Coding';
import Reference from './types/Reference';

/**
 * @property {QuestionnaireItem} item
 */

export default class Questionnaire extends DomainResource {
    static __className = 'Questionnaire';

    __objectStructure = {
        url: String, // Canonical identifier for this questionnaire, represented as a URI (globally unique)
        identifier: [Identifier], // Additional identifier for the questionnaire
        version: String, // Business version of the questionnaire
        name: String, // C? Name for this questionnaire (computer friendly)
        title: String, // Name for this questionnaire (human friendly)
        derivedFrom: [String], // Instantiates protocol or definition
        status: String, // R!  draft | active | retired | unknown
        experimental: Boolean, // For testing purposes, not real usage
        subjectType: [String], // Resource that can be subject of QuestionnaireResponse
        date: String, // Date last changed
        publisher: String, // Name of the publisher (organization or individual)
        contact: [ContactDetail], // Contact details for the publisher
        description: String, // Natural language description of the questionnaire
        useContext: [UsageContext], // The context that the content is intended to support
        jurisdiction: [CodeableConcept], // Intended jurisdiction for questionnaire (if applicable)
        purpose: String, // Why this questionnaire is defined
        copyright: String, // Use and/or publishing restrictions
        approvalDate: String, // When the questionnaire was approved by publisher
        lastReviewDate: String, // When the questionnaire was last reviewed
        effectivePeriod: Period, // When the questionnaire is expected to be used
        code: [Coding], // Concept that represents the overall questionnaire
        item: [QuestionnaireItem], // C? Questions and sections within the Questionnaire
        /**
         * First Class extensions
         */
        formType: String,
        groupNote: Boolean,
        deprecate: Boolean,
        scorable: Boolean,
        clinicalType: CodeableConcept,
        bnOwner: Reference,
        collection: Reference,
    };

    __objectDefaults = {
        status: 'draft',
    };

    constructor(constructJson, className = 'Questionnaire') {
        super(constructJson, className);
        this.createAndPopulateStructure(this.__objectStructure, constructJson, this.__objectDefaults);

        this.originalObjJson = this.toJSON();
    }

    // *************** Getters & Setters ***************

    get bnBasedOn() {
        if (!this.url) return undefined;

        let valuesArray = this.url.split('/').filter((i) => i);
        let arrayLength = valuesArray.length;
        return new Reference({ id: valuesArray[arrayLength - 1], resourceType: valuesArray[arrayLength - 2] });
    }

    get bnSource() {
        if (!this.derivedFrom.length) return undefined;
        const [url, version] = this.derivedFrom[0].split('|');
        const [server, id] = url.split('/Questionnaire/');
        return { server: server, url: url, id: id, version: version };
    }

    getCatalogFields(code, item = this.item) {
        let catalogFields = [];
        for (let field of item) {
            if (field.code.find((e) => e.code === code)) {
                catalogFields.push(field);
            }
            if (field.item.length) {
                catalogFields.push(...this.getCatalogFields(code, field.item));
            }
        }
        return catalogFields;
    }

    getAnswerValueSetFields(valueSet, item = this.item) {
        let fields = [];
        item.forEach((field) => {
            if (field.answerValueSet === valueSet) {
                fields.push(field);
            }
            if (field.item.length) {
                fields.push(...this.getAnswerValueSetFields(valueSet, field.item));
            }
        });
        return fields;
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
