<script>
import { PractitionerQualification, types as FHIRWorksTypes } from '@/fhirworks';
import BnListBox from '@/components/BnListBox.vue';
import DataProvider from '@/components/DataProvider.js';
import BnDialog from '@/components/BnDialog.vue';
import BnListSelectBox from '@/components/BnListSelectBox.vue';
import { addRefToBackBtnListiner, removeRefToBackBtnListiner } from '@/common/nativeBackButton';

export default {
    name: 'BnListQualifications',
    components: { BnListSelectBox, BnDialog, DataProvider, BnListBox },
    props: {
        modelValue: Array,
        viewOnly: Boolean,
    },
    emits: ['update:modelValue'],
    data() {
        return {
            dpData: [],
            listBox: {
                title: 'Qualifications',
                icon: 'badge',
                addLabel: 'Add Qualification',
            },
            dialog: {
                title: 'Select Qualification',
            },
            itemsToAdd: [],
        };
    },
    computed: {
        dpDataQuery() {
            return {
                query: 'system=http://code.system/practitioner.qualification&_sort=display',
                resourceType: 'Concept',
                terminology: true,
            };
        },
        computedDpData() {
            let items = this.dpData.map((item) => new FHIRWorksTypes.Coding(item.toJSON()));
            return items.sort((a, b) => (a.display > b.display ? 1 : -1));
        },
        activeItems() {
            let items = this.modelValue.map((item, index) => {
                return { index: index, display: item.code.codingZero.display, code: item.code.codingZero.code };
            });
            return items.sort((a, b) => (a.display > b.display ? 1 : -1));
        },
    },
    methods: {
        async addItemDialog() {
            if (this.viewOnly) return;
            const options = {
                header: false,
                width: 400,
                fullscreen: true,
            };
            addRefToBackBtnListiner(this.$refs.addItemDialog, 'close', 'addItemDialog');
            this.$refs.addItemDialog.show(options).then((save) => {
                removeRefToBackBtnListiner('addItemDialog');
                if (!save || this.viewOnly) return;
                const list = [...this.modelValue];
                this.itemsToAdd.forEach((item) => {
                    list.push(
                        new PractitionerQualification({
                            code: {
                                coding: [item],
                            },
                        }),
                    );
                });
                this.$emit('update:modelValue', list);
            });
        },
        removeItem(item) {
            if (this.viewOnly) return;
            const list = this.modelValue.filter((i, index) => index !== item.index);
            this.$emit('update:modelValue', list);
        },
    },
};
</script>

<template>
    <div>
        <data-provider v-model="dpData" collection :query="dpDataQuery" />
        <bn-list-box
            :title="listBox.title"
            :items="activeItems"
            item-text="display"
            :item-icon="listBox.icon"
            inactive
            :add="viewOnly ? false : listBox.addLabel"
            add-icon="plus"
            :remove="!viewOnly"
            :clickable="!viewOnly"
            @add="addItemDialog"
            @remove="removeItem"
        />
        <bn-dialog ref="addItemDialog">
            <bn-list-select-box
                title="Select Qualifications"
                v-model="itemsToAdd"
                :items="computedDpData"
                :added-items="activeItems"
                item-text="display"
                item-value="code"
                :item-icon="listBox.icon"
                multiple
                :dialog="{ title: 'Qualifications', save: 'Add' }"
                @save="$refs.addItemDialog.confirm()"
                @cancel="$refs.addItemDialog.cancel()"
                @update:modelValue="itemsToAdd = $event"
            />
        </bn-dialog>
    </div>
</template>
