import { DomainResource } from './index';
import CodeableConcept from './types/CodeableConcept';
import Identifier from './types/Identifier';
import Reference from './types/Reference';
import Annotation from './types/Annotation';

import merge from 'lodash/merge';

const serviceRequestSystemStatusValues = {
    active: 'Active',
    completed: 'Completed',
    draft: 'Draft',
    'entered-in-error': 'Entered in Error',
    'on-hold': 'On Hold',
    revoked: 'Revoked',
    unknown: 'Unknown',
};

export default class ServiceRequest extends DomainResource {
    static __className = 'ServiceRequest';

    __objectStructure = {
        identifier: [Identifier],
        instantiatesCanonical: [String],
        instantiatesUri: [String],
        basedOn: [Reference],
        replaces: [Reference],
        requisition: Identifier,
        status: String, // draft | active | on-hold | revoked | completed | entered-in-error | unknown
        intent: String, // proposal | plan | directive | order | original-order | reflex-order | filler-order | instance-order | option
        category: [CodeableConcept],
        priority: String, // routine | urgent | asap | stat
        doNotPerform: Boolean,
        code: CodeableConcept,
        orderDetail: [CodeableConcept],
        // quantity[x]: valueType,
        subject: Reference,
        encounter: Reference,
        // occurrence[x]: valueType,
        // asNeeded[x]: valueType,
        authoredOn: String,
        requester: Reference,
        performerType: CodeableConcept,
        performer: [Reference],
        locationCode: [CodeableConcept],
        locationReference: [Reference],
        reasonCode: [CodeableConcept],
        reasonReference: [Reference],
        insurance: [Reference],
        supportingInfo: [Reference],
        specimen: [Reference],
        bodySite: [CodeableConcept],
        note: [Annotation],
        patientInstruction: String,
        relevantHistory: [Reference],
    };

    constructor(constructJson, className = 'ServiceRequest') {
        super(constructJson, className);

        this.createAndPopulateStructure(this.__objectStructure, constructJson);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}

export { serviceRequestSystemStatusValues, ServiceRequest };
