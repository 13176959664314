import { BackboneElement } from './index';
import Reference from './types/Reference';

import merge from 'lodash/merge';

export default class CoveragePayor extends BackboneElement {
    static __className = 'CoveragePayor';

    __objectStructure = {
        display: String,
        uri: String,
        bnPayorAddress: [Reference],
    };

    constructor(constructJson, className = 'CoveragePayor') {
        super(constructJson, className);

        this.createAndPopulateStructure(this.__objectStructure, constructJson);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
