import merge from 'lodash/merge';
import Identifier from './types/Identifier';
import ContactPoint from './ContactPoint';
import { DomainResource } from './index';
import Period from './types/Period';
import CodeableConcept from './types/CodeableConcept';
import Reference from './types/Reference';
import PractitionerRoleAvailableTime from './PractitionerRoleAvailableTime';
import PractitionerRoleNotAvailable from './PractitionerRoleNotAvailable';
import PractitionerRoleAccess from './PractitionerRoleAccess';

export default class PractitionerRole extends DomainResource {
    static __className = 'PractitionerRole';

    __objectStructure = {
        identifier: [Identifier], // Business Identifiers that are specific to a role/location
        active: Boolean, // Whether this practitioner role record is in active use
        period: Period, // The period during which the practitioner is authorized to perform in these role(s)
        practitioner: Reference, // Practitioner that is able to provide the defined services for the organization
        organization: Reference, // Organization where the roles are available
        code: [CodeableConcept], // Roles which this practitioner may perform
        specialty: [CodeableConcept], // Specific specialty of the practitioner
        location: [Reference], // The location(s) at which this practitioner provides care
        healthcareService: [Reference], // The list of healthcare services that this worker provides for this role's Organization/Location(s)
        telecom: [ContactPoint], // Contact details that are specific to the role/location/service
        availableTime: [PractitionerRoleAvailableTime], // Times the Service Site is available
        notAvailable: [PractitionerRoleNotAvailable], // Not available during this time due to provided reason
        availabilityExceptions: String, // Description of availability exceptions
        endpoint: [Reference], // Technical endpoints providing access to services operated for the practitioner with this role
        /**
         * First-class extensions
         */
        healthcareServiceAccess: [PractitionerRoleAccess],
    };

    constructor(constructJson, className = 'PractitionerRole') {
        super(constructJson, className);

        this.createAndPopulateStructure(this.__objectStructure, constructJson);

        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
