import { Element } from './index';
import merge from 'lodash/merge';
import ContactPoint from './ContactPoint';

export default class ContactDetail extends Element {
    static __className = 'ContactDetail';

    __objectStructure = {
        name: String,
        telecom: ContactPoint,
    };

    constructor(constructJson, className = 'ContactDetail') {
        super(constructJson, className);
        this.createAndPopulateStructure(this.__objectStructure, constructJson, this.__objectDefaults);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
