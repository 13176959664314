import { BackboneElement } from './index';
import merge from 'lodash/merge';
import Reference from './types/Reference';

export default class BN_LedgerTransaction extends BackboneElement {
    static __className = 'BN_LedgerTransaction';

    __objectStructure = {
        type: String,
        value: Number,
        date: Date,
        bnPayment: Reference,
        bnPostPayment: Reference,
        adjustment: Number,
    };

    constructor(constructJson, className = 'BN_LedgerTransaction') {
        super(constructJson, className);
        this.createAndPopulateStructure(this.__objectStructure, constructJson);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
