import { BackboneElement } from './index';
import Reference from './types/Reference';

import merge from 'lodash/merge';

export default class CoverageEligibilityRequestSupportingInfo extends BackboneElement {
    static __className = 'CoverageEligibilityRequestSupportingInfo';

    __objectStructure = {
        sequence: Number, // planned | active | reserved | completed
        information: Reference,
        appliesToAll: Boolean,
    };

    constructor(constructJson, className = 'CoverageEligibilityRequestSupportingInfo') {
        super(constructJson, className);
        this.createAndPopulateStructure(this.__objectStructure, constructJson);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
