import { BackboneElement } from './index';
import CodeableConcept from './types/CodeableConcept';

import merge from 'lodash/merge';
import Reference from "./types/Reference";

export default class ClinicalImpressionInvestigation extends BackboneElement {
    static __className = 'ClinicalImpressionInvestigation';

    __objectStructure = {
        code: CodeableConcept,
        item: [Reference], // Observation | QuestionnaireResponse | FamilyMemberHistory | DiagnosticReport | RiskAssessment | ImagingStudy | Media
    };

    constructor(constructJson, className = 'ClinicalImpressionInvestigation') {
        super(constructJson, className);

        this.createAndPopulateStructure(this.__objectStructure, constructJson);

        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
