import merge from 'lodash/merge';

import { BackboneElement } from './index';
import CodeableConcept from './types/CodeableConcept';
import Reference from './types/Reference';

export default class ListEntry extends BackboneElement {
    static __className = 'ListEntry';

    __objectStructure = {
        flag: CodeableConcept, // Status/Workflow information about this item
        deleted: Boolean, // If this item is actually marked as deleted
        date: String, // When item added to list
        item: Reference,
    };

    constructor(constructJson, className = 'ListEntry') {
        super(constructJson, className);
        this.createAndPopulateStructure(this.__objectStructure, constructJson);

        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
