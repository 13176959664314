import { DomainResource } from './index';
import merge from 'lodash/merge';
import Coding from './types/Coding';

export default class BN_EdiConfiguration extends DomainResource {
    static __className = 'BN_EdiConfiguration';

    __objectStructure = {
        tenantId: String,
        transactionType: String,
        recordsPerBatch: String,
        classifyBy: Coding,
        errornotifyEmail: [String],
        successnotifyEmail: [String],
    };

    constructor(constructJson) {
        super(constructJson);
        this.createAndPopulateStructure(this.__objectStructure, constructJson);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
