import { useAuthStore } from '@/stores/auth';
import timezoneApi from '@/common/api/timezone.api';

const USERSNAP_PROJECT_KEY = import.meta.env.VITE_USERSNAP_PROJECT_KEY;
const USERSNAP_GLOBAL_API_KEY = import.meta.env.VITE_USERSNAP_GLOBAL_KEY;

function showUsersnap() {
    const authStore = useAuthStore();
    // initialize Usersnap
    window.onUsersnapCXLoad = (api) => {
        api.init({
            user: { email: authStore.user.apiUser.email, userId: authStore.user.apiUser.id },
            nativeScreenshot: true,
            custom: {
                user: {
                    name: authStore.fullName,
                    email: authStore.user.apiUser.email,
                    isSupport: authStore.isBnSupportUser,
                },
                account: {
                    id: authStore.account.id,
                    name: authStore.account.name,
                    fhirUri: authStore.account.fhirApiUri,
                },
            },
        });

        api.on('beforeSubmit', (event) => {
            let date = new Date();

            event.api.setValue('custom', {
                ...event.values.custom,
                ...{
                    date: {
                        UTC: date.toUTCString(),
                        MT: timezoneApi.getLocalDateTime(date, 'yyyy-MM-dd HH:mm:ss.SSS', 'America/Boise'),
                    },
                },
            });
        });

        // open widget
        api.show(USERSNAP_PROJECT_KEY).then((widgetApi) => widgetApi.open());

        api.on('close', () => {
            if (this.$vuetify.display.xs) {
                api.hide(USERSNAP_PROJECT_KEY).then((widgetApi) => widgetApi.open());
            }
        });
    };

    // create global snippet
    const script = document.createElement('script');
    script.defer = 1;
    script.src = 'https://widget.usersnap.com/global/load/' + USERSNAP_GLOBAL_API_KEY + '?onload=onUsersnapCXLoad';
    document.getElementsByTagName('head')[0].appendChild(script);
}

function initializeUsersnap() {
    const authStore = useAuthStore();
    if (!(typeof window.onUsersnapCXLoad === 'function')) {
        // initialize Usersnap
        window.onUsersnapCXLoad = (api) => {
            api.init({
                user: { email: authStore.user.apiUser.email, userId: authStore.user.apiUser.id },
                nativeScreenshot: true,
                custom: {
                    user: {
                        name: authStore.fullName,
                        email: authStore.user.apiUser.email,
                        isSupport: authStore.isBnSupportUser,
                    },
                    account: {
                        id: authStore.account.id,
                        name: authStore.account.name,
                        fhirUri: authStore.account.fhirApiUri,
                    },
                },
            });

            api.on('beforeSubmit', (event) => {
                let date = new Date();

                event.api.setValue('custom', {
                    ...event.values.custom,
                    ...{
                        date: {
                            UTC: date.toUTCString(),
                            MT: timezoneApi.getLocalDateTime(date, 'yyyy-MM-dd HH:mm:ss.SSS', 'America/Boise'),
                        },
                    },
                });
            });
        };

        // create global snippet
        const script = document.createElement('script');
        script.defer = 1;
        script.src = 'https://widget.usersnap.com/global/load/' + USERSNAP_GLOBAL_API_KEY + '?onload=onUsersnapCXLoad';
        document.getElementsByTagName('head')[0].appendChild(script);
    }
}

export function useUsersnap() {
    return { showUsersnap, initializeUsersnap };
}
