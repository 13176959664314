import { DomainResource } from './index';
import merge from 'lodash/merge';
import BN_TreatmentPlanCategoryModality from '@/fhirworks/BN_TreatmentPlanCategoryModality';

export default class BN_TreatmentPlanLibraryCategory extends DomainResource {
    static _className = 'BN_TreatmentPlanLibraryCategory';

    _objectStructure = {
        name: String,
        modality: [BN_TreatmentPlanCategoryModality]
    };

    constructor(constructJson, className = 'BN_TreatmentPlanLibraryCategory') {
        super(constructJson, className);
        this.createAndPopulateStructure(this._objectStructure, constructJson, this._objectDefaults);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this._objectStructure));
    }
}
