import { DomainResource } from './index';
import merge from 'lodash/merge';
import Reference from './types/Reference';
import BN_LedgerTransaction_amountHistory from './BN_LedgerTransaction_amountHistory';

export default class BN_LedgerTransaction extends DomainResource {
    static __className = 'BN_LedgerTransaction';

    __objectStructure = {
        patient: Reference, // "Patient"
        superBill: Reference, // "BN_SuperBill"
        superBillLineItem: Reference, // "BN_SuperBillLineItem"
        date: String,
        description: String,
        type: String,
        amount: Number,
        balance: Number,
        amountHistory: [BN_LedgerTransaction_amountHistory],
    };

    constructor(constructJson, className = 'BN_LedgerTransaction') {
        super(constructJson, className);
        this.createAndPopulateStructure(this.__objectStructure, constructJson);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
