import { createApp } from 'vue';
import App from '@/App.vue';
import router from '@/router';
import '@/registerServiceWorker';

const app = createApp(App);

import { NativeEventSource, EventSourcePolyfill } from 'event-source-polyfill';

const EventSource = NativeEventSource || EventSourcePolyfill;
// OR: may also need to set as global property
window.EventSource = EventSource;

// Import global CSS
import '@ionic/core/css/core.css';
import '@ionic/core/css/ionic.bundle.css';

import 'nprogress/nprogress.css';
import '@/assets/scss/bn.scss';
import errorHandler from '@/common/errorHandler';

import { createPinia, PiniaVuePlugin } from 'pinia';
app.use(PiniaVuePlugin);
const pinia = createPinia();

// import { IonicVueRouter } from '@ionic/vue';
// Vue.use(IonicVueRouter);

// import VueRouter from 'vue-router';
// app.use(VueRouter);

// Import BnAPI
import api from '@/common/api';
app.use(api);

// Add our global directives
import BnGlobalDirectives from '@/directives';
app.use(BnGlobalDirectives);

import { autoAnimatePlugin } from '@formkit/auto-animate/vue';
app.use(autoAnimatePlugin);

// Vue Input Facade
import { plugin as InputFacade } from 'vue-input-facade';
app.use(InputFacade);

// Ionic
// if true(innerHTMLTemplatesEnabled) content passed to the relevant components will be parsed as HTML instead of plaintext.
import { IonicVue, IonPage, IonApp, IonContent, IonHeader, IonToolbar, IonFooter } from '@ionic/vue';
app.use(IonicVue);
app.component('IonPage', IonPage);
app.component('IonApp', IonApp);
app.component('IonContent', IonContent);
app.component('IonToolbar', IonToolbar);
app.component('IonHeader', IonHeader);
app.component('IonFooter', IonFooter);

// Vuelidate
// import { useVuelidate } from '@vuelidate/core';
// Vue.use(useVuelidate);

import bnVuelidateErrorExtractor from '@/plugins/bn-vuelidate-error-extractor';
app.use(bnVuelidateErrorExtractor);

import bnVuelidateRequired from '@/plugins/bn-vuelidate-required';
app.use(bnVuelidateRequired);

import Storage from 'vue-ls';
app.use(Storage);

// VueGAPI
import VueGAPI from 'vue-gapi';
const gApiConfig = {
    apiKey: import.meta.env.VITE_GOOGLE_API_KEY,
    clientId: import.meta.env.VITE_GOOGLE_CLIENT_ID + '.apps.googleusercontent.com',
    discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest'],
    scope: 'https://www.googleapis.com/auth/calendar.readonly',
    refreshToken: true,
};
app.use(VueGAPI, gApiConfig);

// FontAwesome
import { library } from '@fortawesome/fontawesome-svg-core';

import addBrandIcons from '@/icons-brands';
addBrandIcons(library);

import addSolidIcons from '@/icons-solid';
addSolidIcons(library);

import addRegularIcons from '@/icons-regular';
addRegularIcons(library);

import addDuotoneIcons from '@/icons-duotone';
addDuotoneIcons(library);

import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome';
import vuetify from '@/plugins/vuetify';

app.component('FontAwesomeIcon', FontAwesomeIcon);
app.component('FontAwesomeLayers', FontAwesomeLayers);
app.component('FontAwesomeLayersText', FontAwesomeLayersText);
// End FontAwesome

import { formatDate, formatTime, formatDatetime } from '@/filters/dateTimeFilters';
import { singleLineAddress } from '@/filters/addressFilters';
import { contactIcon, contactColor } from '@/filters/iconFilters';
import { getIconFromFileName } from '@/filters/fileFilters';

import * as stringFilters from 'vue2-filters/src/string/index';
import * as arrayFilters from 'vue2-filters/src/array/index';
import * as otherFilters from 'vue2-filters/src/other/index';

app.config.globalProperties.$filters = {
    formatDate,
    formatTime,
    formatDatetime,
    singleLineAddress,
    contactIcon,
    contactColor,
    getIconFromFileName,
    ...stringFilters,
    ...arrayFilters,
    ...otherFilters,
};

import mitt from 'mitt';
app.config.globalProperties.$eventBus = mitt();

import compileComponent from '@/components/CompileVue.vue';
app.component('CompileVue', compileComponent);

import BnDialog from '@/components/BnDialog.vue';
app.component('BnDialog', BnDialog);

import VueApexCharts from 'vue3-apexcharts';
app.use(VueApexCharts);

app.component('ApexChart', VueApexCharts);

import getDisplayMixin from '@/mixins/GetDisplayReferenceMixin.js';
app.mixin(getDisplayMixin);

import devOnlyMixin from '@/mixins/devOnlyMixin.vue';
app.mixin(devOnlyMixin);

if (import.meta.env.PROD || import.meta.env.VITE_DISABLE_LOGGING === false) {
    app.config.errorHandler = errorHandler.processError;
    app.config.warnHandler = errorHandler.processWarning;
} else {
    app.config.errorHandler = (err, instance, info) => {
        console.error(`[Global Error Handler] Error in ${info}: `, err);
    };
    app.config.warnHandler = (err, instance, info) => {
        console.warn(`[Global Warning Handler] Warning in ${info}: `, err);
    };
}

import { VCombobox, VTextField } from 'vuetify/components';
app.component('VTextField', VTextField);
app.component('VCombobox', VCombobox);

app.config.performance = true;
app.config.productionTip = false;
app.config.globalProperties.isPortal = window.page === 'portal';

import * as permissions from '@/common/permissionConstants';
app.config.globalProperties.permissions = permissions;

// Importing storage services
import DbVersionService from './stores/services/dbVersionService';
import StorageService from './stores/services/storageService';
import InitializeAppService from './stores/services/initializeAppService';
import { Capacitor } from '@capacitor/core';

const platform = Capacitor.getPlatform();

if (platform !== 'web') {
    // Define and instantiate the required services
    const dbVersionServ = new DbVersionService();
    const storageServ = new StorageService(dbVersionServ);
    // Set the Storage services as global properties on the app
    app.config.globalProperties.$storageServ = storageServ;
    //Define and instantiate the InitializeAppService
    let initAppServ = new InitializeAppService(storageServ);
    initAppServ
        .initializeApp()
        .then(() => {
            app.use(pinia).use(router).use(vuetify).mount('#app');
        })
        .catch((error) => {
            console.error('App Initialization error:', error);
        });
} else {
    app.use(pinia).use(router).use(vuetify).mount('#app');
}

if (window.Cypress) {
    window.buildNum = import.meta.env.VITE_BUILD_NUM || null;
    window.VueApp = app;
}
