import { Element } from './index';
import Period from './types/Period';
import merge from 'lodash/merge';

export default class Address extends Element {
    static __className = 'Address';

    __objectStructure = {
        use: String, // home | work | temp | old | billing - purpose of this address
        type: String, // postal | physical | both
        text: String, // Text representation of the address
        line: [String], // Street name, number, direction & P.O. Box etc.
        city: String, // Name of city, town etc.
        district: String, // District name (aka county)
        state: String, // Sub-unit of country (abbreviations ok)
        postalCode: String, // Postal code for area
        country: String, // Country (e.g. can be ISO 3166 2 or 3 letter code)
        period: Period, // Time period when address was/is in use
        /**
         * AidBox First Class extensions
         */
        preferred: Boolean,
    };

    __objectDefaults = {
        experimental: false,
    };

    constructor(constructJson, className = 'Address') {
        super(constructJson, className);
        this.createAndPopulateStructure(this.__objectStructure, constructJson, this.__objectDefaults);
        this.originalObjJson = this.toJSON();
    }

    // *************** Custom Getters/Setters ***************

    get line1() {
        if (this.line.length === 0) {
            return '';
        }
        return this.line[0] || '';
    }

    set line1(value) {
        if (value === '' || value === null) {
            this.line.splice(0, 1, undefined);
        } else {
            this.line.splice(0, 1, value);
        }
    }

    get line2() {
        if (this.line.length < 2) {
            return '';
        }
        return this.line[1] || '';
    }

    set line2(value) {
        if (value === '' || value === null) {
            if (this.line.length >= 2) {
                this.line.splice(1, 1, undefined);
            }
        } else {
            this.line.splice(1, 1, value);
        }
    }

    get addressDisplay() {
        let address = '';
        if (this.line[0]) address += this.line[0] + '\n';
        if (this.line[1]) address += this.line[1] + '\n';
        if (this.city) address += this.city;
        if (this.city && (this.state || this.postalCode || this.country)) address += ', ';
        if (this.state) address += this.state + ' ';
        if (this.postalCode) address += this.postalCode;
        return address;
    }

    // Methods

    getAddressLine(lineNumber = 0) {
        if (lineNumber > this.line.length) {
            return undefined;
        }

        return this.line[lineNumber];
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
