import { useWorkflow } from '@/common/workflowEngine/useWorkflow';
import { crud } from '@/common/crud';
import { Encounter, EpisodeOfCare, CareTeam, Reference } from '@/fhirworks';
import { v4 as uuidv4 } from 'uuid';
import { $httpFhirApi } from '@/common/api/httpFhir.service';

const { postTransaction, getReference } = useWorkflow();

const createEpisodeOfCare = async (data, existingTransaction = false) => {
    let transaction = existingTransaction || [];

    const episodeOfCare =
        data.episodeOfCare instanceof EpisodeOfCare
            ? data.episodeOfCare
            : new EpisodeOfCare({
                  id: uuidv4(),
                  status: data.status ? data.status : 'planned',
                  patient: { id: data.patient.id, resourceType: 'Patient' },
                  complaint: data.complaint,
                  encounter: data.encounter,
                  location: data.location,
                  managingOrganization: data.managingOrganization,
                  estPeriod: {
                    start: data.estPeriod.start,
                  },
              });

    if (data.complaint) {
        episodeOfCare.originalComplaint = data.complaint;
    }

    transaction.push(crud.post(episodeOfCare));

    transaction = await addEncounter(
        {
            episodeOfCare: episodeOfCare,
            program: data.program,
            location: data.location,
        },
        transaction,
    );

    transaction = await addPrimaryCareTeam(
        {
            episodeOfCare: episodeOfCare,
            careTeam: data.careTeam || {},
        },
        transaction,
    );

    if (existingTransaction === false) {
        return await postTransaction(transaction);
    }

    return transaction;
};

const updateEpisodeOfCare = async (data, existingTransaction = false) => {
    let transaction = existingTransaction || [];

    if (data.careTeam?.temp?.isNew) {
        transaction.push(crud.post(data.careTeam));
    } else if (data.careTeam) {
        transaction.push(crud.patch(data.careTeam));
    }

    if (data.careTeam) {
        data.episodeOfCare.team = getReference(data.careTeam);
    }

    if (data.status) {
        // set status and update the status history
        data.episodeOfCare.status = data.status;
        data.episodeOfCare.statusHistory.push({ status: data.status, period: { start: new Date().toISOString() } });
        // cancel or entered in error
        const resources = await $httpFhirApi.get('/Encounter?episode-of-care=' + data.episodeOfCare.id);
        resources.data.forEach((item) => {
            if (!['finished', 'cancelled', 'entered-in-error'].includes(item.status)) {
                item.status = data.status;
                transaction.push(crud.patch(item));
            }
        });
    }

    if (data.episodeOfCare?.temp?.isNew) {
        transaction.push(crud.post(data.episodeOfCare));
    } else if (data.episodeOfCare) {
        transaction.push(crud.patch(data.episodeOfCare));
    }

    if (existingTransaction === false) {
        return await postTransaction(transaction);
    }

    return transaction;
};

const deleteEpisodeOfCare = async (data, existingTransaction = false) => {
    let transaction = existingTransaction || [];

    // delete Encounters and all related resources (CareTeam)
    const resources = await $httpFhirApi.get('/Encounter?episode-of-care=' + data.episodeOfCare.id + '&_revinclude=CareTeam:encounter');
    resources.data.forEach((item) => {
        if (['Encounter', 'CareTeam'].includes(item.resourceType)) {
            transaction.push(crud.delete(item));
        }
    });
    // delete Primary CareTeam
    transaction.push(crud.delete(data.episodeOfCare.team[0]));
    // delete the EpisodeOfCare
    transaction.push(crud.delete(data.episodeOfCare));

    if (existingTransaction === false) {
        return await postTransaction(transaction);
    }

    return transaction;
};

const intakeEpisodeOfCare = async (data, existingTransaction = false) => {
    let transaction = existingTransaction || [];

    const timeStamp = new Date().toISOString();
    // update EpisodeOfCare
    data.episodeOfCare.status = 'active';
    data.episodeOfCare.statusHistory.push({ status: 'active', period: { start: timeStamp } });
    if (!data.episodeOfCare.period?.start) {
        data.episodeOfCare.period = { start: timeStamp, end: data.episodeOfCare.period?.end };
    }
    transaction.push(crud.patch(data.episodeOfCare));

    if (existingTransaction === false) {
        return await postTransaction(transaction);
    }

    return transaction;
};

const dischargeEpisodeOfCare = async (data, existingTransaction = false) => {
    let transaction = existingTransaction || [];

    const timeStamp = data.end || new Date().toISOString();
    // update EpisodeOfCare
    data.episodeOfCare.status = 'finished';
    data.episodeOfCare.statusHistory.push({ status: 'finished', period: { start: timeStamp } });
    data.episodeOfCare.period = { start: data.episodeOfCare.period?.start, end: timeStamp };
    transaction.push(crud.patch(data.episodeOfCare));

    // update Encounters if required
    const encounters = await $httpFhirApi.get('/Encounter?episode-of-care=' + data.episodeOfCare.id);
    encounters.data.forEach((item) => {
        // ignore encounters that are not ACTIVE or ONLEAVE
        if (!['arrived', 'in-progress', 'onleave'].includes(item.status)) return;
        // discharge encounter
        item.status = 'finished';
        item.statusHistory.push({ status: 'finished', period: { start: timeStamp } });
        item.period = { start: item.period?.start, end: timeStamp };
        transaction.push(crud.patch(item));
    });

    if (existingTransaction === false) {
        return await postTransaction(transaction);
    }

    return transaction;
};

const addEncounter = async (data, existingTransaction = false) => {
    let transaction = existingTransaction || [];

    const episodeOfCare = data.episodeOfCare instanceof EpisodeOfCare ? data.episodeOfCare : new EpisodeOfCare(data.episodeOfCare);

    // create Encounter: if already defined just use it
    const encounter =
        data.encounter instanceof Encounter
            ? data.encounter
            : // this is primarily used when creating a new Patient with an initial EpisodeOfCare/Program
              new Encounter({
                  id: uuidv4(),
                  class: { code: 'AMB' },
                  type: [{ text: 'PROGRAM' }],
                  episodeOfCare: [{ id: episodeOfCare.id, resourceType: 'EpisodeOfCare' }],
                  subject: episodeOfCare.patient,
                  healthcareService: data.program,
                  serviceProvider: episodeOfCare.managingOrganization,
                  location: [{ location: { id: data.location.id, resourceType: 'Location' } }],
                  status: 'planned',
                  statusHistory: [
                      {
                          status: 'planned',
                          period: { start: new Date().toISOString() },
                      },
                  ],
              });
    transaction.push(crud.post(encounter));

    if (existingTransaction === false) {
        return await postTransaction(transaction);
    }

    return transaction;
};

const updateEncounter = async (data, existingTransaction = false) => {
    let transaction = existingTransaction || [];

    transaction.push(crud.patch(data.encounter));

    if (existingTransaction === false) {
        return await postTransaction(transaction);
    }

    return transaction;
};

const deleteEncounter = async (data, existingTransaction = false) => {
    let transaction = existingTransaction || [];

    // delete Encounter
    transaction.push(crud.delete(data.encounter));

    if (existingTransaction === false) {
        return await postTransaction(transaction);
    }

    return transaction;
};

const intakeEncounter = async (data, existingTransaction = false) => {
    let transaction = existingTransaction || [];

    const timeStamp = new Date().toISOString();
    // update Encounter
    data.encounter.status = 'in-progress';
    data.encounter.statusHistory.push({ status: 'in-progress', period: { start: timeStamp } });
    data.encounter.period = { start: timeStamp };
    transaction.push(crud.patch(data.encounter));

    // set EpisodeOfCare to 'active' and set start date if blank
    let episodeOfCare = data.episodeOfCare;
    if (!episodeOfCare) {
        const eoc = await $httpFhirApi.get('/EpisodeOfCare?id=' + data.encounter.episodeOfCare.id);
        episodeOfCare = new EpisodeOfCare(eoc.data[0]);
    }
    episodeOfCare.status = 'active';
    if (!episodeOfCare.period?.start) {
        episodeOfCare.period = { start: timeStamp, end: episodeOfCare.period?.end };
    }
    transaction.push(crud.patch(episodeOfCare));

    if (existingTransaction === false) {
        return await postTransaction(transaction);
    }

    return transaction;
};

const dischargeEncounter = async (data, existingTransaction = false) => {
    let transaction = existingTransaction || [];

    const timeStamp = new Date().toISOString();
    // update Encounter
    data.encounter.status = 'finished';
    data.encounter.statusHistory.push({ status: 'finished', period: { start: timeStamp } });
    data.encounter.period = { start: data.encounter.period?.start, end: timeStamp };
    transaction.push(crud.patch(data.encounter));

    if (existingTransaction === false) {
        return await postTransaction(transaction);
    }

    return transaction;
};

const dischargeTransferEncounter = async (data, existingTransaction = false) => {
    let transaction = existingTransaction || [];

    const timeStamp = data.sourceEncounter.period.end || new Date().toISOString();
    // update the source encounter
    data.sourceEncounter.status = 'finished';
    data.sourceEncounter.statusHistory.push({ status: 'finished', period: { start: timeStamp } });
    data.sourceEncounter.period = { start: data.sourceEncounter.period.start, end: timeStamp };
    data.sourceEncounter.transferDestination = new Reference({ id: data.encounter.id, resourceType: 'Encounter' });
    transaction.push(crud.patch(data.sourceEncounter));

    // Create new encounter and intake if required
    if (data.encounter.temp?.intakeNow) {
        data.encounter.status = 'in-progress';
        data.encounter.statusHistory.push({ status: 'in-progress', period: { start: timeStamp } });
        data.encounter.period = { start: timeStamp };
    }
    data.encounter.transferOrigin = new Reference({ id: data.sourceEncounter.id, resourceType: 'Encounter' });
    transaction.push(crud.post(data.encounter));

    if (existingTransaction === false) {
        return await postTransaction(transaction);
    }

    return transaction;
};

const addPrimaryCareTeam = async (data, existingTransaction = false) => {
    let transaction = existingTransaction || [];

    // if fully qualified CareTeam then use it otherwise create a new one
    const careTeam =
        data.careTeam instanceof CareTeam
            ? data.careTeam
            : new CareTeam({
                  id: uuidv4(),
                  subject: data.episodeOfCare.patient,
                  participant: data.participant || undefined,
              });
    transaction.push(crud.post(careTeam));

    // add CareTeam to EpisodeOfCare
    const episodeOfCare = data.episodeOfCare instanceof EpisodeOfCare ? data.episodeOfCare : new EpisodeOfCare(data.episodeOfCare);
    episodeOfCare.team[0] = { id: careTeam.id, resourceType: 'CareTeam' };
    transaction.push(crud.patch(episodeOfCare));

    if (existingTransaction === false) {
        return await postTransaction(transaction);
    }

    return transaction;
};

export function useEpisodeOfCareFlow() {
    return {
        createEpisodeOfCare,
        updateEpisodeOfCare,
        deleteEpisodeOfCare,
        intakeEpisodeOfCare,
        dischargeEpisodeOfCare,
        addEncounter,
        updateEncounter,
        deleteEncounter,
        intakeEncounter,
        dischargeEncounter,
        dischargeTransferEncounter,
    };
}
