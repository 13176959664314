import { Element } from './index';
import merge from 'lodash/merge';
import Coding from './types/Coding';
import Reference from './types/Reference';
import ValueX from './types/ValueX';

export default class QuestionnaireAnswerOption extends Element {
    static __className = 'QuestionnaireAnswerOption';

    __objectStructure = {
        valueInteger: Number,
        valueDate: String,
        valueTime: String,
        valueString: String,
        valueCoding: Coding,
        valueReference: Reference,
        value: ValueX,
        initialSelected: Boolean,
        /**
         * First Class extensions
         */
        score: Number,
    };

    constructor(constructJson, className = 'QuestionnaireAnswerOption') {
        super(constructJson, className);
        this.createAndPopulateStructure(this.__objectStructure, constructJson, this.__objectDefaults);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
