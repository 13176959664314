import { BackboneElement } from './index';
import Coding from './types/Coding';
import Period from './types/Period';

import merge from 'lodash/merge';

export default class EncounterClassHistory extends BackboneElement {
    static __className = 'EncounterClassHistory';

    __objectStructure = {
        class: Coding, // inpatient | outpatient | ambulatory | emergency +
        period: Period,
    };

    constructor(constructJson, className = 'EncounterClassHistory') {
        super(constructJson, className);
        this.createAndPopulateStructure(this.__objectStructure, constructJson);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
