import colors from 'vuetify/lib/util/colors';
import { useAuthStore } from '@/stores/auth';

export const getLibraryUrl = () => {
    // if (import.meta.env.PROD) {
    return 'https://bnprofile.fhir.bestnotesfhir.com';
    // }

    // return 'http://bestnotesfresh.com:8890';
};

export const isLibraryDatabase = () => {
    const authStore = useAuthStore();
    return authStore.account.fhirApiUri?.includes('bnprofile.fhir.bestnotesfhir.com');
};

export const API_URL = 'https://www.googleapis.com/books/v1/volumes';
export const FHIR_DATE_FORMAT = 'yyyy-MM-dd';

export const ICD10_CURRENT_SYSTEM = 'http://code.system/ICD10-2023';

export const colorSwatches = [
    [colors.red.base, colors.red.darken1, colors.red.darken2, colors.red.darken3, colors.red.darken4],
    [colors.pink.base, colors.pink.darken1, colors.pink.darken2, colors.pink.darken3, colors.pink.darken4],
    [colors.purple.base, colors.purple.darken1, colors.purple.darken2, colors.purple.darken3, colors.purple.darken4],
    [colors.deepPurple.base, colors.deepPurple.darken1, colors.deepPurple.darken2, colors.deepPurple.darken3, colors.deepPurple.darken4],
    [colors.indigo.base, colors.indigo.darken1, colors.indigo.darken2, colors.indigo.darken3, colors.indigo.darken4],
    [colors.blue.base, colors.blue.darken1, colors.blue.darken2, colors.blue.darken3, colors.blue.darken4],
    [colors.cyan.base, colors.cyan.darken1, colors.cyan.darken2, colors.cyan.darken3, colors.cyan.darken4],
    [colors.teal.base, colors.teal.darken1, colors.teal.darken2, colors.teal.darken3, colors.teal.darken4],
    [colors.green.base, colors.green.darken1, colors.green.darken2, colors.green.darken3, colors.green.darken4],
    [colors.lime.base, colors.lime.darken1, colors.lime.darken2, colors.lime.darken3, colors.lime.darken4],
    [colors.yellow.base, colors.yellow.darken1, colors.yellow.darken2, colors.yellow.darken3, colors.yellow.darken4],
    [colors.amber.base, colors.amber.darken1, colors.amber.darken2, colors.amber.darken3, colors.amber.darken4],
    [colors.orange.base, colors.orange.darken1, colors.orange.darken2, colors.orange.darken3, colors.orange.darken4],
    [colors.brown.base, colors.brown.darken1, colors.brown.darken2, colors.brown.darken3, colors.brown.darken4],
    [colors.blueGrey.base, colors.blueGrey.darken1, colors.blueGrey.darken2, colors.blueGrey.darken3, colors.blueGrey.darken4],
];

export const COMMON_TIMEZONES = [
    { header: 'Common' },
    { timezone: 'America/Puerto_Rico' },
    { timezone: 'America/New_York' },
    { timezone: 'America/Chicago' },
    { timezone: 'America/Denver' },
    { timezone: 'America/Phoenix' },
    { timezone: 'America/Los_Angeles' },
    { timezone: 'America/Anchorage' },
    { timezone: 'Pacific/Honolulu' },
];
export const DEFAULT_TIMEZONES = ['America', 'Atlantic', 'Europe', 'Pacific'];
export const RESERVED_APPOINTMENT_TITLE = 'Appointment';
export const RESERVED_APPOINTMENT_ID = 'eca4263a-aa88-439b-bc1e-bcbb735c30e6';
export const EMERGENCY_CONTACT = 'Emergency contact';
export const NEXT_OF_KIN = 'Next of kin';
export const LEGAL_GUARDIAN = 'Legal guardian';

export const MODIFIER_CHIP_COLOR = {
    1: 'green-darken-1',
    2: 'blue-darken-1',
    3: 'orange-darken-1',
    4: 'deep-purple-darken-1',
    DEFAULT: 'grey-darken-1',
};

export const MODIFIER_TEXT_COLOR = {
    1: 'green--text darken-1',
    2: 'blue--text darken-1',
    3: 'orange--text darken-1',
    4: 'deep-purple--text darken-1',
    DEFAULT: 'grey--text darken-1',
};

export const EOC_STATUS_MAP = {
    planned: { value: 'Planned', modelValue: 'Planned', color: 'pending', icon: 'spinner', dark: true },
    active: { value: 'Active', modelValue: 'Active', verb: 'Begin', color: 'green-darken-4', icon: 'play-circle', dark: true },
    finished: { value: 'Closed', modelValue: 'Closed', verb: 'Close', color: 'primary', icon: 'check-circle', dark: true },
    'entered-in-error': { value: 'Entered-in-error', modelValue: 'Entered-in-error', color: 'error', icon: 'times-circle', dark: true },
    'on-hold': { value: 'On-hold', modelValue: 'On-hold', verb: 'Pause', color: 'orange-darken-2', icon: 'pause-circle', dark: true },
    cancelled: { value: 'Not qualified', modelValue: 'Not qualified', color: 'error', icon: 'ban', dark: true },
    waitlist: { value: 'Waitlist', modelValue: 'Waitlist', color: 'pending', icon: 'pause-circle', dark: true },
    delete: { value: 'Delete', modelValue: 'Delete', color: 'red', icon: 'trash', dark: true },
};
export const PROGRAM_STATUS_MAP = {
    planned: { value: 'Pre-Intake', modelValue: 'Pre-Intake', color: 'teal-darken-1', icon: 'spinner', dark: true },
    arrived: { value: 'Arrived', modelValue: 'Arrived', color: 'green', icon: 'dot-circle', dark: true },
    'in-progress': { value: 'Active', modelValue: 'Active', color: 'green-darken-4', icon: 'play-circle', dark: true },
    finished: { value: 'Discharged', modelValue: 'Discharged', color: 'primary', icon: 'check-circle', dark: true },
    'entered-in-error': { value: 'Entered-in-error', modelValue: 'Entered-in-error', color: 'error', icon: 'times-circle', dark: true },
    onleave: { value: 'On-leave', modelValue: 'On-leave', color: 'orange-darken-2', icon: 'pause-circle', dark: true },
    cancelled: { value: 'Canceled', modelValue: 'Canceled', color: 'red', icon: 'ban', dark: true },
    unknown: { value: 'Unknown', modelValue: 'Unknown', color: 'orange-darken-2', icon: 'pause-circle', dark: true },
    delete: { value: 'Delete', modelValue: 'Delete', color: 'red', icon: 'trash', dark: true },
    transfer: { value: 'Transfer', modelValue: 'Transfer', color: 'red', icon: 'arrow-circle-right', dark: true },
};
export const SYSTEM_ADMIN_ROLE_ID = 'aaa79ef5-a4c3-4de7-8ff2-c96f6c43daaa';

export const FORM_RESTRICTION_GROUPS = [
    { value: 'Patient', text: 'Patients', icon: 'heartbeat' },
    { value: 'Person', text: 'Individuals (non-patients)', icon: 'user' },
    { value: 'Practitioner', text: 'Professionals', icon: 'user-md' },
    { value: 'Organization', text: 'Business/Organizations', icon: 'university' },
    { value: 'CareTeam', text: 'Employees', icon: 'user-md' },
];

export const ENCOUNTER_STATUS_MAP = {
    'in-progress': { value: 'Active', color: 'success', icon: 'play-circle' },
    finished: { value: 'Concluded', color: 'info', icon: 'check' },
};
export default API_URL;
