import { BackboneElement } from './index';
import Reference from './types/Reference';
import CodeableConcept from './types/CodeableConcept';

import merge from 'lodash/merge';

export default class EncounterDiagnosis extends BackboneElement {
    static __className = 'EncounterDiagnosis';

    __objectStructure = {
        condition: Reference, // Condition | Procedure
        use: CodeableConcept, // DiagnosisRole
        rank: Number, // Ranking of the diagnosis (for each role type)
    };

    constructor(constructJson, className = 'EncounterDiagnosis') {
        super(constructJson, className);
        this.createAndPopulateStructure(this.__objectStructure, constructJson, this.__objectDefaults);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
