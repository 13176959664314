<script>
import BnToolbarBtn from '@/components/BnToolbarBtn.vue';
import BnLoading from '@/components/BnLoading.vue';
import BnContactEditOrganization from '@/components/contacts/BnContactEditOrganization.vue';
import BnContactEditHuman from '@/components/contacts/BnContactEditHuman.vue';
import DataProvider from '@/components/DataProvider.js';
import BnInfo from '@/components/BnInfo.vue';
import BnMenu from '@/components/BnMenu.vue';
import BnMenuItem from '@/components/BnMenuItem.vue';
import { Patient, Practitioner, Organization } from '@/fhirworks';
import createHuman from '@/common/createHuman';
import { isPerson } from '@/common/core';
import BnFooter from '@/components/BnFooter';
import { useNotificationStore } from '@/stores/notification';
import { useEpisodeOfCareFlow } from '@/common/workflowEngine/useEpisodeOfCareFlow';

export default {
    name: 'BnContactEditDialog',
    components: { BnMenuItem, BnMenu, BnInfo, BnLoading, DataProvider, BnContactEditHuman, BnContactEditOrganization, BnToolbarBtn, BnFooter },
    props: {
        authStore: Object,
        isWidget: Boolean,
    },
    emits: ['dataChanged', 'updateWidget'],
    data() {
        return {
            // dialog management props
            dialog: false,
            resolve: null,
            reject: null,
            options: null,
            baseOptions: {
                header: 'BestNotes',
                width: 500,
                persistent: true,
                info: undefined,
                warning: undefined,
            },
            props: undefined,

            // other props go here
            dpContact: undefined,
            validResource: false,
            contactTypes: {
                patient: 'patient',
                practitioner: 'professional',
                organization: 'organization',
            },
            loading: false,
            accessStatus: null,
            isPatientOrContact: null,
        };
    },
    mounted() {
        this.contactTypes.patient = this.authStore?.clientVocab.toLowerCase() || this.contactTypes.patient.toLowerCase();
    },
    computed: {
        existingContact() {
            return this.dpContact?.id;
        },
        header() {
            const action = this.dpContact?.id ? 'Edit' : 'Add';
            const target = isPerson(this.dpContact) ? 'contact' : this.contactTypes[this.dpContact?.resourceType?.toLowerCase()];
            return target ? action + ' ' + target : '';
        },
        dpContactQuery() {
            if (this.props.contact) {
                return { query: { resourceType: this.props.contact?.resourceType, id: this.props.contact?.id } };
            }
            // add new contact
            else if (this.props.add) {
                let newContact;

                if (this.props.add.type === 'organization') {
                    newContact = new Organization();
                } else if (this.props.add.type === 'practitioner') {
                    newContact = new Practitioner();
                } else if (this.props.add.type === 'patient') {
                    newContact = new Patient({
                        managingOrganization: {
                            id: this.authStore.organizationAccount.id,
                            resourceType: 'Organization',
                        },
                    });
                    newContact.addMrn(this.authStore.account.fhirApiUri);
                } else if (this.props.add.type === 'contact') {
                    newContact = new Patient();
                    newContact.addMrn(this.authStore.account.fhirApiUri);
                }
                // populate data based on search query
                createHuman(this.props.add.value, newContact);
                return { resource: newContact };
            }
            return {};
        },
    },
    methods: {
        checkAccess(accessStatus) {
            this.accessStatus = accessStatus;
            return this.accessStatus;
        },
        contactType(isPatientOrContact) {
            this.isPatientOrContact = isPatientOrContact;
            return this.isPatientOrContact;
        },
        show(options, props) {
            this.props = props;
            // set header
            this.options = { ...this.baseOptions, ...options };
            // display dialog
            this.dialog = true;
            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },
        hide() {
            this.dialog = false;
        },
        accountNotification(contact) {
            const notificationStore = useNotificationStore();
            if (!this.isPatientOrContact && contact === 'Patient') {
                contact = 'Contact';
            } else if (contact === 'Practitioner') {
                contact = 'Professional';
            }
            notificationStore.add({
                message: `${contact} saved successfully.`,
                color: 'info',
            });
        },
        async save() {
            this.loading = true;
            const newPatientEOC = !this.dpContact.id && this.dpContact.resourceType === 'Patient' ? this.dpContact.temp : undefined;
            await this.$refs.dpContact.save();

            // new Patient - create initial Episode of Care
            if (newPatientEOC?.program?.id) {
                const { createEpisodeOfCare } = useEpisodeOfCareFlow();
                await createEpisodeOfCare({
                    patient: this.dpContact,
                    complaint: newPatientEOC.complaint || 'Unknown',
                    program: newPatientEOC.program,
                    location: newPatientEOC.location,
                    status: newPatientEOC.status,
                    managingOrganization: newPatientEOC.location.managingOrganization,
                    Guarantor: {
                        display: 'Self',
                    },
                    estPeriod: {
                        start: newPatientEOC.eocEstStartDate
                    }
                });
            }

            if (this.props.addNewRelatedContact) {
                // Save the new relationship value
                let contactEditForm = this.$refs.contactEditHuman;
                if (this.dpContact.resourceType === 'organization') {
                    contactEditForm = this.$refs.contactEditOrganization;
                }
                await contactEditForm.saveRelationship(this.dpContact);
                this.$emit('dataChanged');
            }

            if (this.isWidget) {
                this.$emit('updateWidget', this.dpContact);
            }

            let contactType = this.dpContact.resourceType;

            if (!this.accessStatus && this.accessStatus !== null) {
                this.accountNotification(contactType);
                this.dialog = false;
                this.loading = false;
                return;
            }
            // send notification
            this.accountNotification(contactType);
            //return the dpContact
            return this.close(this.dpContact);
        },
        close(resolve) {
            this.resolve(resolve);
            this.dialog = false;
            this.options = undefined;
            this.loading = false;
        },
        validateResource(inValid) {
            this.validResource = !inValid;
        },
    },
};
</script>

<template>
    <div>
        <v-dialog v-if="options" v-model="dialog" scrollable :fullscreen="$vuetify.display.xs" :width="options.width" :persistent="options.persistent">
            <v-card flat class="d-flex flex-column">
                <div class="flex-grow-0">
                    <ion-header>
                        <ion-toolbar>
                            <v-toolbar density="compact" flat>
                                <v-toolbar-title data-cy="editContactDialogHeader">{{ header }}</v-toolbar-title>
                                <bn-toolbar-btn
                                    type="text"
                                    :icon="existingContact ? 'times' : 'trash'"
                                    :label="existingContact ? 'Close' : 'Discard'"
                                    @click="close"
                                    data-cy="contactEditDialogCloseBtn"
                                />
                                <bn-toolbar-btn
                                    type="outlined"
                                    right
                                    color="primary"
                                    :icon="loading ? 'spinner' : 'check'"
                                    label="Save"
                                    :disabled="!validResource"
                                    @click="save"
                                    data-cy="saveContactButton"
                                />
                                <bn-menu v-if="$vuetify.display.xs" offset-y left show-usersnap-option>
                                    <bn-menu-item icon="check" label="Save" :disabled="!validResource" @click="save" />
                                    <bn-menu-item icon="times" :label="existingContact ? 'Close' : 'Discard'" @click="close" />
                                </bn-menu>
                            </v-toolbar>
                        </ion-toolbar>
                    </ion-header>
                    <v-divider></v-divider>
                    <!-- Info/Warning message -->
                    <bn-info v-if="options.info" type="outlined">{{ options.info }}</bn-info>
                    <bn-info v-if="options.warning" type="outlined" color="warning">{{ options.warning }}</bn-info>
                </div>
                <data-provider ref="dpContact" v-model="dpContact" v-bind="dpContactQuery">
                    <template #loading>
                        <bn-loading card skeleton="article@3" class="pa-4" />
                    </template>
                    <template #default>
                        <div data-cy="newRelatedContactInfo" class="pa-4 scroll-on">
                            <bn-contact-edit-organization
                                v-if="dpContact.resourceType === 'Organization'"
                                ref="contactEditOrganization"
                                :resource="dpContact"
                                :relationship-props="props"
                                :auth-store="authStore"
                                @validate="validateResource"
                            />
                            <bn-contact-edit-human
                                v-else
                                ref="contactEditHuman"
                                :resource="dpContact"
                                :relationship-props="props"
                                :auth-store="authStore"
                                :is-widget="isWidget"
                                @show-access="checkAccess"
                                @validate="validateResource"
                                @is-patient-or-contact="contactType"
                            />
                        </div>
                    </template>
                </data-provider>
                <!-- ios ample touch footer bar -->
                <bn-footer class="dialog-footer"></bn-footer>
            </v-card>
        </v-dialog>
    </div>
</template>
