import { BaseElement } from '../index';
import merge from 'lodash/merge';
import AuthorX from '../types/AuthorX';
import get from 'lodash/get';

/**
 * @property {AuthorX} author
 * @property {String} time
 * @property {String} text
 */
export default class Annotation extends BaseElement {
    static __className = 'Annotation';

    __objectStructure = {
        author: AuthorX,
        time: String,
        text: String,
    };

    constructor(constructJson, className = 'Annotation') {
        super(constructJson, className);

        let fhirAuthorReference = get(constructJson, 'authorReference', undefined);
        if (fhirAuthorReference) {
            this.authorReference = fhirAuthorReference;
        }

        let fhirAuthorString = get(constructJson, 'authorString', undefined);
        if (fhirAuthorString) {
            this.authorString = fhirAuthorString;
        }

        this.createAndPopulateStructure(this.__objectStructure, constructJson, this.__objectDefaults);
        this.originalObjJson = this.toJSON();
    }

    get authorReference() {
        // eslint-disable-next-line security/detect-object-injection
        if (!this.author) {
            return undefined;
        }
        return this.author.authorReference;
    }
    set authorReference(value) {
        // eslint-disable-next-line security/detect-object-injection
        if (!this.author) {
            this.author = new AuthorX();
        }
        this.author.authorReference = value;
    }

    get authorString() {
        // eslint-disable-next-line security/detect-object-injection
        if (!this.author) {
            return undefined;
        }
        return this.author.authorString;
    }
    set authorString(value) {
        // eslint-disable-next-line security/detect-object-injection
        if (!this.author) {
            this.author = new AuthorX();
        }
        this.author.authorString = value;
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
