import { DomainResource } from './index';
import merge from 'lodash/merge';
import Coding from './types/Coding';
import Reference from './types/Reference';

export default class BN_PaymentTransaction extends DomainResource {
    static __className = 'BN_PaymentTransaction';

    __objectStructure = {
        BN_Payment: Reference,
        BN_ERA: Reference,
        payor: Reference,
        patient: Reference,
        BN_PostPayment: Reference,
        explanationOfBenefit: Reference,
        account: Reference,
        totalAmount: Number,
        amount: Number,
        transactionDateTime: String,
        transactionType: Coding,
        responsibility: Coding,
        paymentType: Coding,
        paymentMode: Coding,
    };

    constructor(constructJson, className = 'BN_PaymentTransaction') {
        super(constructJson, className);
        this.createAndPopulateStructure(this.__objectStructure, constructJson);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
