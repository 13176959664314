import { BackboneElement } from './index';
import CodeableConcept from './types/CodeableConcept';
import AllowedX from './types/AllowedX';
import UsedX from './types/UsedX';

import merge from 'lodash/merge';
import get from 'lodash/get';
import CoverageEligibilityResponseInsuranceItemBenefitEffectivePeriod from './CoverageEligibilityResponseInsuranceItemBenefitEffectivePeriod';

export default class CoverageEligibilityResponseInsuranceItemBenefit extends BackboneElement {
    static __className = 'CoverageEligibilityResponseInsuranceItemBenefit';

    __objectStructure = {
        type: CodeableConcept,
        allowed: AllowedX,
        used: UsedX,
        message: [String],
        benefitPeriod: [CoverageEligibilityResponseInsuranceItemBenefitEffectivePeriod],
        healthcareServicesDelivery: [String],
    };

    constructor(constructJson, className = 'CoverageEligibilityResponseInsuranceItemBenefit') {
        super(constructJson, className);

        this.createAndPopulateStructure(this.__objectStructure, constructJson);

        let fhirAllowedUnsignedInt = get(constructJson, 'allowedUnsignedInt', undefined);
        if (fhirAllowedUnsignedInt) {
            this.allowedUnsignedInt = fhirAllowedUnsignedInt;
        }

        let fhirAllowedString = get(constructJson, 'allowedString', undefined);
        if (fhirAllowedString) {
            this.allowedString = fhirAllowedString;
        }

        let fhirAllowedMoney = get(constructJson, 'allowedMoney', undefined);
        if (fhirAllowedMoney) {
            this.allowedMoney = fhirAllowedMoney;
        }

        let fhirUsedUnsignedInt = get(constructJson, 'usedUnsignedInt', undefined);
        if (fhirUsedUnsignedInt) {
            this.usedUnsignedInt = fhirUsedUnsignedInt;
        }

        let fhirUsedString = get(constructJson, 'usedString', undefined);
        if (fhirUsedString) {
            this.usedString = fhirUsedString;
        }

        let fhirUsedMoney = get(constructJson, 'usedMoney', undefined);
        if (fhirUsedMoney) {
            this.usedMoney = fhirUsedMoney;
        }

        this.originalObjJson = this.toJSON();
    }

    get allowedUnsignedInt() {
        if (!this.allowed) {
            return undefined;
        }
        return this.allowed.allowedUnsignedInt;
    }

    set allowedUnsignedInt(value) {
        if (!this.allowed) {
            this.allowed = new AllowedX();
        }
        this.allowed.allowedUnsignedInt = value;
    }

    get allowedString() {
        if (!this.allowed) {
            return undefined;
        }
        return this.allowed.allowedString;
    }

    set allowedString(value) {
        if (!this.allowed) {
            this.allowed = new AllowedX();
        }
        this.allowed.allowedString = value;
    }

    get allowedMoney() {
        if (!this.allowed) {
            return undefined;
        }
        return this.allowed.allowedMoney;
    }

    set allowedMoney(value) {
        if (!this.allowed) {
            this.allowed = new AllowedX();
        }
        this.allowed.allowedMoney = value;
    }

    get usedUnsignedInt() {
        if (!this.used) {
            return undefined;
        }
        return this.used.usedUnsignedInt;
    }

    set usedUnsignedInt(value) {
        if (!this.used) {
            this.used = new UsedX();
        }
        this.used.usedUnsignedInt = value;
    }

    get usedString() {
        if (!this.used) {
            return undefined;
        }
        return this.used.usedString;
    }

    set usedString(value) {
        if (!this.used) {
            this.used = new UsedX();
        }
        this.used.usedString = value;
    }

    get usedMoney() {
        if (!this.used) {
            return undefined;
        }
        return this.used.usedMoney;
    }

    set usedMoney(value) {
        if (!this.used) {
            this.used = new UsedX();
        }
        this.used.usedMoney = value;
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
