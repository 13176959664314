import { BackboneElement } from './index';
import CodeableConcept from './types/CodeableConcept';
import Reference from './types/Reference';
import get from 'lodash/get';
import Period from './types/Period';
import merge from 'lodash/merge';
import Identifier from './types/Identifier';

export default class PractitionerQualification extends BackboneElement {
    static __className = 'PractitionerQualification';

    constructor(constructorJson, className = 'PractitionerQualification') {
        super(constructorJson, className);

        this.__identifier = [];
        let identifierInfo = get(constructorJson, 'identifier', []);
        identifierInfo.forEach((identifierItem) => {
            this.__identifier.push(new Identifier(identifierItem));
        });

        this.__code = new CodeableConcept(get(constructorJson, 'code', undefined));

        this.__period = new Period(get(constructorJson, 'period', undefined));

        this.__issuer = new Reference(get(constructorJson, 'issuer', undefined));

        this.originalObjJson = this.toJSON();
    }

    // Properties

    get identifier() {
        return this.__identifier || [];
    }

    set identifier(value) {
        if (Array.isArray(value)) {
            this.__identifier = value;
            return;
        }

        if (value instanceof Identifier) {
            this.__identifier.push(value);
            return;
        }

        if (value === undefined) {
            this.__identifier = [];
            return;
        }

        this.__identifier.push(new Identifier(value));
    }

    get code() {
        return this.__code;
    }

    set code(value) {
        if (value instanceof CodeableConcept) {
            this.__code = value;
            return;
        }

        this.__code = value !== undefined ? new CodeableConcept(value) : undefined;
    }

    get period() {
        return this.__period;
    }

    set period(value) {
        if (value instanceof Period) {
            this.__period = value;
            return;
        }

        this.__period = value !== undefined ? new Period(value) : undefined;
    }

    get issuer() {
        return this.__issuer;
    }

    set issuer(value) {
        if (value instanceof Reference) {
            this.__issuer = value;
            return;
        }

        this.__issuer = value !== undefined ? new Reference(value) : undefined;
    }

    // Methods

    toJSON() {
        return merge(
            super.toJSON(this),
            this.removeUndefinedObjProps({
                identifier: this.arrayPropertyToJson(this.identifier),
                code: this.propertyToJson(this.code),
                period: this.propertyToJson(this.period),
                issuer: this.propertyToJson(this.issuer),
            }),
        );
    }
}
