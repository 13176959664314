import { BackboneElement } from './index';
import CodeableConcept from './types/CodeableConcept';

import merge from 'lodash/merge';

export default class CoverageClass extends BackboneElement {
    static __className = 'CoverageClass';

    __objectStructure = {
        type: CodeableConcept,
        value: String,
        name: String,
    };

    constructor(constructJson, className = 'CoverageClass') {
        super(constructJson, className);

        this.createAndPopulateStructure(this.__objectStructure, constructJson);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
