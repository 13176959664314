import { BackboneElement } from './index';
import merge from 'lodash/merge';

export default class ValueSetComposeIncludeFilter extends BackboneElement {
    static __className = 'ValueSetComposeIncludeFilter';

    __objectStructure = {
        property: String, // !R A property/filter defined by the code system
        op: String, // !R = | is-a | descendent-of | is-not-a | regex | in | not-in | generalizes | exists
        value: String, // !R Code from the system, or regex criteria, or boolean value for exists
    };

    constructor(constructJson, className = 'ValueSetComposeIncludeFilter') {
        super(constructJson, className);
        this.createAndPopulateStructure(this.__objectStructure, constructJson);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
