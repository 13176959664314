import merge from 'lodash/merge';
import { DomainResource } from './index';
import CodeableConcept from './types/CodeableConcept';
import Coding from './types/Coding';
import Identifier from './types/Identifier';
import Reference from './types/Reference';
import Period from './types/Period';
import Money from './types/Money';
import ClaimRelated from './ClaimRelated';
import ClaimPayee from './ClaimPayee';
import ClaimCareTeam from './ClaimCareTeam';
import ClaimDiagnosis from './ClaimDiagnosis';
import ClaimInsurance from './ClaimInsurance';
import ClaimAccident from './ClaimAccident';
import ClaimItem from './ClaimItem';
import ClaimQualifiers from './ClaimQualifiers';

export default class Claim extends DomainResource {
    static __className = 'Claim';

    __objectStructure = {
        identifier: [Identifier],
        status: String, // active | cancelled | draft | entered-in-error
        type: CodeableConcept,
        subType: CodeableConcept,
        use: String, // claim | preauthorization | predetermination
        patient: Reference,
        billablePeriod: Period,
        created: String,
        enterer: Reference,
        insurer: Reference,
        provider: Reference,
        priority: CodeableConcept,
        // fundsReserve: CodeableConcept,
        related: [ClaimRelated],
        prescription: Reference,
        originalPrescription: Reference,
        payee: ClaimPayee,
        referral: Reference,
        facility: Reference,
        careTeam: [ClaimCareTeam],
        // supportingInfo: [],
        diagnosis: [ClaimDiagnosis],
        // procedure: [],
        insurance: [ClaimInsurance],
        accident: ClaimAccident,
        item: [ClaimItem],
        total: Money,
        /**
         * AidBox First Class extensions
         */
        dateOfServiceStart: String,
        dateOfServiceEnd: String,
        generationDate: String,
        submittedOn: String,
        feeSchedule: String,
        placeOfService: Coding,
        transferTo: Coding,
        claimStatus: Coding,
        emergencyIndicator: Boolean,
        specialProgramCode: Coding,
        delayReasonCode: Coding,
        claimCode: Coding,
        outSideLab: Boolean,
        outSideLabCharge: Coding,
        resubmissionCode: Coding,
        originalReferenceNumber: String,
        serviceAuthorizationExceptionCode: Coding,
        priorAuthorizationNumber: String,
        EPSDT: Boolean,
        reasonCode: Coding,
        familyPlanning: Boolean,
        noteQualifier: Coding,
        additionalClaimInformation: Coding,
        noteInformation: String,
        paperQualifier: Coding,
        reportTypeCode: Coding,
        transmissionTypeCode: Coding,
        paperInformation: String,
        supplementalInformationQualifier: Coding,
        supplementalInformation: String,
        mammographyCertificationNumber: String,
        medicalRecordNumber: Reference,
        referringProvider: Reference,
        renderingProvider: Reference,
        supervisingProvider: Reference,
        orderingProvider: Reference,
        patientOnLeave: Period,
        payerClaimControlNumber: String,
        Claimqualifierstype: Coding,
        claimIdentifierForTransmissionIntermediaries: String,
        itemsuperbillLineItem: Reference,
        CLIANumber: String,
        superbill: Reference,
        qualifiers: [ClaimQualifiers],
        patientBalance: Number,
        insuranceBalance: Number,
        insurancePriority: CodeableConcept,
        charge: Money,
        otherAccident: Boolean,
        employment: Boolean,
        information: String,
        errorMessage: String,
        CMS1500PatientAccountNumber: String,
    };

    constructor(constructJson, className = 'Claim') {
        super(constructJson, className);
        this.createAndPopulateStructure(this.__objectStructure, constructJson);

        this.populateCustomPropertiesFromJson(constructJson, []);

        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
