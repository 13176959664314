import { BaseElement } from '../index';
import merge from 'lodash/merge';

/**
 * @property {String} start
 * @property {String} end
 */
export default class Period extends BaseElement {
    static __className = 'Period';

    __objectStructure = {
        start: String,
        end: String,
    };

    constructor(constructJson, className = 'Period') {
        super(constructJson, className);

        this.createAndPopulateStructure(this.__objectStructure, constructJson, this.__objectDefaults);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
