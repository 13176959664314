import { BackboneElement } from './index';
import CodeableConcept from './types/CodeableConcept';

import merge from 'lodash/merge';
import Reference from "./types/Reference";

export default class ClinicalImpressionFinding extends BackboneElement {
    static __className = 'ClinicalImpressionFinding';

    __objectStructure = {
        itemCodeableConcept: CodeableConcept,
        itemReference: Reference, // Condition | Observation | Media
        basis: String,
    };

    constructor(constructJson, className = 'ClinicalImpressionFinding') {
        super(constructJson, className);

        this.createAndPopulateStructure(this.__objectStructure, constructJson);

        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
