<script setup>
/**
 * TODO: Some nice things to do:
 * -- add 'clearable' property similar to v-text-field to delete content
 */
import { ref, defineProps, defineEmits, computed, onMounted, onBeforeUnmount, watch, defineExpose } from 'vue';
import { Editor, EditorContent } from '@tiptap/vue-3';
import { mergeAttributes } from '@tiptap/core';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import TextAlign from '@tiptap/extension-text-align';
import Heading from '@tiptap/extension-heading';
import Paragraph from '@tiptap/extension-paragraph';
import OrderedList from '@tiptap/extension-ordered-list';
import BulletList from '@tiptap/extension-bullet-list';

import { stripTags } from '@/common/core';
import debounce from 'lodash/debounce';

const emit = defineEmits(['focus', 'focused', 'blur', 'setRTE', 'update:modelValue']);

const props = defineProps({
    label: String,
    prompt: String,
    modelValue: String,
    disabled: Boolean,
    printView: Boolean,
    autofocus: Boolean,
    placeholder: String,
    outlined: Boolean,
    flat: Boolean,
    isLabel: Boolean,
    toolbar: {
        type: Array,
        default: function () {
            return ['bold', 'italic', 'underline', 'text-align', 'heading'];
        },
    },
    errorMessages: Array,
});

const focused = ref(false);
const inputFocused = ref(false);
const editor = ref(null);
const isEmpty = ref(true);

const mainRef = ref(null);
const elWidth = ref(null);

const resizeObserver = new ResizeObserver(
    debounce(
        () => {
            elWidth.value = Math.min(mainRef.value?.getBoundingClientRect().width, mainRef.value?.children[0]?.getBoundingClientRect().width);
        },
        100,
        { maxWait: 300 },
    ),
);

onMounted(() => {
    editor.value = new Editor({
        autofocus: props.autofocus ? 'end' : false,
        content: props.modelValue || props.placeholder,
        editable: !props.disabled,
        enableInputRules: false,
        extensions: [
            StarterKit.configure({
                heading: false,
                paragraph: false,
                orderedList: false,
                bulletList: false,
                history: {
                    newGroupDelay: 250,
                },
            }),

            Paragraph.configure().extend({
                renderHTML({ HTMLAttributes }) {
                    let pClassObject = { class: 'text-body-1' };
                    if (props.isLabel) {
                        pClassObject = {};
                    }

                    return [`p`, mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, pClassObject), 0];
                },
            }),
            Heading.configure({ levels: [1, 2, 3, 4, 5, 6] }).extend({
                levels: [1, 2, 3, 4, 5, 6],
                renderHTML({ node, HTMLAttributes }) {
                    const level = this.options.levels.includes(node.attrs.level) ? node.attrs.level : this.options.levels[0];
                    return [
                        `h${level}`,
                        mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
                            class: `text-h${level}`,
                        }),
                        0,
                    ];
                },
            }),
            TextAlign.configure({
                types: ['heading', 'paragraph'],
            }),
            Underline,
            BulletList.configure({
                HTMLAttributes: {
                    class: 'ml-4',
                },
                itemTypeName: 'listItem',
            }),
            OrderedList.configure({
                HTMLAttributes: {
                    class: 'ml-4',
                },
                itemTypeName: 'listItem',
            }),
        ],
        onFocus: () => {
            if (props.disabled) {
                editor.value.setOptions({ editable: false });
                return;
            } else editor.value.setOptions({ editable: true });
            setEditor(editor.value);
            focused.value = true;
            inputFocused.value = true;
            emit('focused');
        },
        onBlur: ({ event }) => {
            inputFocused.value = false;
            emit('blur');
            // focus is on another editor
            if (event.relatedTarget?.editor || event.relatedTarget?.className.includes('ProseMirror')) {
                setEditor(null);
                return;
            }

            // ignore targets that are RTE related (toolbar buttons, etc.)
            if (window.keepRTEOpen || event.relatedTarget?.className.includes('rte-')) {
                return;
            }
            /**
             * Timeout below is a workaround to support Safari since it does not recognize
             * blur => target correctly (event.relatedTarget)
             */
            setTimeout(() => {
                if (window.keepRTEOpen) {
                    window.keepRTEOpen = false;
                    return;
                }
                // all other targets we want to lose focus
                setEditor(null);
                focused.value = false;
            }, 200);
        },
        onUpdate: () => {
            saveContents();
        },
    });
    if (stripTags(props.modelValue)) isEmpty.value = false;

    resizeObserver.observe(mainRef.value);
});

onBeforeUnmount(() => {
    editor.value?.destroy();
    if (mainRef.value) {
        resizeObserver.unobserve(mainRef.value);
    }
});

const slotLabel = computed(() => {
    if (!mainRef.value) return '';
    if (props.prompt) return props.label || '';

    let textWidth = getTextWidth(props.label, getCanvasFont(mainRef.value));

    if (textWidth + 10 > elWidth.value) {
        return null;
    }

    return props.label || '';
});

const slotPrompt = computed(() => {
    if (props.prompt) return props.prompt;

    if (!slotLabel.value) {
        return props.label || '';
    }

    return null;
});

watch(
    () => props.modelValue,
    (newVal, oldVal) => {
        if (stripTags(newVal)) isEmpty.value = false;

        editor.value.setOptions({ editable: !props.disabled });

        if (newVal !== oldVal && newVal !== editor.value.getHTML()) {
            editor.value.commands.setContent(newVal);
        }
    },
);
watch(
    () => props.disabled,
    (newVal) => {
        editor.value.setOptions({ editable: !newVal });
    },
);
const errorState = computed(() => {
    return props.errorMessages?.length > 0;
});

const labelStyling = computed(() => {
    if (!props.label) return '';
    let transition = 'transition-duration: 0.15s; transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1); transition-property: font-size, top;';
    let style = transition + 'position: relative; top: 35px;';

    if (!isEmpty.value || inputFocused.value || props.printView) {
        style = transition + 'position: relative; top: 21px; font-size: 0.75rem; font-weight: 400;';
    }

    return style;
});

const promptStyling = computed(() => {
    return slotLabel.value ? 'margin-bottom: -15px;' : 'margin-bottom: 0;';
});

const setEditor = (editor) => {
    if (props.disabled) return;
    if (!editor) {
        emit('blur');
        focused.value = false;
    } else {
        emit('focus');
    }
    emit('setRTE', { editor: editor, toolbar: props.toolbar });
};

const saveContents = () => {
    let contents = editor.value.getHTML();
    // clear empty contents - if only tags remain
    if (!stripTags(contents) || contents === props.placeholder) {
        contents = undefined;
        isEmpty.value = true;
    } else {
        isEmpty.value = false;
    }

    // push contents to parent
    emit('update:modelValue', contents);
};

// insert text at the cursor (cannot insert HTML)
const insertText = (text) => {
    if (!text) return;
    const transaction = editor.value.state.tr.insertText(text);
    editor.value.view.dispatch(transaction);
};

const resetKeepRTEOpen = () => {
    window.keepRTEOpen = false;
};

const getTextWidth = (text, font) => {
    // re-use canvas object for better performance
    const canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement('canvas'));
    const context = canvas.getContext('2d');
    context.font = font;
    const metrics = context.measureText(text);
    return metrics.width;
};

const getCssStyle = (element, prop) => {
    return window.getComputedStyle(element, null).getPropertyValue(prop);
};

const getCanvasFont = (el = document.body) => {
    const fontWeight = getCssStyle(el, 'font-weight') || 'normal';
    const fontSize = getCssStyle(el, 'font-size') || '16px';
    const fontFamily = getCssStyle(el, 'font-family') || 'Times New Roman';

    return `${fontWeight} ${fontSize} ${fontFamily}`;
};

defineExpose({ insertText, editor });
</script>

<template>
    <div>
        <slot name="top-toolbar"></slot>
        <div
            class="rte-editor"
            :class="{
                'rte-editor-focused': focused,
                'rte-editor-outlined': outlined,
                'rte-editor-flat': flat,
                'rte-editor-error': errorState,
                'mb-2': errorState,
                'mb-4': !errorState,
            }"
            style="color: black;"
            :style="!slotPrompt && !slotLabel ? '' : 'margin-top: -22px;'"
            @click="resetKeepRTEOpen"
        >
            <div v-if="slotPrompt" class="bn-prompt text-wrap mt-6" :style="promptStyling" v-html="slotPrompt"></div>
            <label v-if="slotLabel" class="v-label ml-4 mb-1" :class="{ 'text-red': errorState }" v-html="slotLabel" :style="labelStyling"></label>
            <div ref="mainRef">
                <editor-content :editor="editor" data-cy="formFieldLabel" class="rte-editor-content" style="padding-top: 2px;" />
            </div>
            <div v-if="errorState" class="bn-label red-text mt-1" v-html="errorState ? errorMessages[0] : '&nbsp;'"></div>
        </div>
    </div>
</template>
