import { BackboneElement } from './index';
import CodeableConcept from './types/CodeableConcept';
import merge from 'lodash/merge';


export default class FamilyMemberHistoryCondition extends BackboneElement {
    static __className = 'FamilyMemberHistoryCondition';

    __objectStructure = {
        code: CodeableConcept,
    };

    __objectDefaults = {
        status: 'draft',
    };

    constructor(constructJson, className = 'FamilyMemberHistoryCondition') {
        super(constructJson, className);
        this.createAndPopulateStructure(this.__objectStructure, constructJson, this.__objectDefaults);

        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
