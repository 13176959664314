import { BaseElement } from '../index';
import merge from 'lodash/merge';

/**
 * @property {String} contentType // Mime type of the content, with charset etc.
 * @property {String} language // Human language of the content (BCP-47)
 * @property {String} data // Data inline, base64ed
 * @property {String} url // Uri where the data can be found
 * @property {Number} size // Number of bytes of content (if url provided)
 * @property {String} hash // Hash of the data (sha-1, base64ed)
 * @property {String} title // Label to display in place of the data
 * @property {String} creation // Date attachment was first created
 */
export default class Attachment extends BaseElement {
    static __className = 'Attachment';

    __objectStructure = {
        contentType: String, // Mime type of the content, with charset etc.
        language: String, // Human language of the content (BCP-47)
        data: String, // Data inline, base64ed
        url: String, // Uri where the data can be found
        size: Number, // Number of bytes of content (if url provided)
        hash: String, // Hash of the data (sha-1, base64ed)
        title: String, // Label to display in place of the data
        creation: String, // Date attachment was first created
    };

    constructor(constructJson, className = 'Attachment') {
        super(constructJson, className);

        this.createAndPopulateStructure(this.__objectStructure, constructJson, this.__objectDefaults);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
