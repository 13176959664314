import { ref, watch, onUnmounted } from 'vue';

import { parseISO, addMilliseconds, differenceInMilliseconds } from 'date-fns';
import { useAuthStore } from '@/stores/auth';
import router from '@/router';
import { useRoute } from 'vue-router';
import Storage from 'vue-ls';
import { storeToRefs } from 'pinia';
import VueCookies from 'vue-cookies';

export function useLockout() {
    const lockOutTimer = ref(null);
    const lockOutCountDown = ref(null);

    const authStore = useAuthStore();
    const route = useRoute();

    const { ls } = Storage.useStorage({
        namespace: '',
        name: 'ls',
        storage: 'local',
    });

    let lockoutInterval;
    let tokenRefreshTimeout;

    resetLockoutTimer();
    resetTokenRefreshTimer();

    clearInterval(lockoutInterval);
    lockoutInterval = setInterval(() => {
        let timeToLockout = authStore.lockoutLastActivityTime;
        timeToLockout = addMilliseconds(timeToLockout, authStore.lockoutTime);
        if (timeToLockout.getTime() < Date.now()) {
            startLockoutTimer();
        }
    }, 2500);

    function watchCookie(cookieToWatch, callback, timeout = 3500) {
        var checkTokenCookie = (function () {
            var lastCookie = VueCookies.get(cookieToWatch); // 'static' memory between function calls

            return function () {
                var currentCookie = VueCookies.get(cookieToWatch);

                if (currentCookie != lastCookie) {
                    callback(currentCookie, lastCookie);

                    lastCookie = currentCookie; // store latest cookie
                }
            };
        })();
        window.setInterval(checkTokenCookie, timeout); // run every 3.5 seconds
    }

    // token NEEDS to be in cookie for security reasons.  There is no good way to watch the cookie so here we poll for the token changes in cookie
    watchCookie('token', (token) => {
        if (token) {
            let isLocked = authStore.isLockedOut; // have to check before setting token
            authStore.setToken(token);

            if (isLocked) {
                router.push(authStore.lockoutPage);
            }
        } else if (route && !['lockout', 'invalid-tab'].includes(route.name)) {
            executeLockout();
        }
    });

    ls.on('lastTokenRefreshTime', (lastTokenRefreshTime) => {
        authStore.setLastTokenRefreshTime(parseISO(lastTokenRefreshTime));
    });
    ls.on('lockoutLastActivityTime', (lockoutLastActivityTime) => {
        clearTimeout(lockOutTimer.value);
        lockOutTimer.value = null;
        lockOutCountDown.value = null;
        authStore.setLockoutLastActivityTime(parseISO(lockoutLastActivityTime));
    });
    // lockOutCountDown has to be in a cookie because it doesn't work otherwise
    watchCookie(
        'lockOutCountDown',
        (value) => {
            if (!lockOutTimer.value) {
                lockOutCountDown.value = value;
            }
        },
        750,
    );

    watchCookie('user', (user) => {
        if (!user) {
            router.push({ name: 'login' });
        }
    });
    watchCookie('account', (account) => {
        if (!account) {
            router.push({ name: 'login' });
        } else if (account?.id !== authStore.account.id) {
            router.push({ name: 'invalid-tab' });
        }
    });

    onUnmounted(() => {
        clearInterval(lockoutInterval);
        clearTimeout(authStore.tokenRefreshTimer);

        ls.off('lastTokenRefreshTime', (lastTokenRefreshTime) => authStore.setLastTokenRefreshTime(parseISO(lastTokenRefreshTime)));
        ls.off('lockoutLastActivityTime', (lockoutLastActivityTime) => {
            clearTimeout(lockOutTimer.value);
            lockOutTimer.value = null;
            lockOutCountDown.value = null;
            authStore.setLockoutLastActivityTime(parseISO(lockoutLastActivityTime));
        });
    });

    const { isAuthenticated } = storeToRefs(authStore);
    watch(isAuthenticated, (val) => {
        if (!val) {
            return;
        }

        resetLockoutTimer();
        resetTokenRefreshTimer();
    });

    function startLockoutTimer() {
        // if not logged in do nothing
        if (!authStore.isAuthenticated || authStore.isLockedOut || !authStore.lockoutLastActivityTime) {
            authStore.resetLockoutActivityTime();
            return;
        }

        // reset the global timer
        authStore.resetLockoutActivityTime();

        // open lockout snackbar
        lockOutCountDown.value = 30;
        VueCookies.set('lockOutCountDown', 30);

        // count down to auto-lock
        lockOutTimer.value = setInterval(() => {
            if (lockOutCountDown.value === null) return;
            lockOutCountDown.value -= 1;
            VueCookies.set('lockOutCountDown', lockOutCountDown.value);
            if (lockOutCountDown.value < 1) {
                lockOutCountDown.value = null;
                VueCookies.remove('lockOutCountDown');
                executeLockout();
            }
        }, 1000);
    }

    function resetLockoutTimer() {
        clearTimeout(lockOutTimer.value);
        lockOutTimer.value = null;
        lockOutCountDown.value = null;
        VueCookies.remove('lockOutCountDown');
        authStore.resetLockoutActivityTime();
    }

    function executeLockout() {
        resetLockoutTimer();
        // if not logged in do nothing
        if (!authStore.isAuthenticated || authStore.isLockedOut || !authStore.lockoutLastActivityTime) {
            return;
        }
        // navigate to lockout page
        router.push({ name: 'lockout' });
    }

    function resetTokenRefreshTimer() {
        clearTimeout(tokenRefreshTimeout);
        let timerTimeout = authStore.tokenRefreshTime;

        if (authStore.lastTokenRefreshTime) {
            let refreshTimeAgo = differenceInMilliseconds(new Date(), authStore.lastTokenRefreshTime);

            // timeout is the timerTimeout minus the time ago it was last refreshed
            timerTimeout = timerTimeout - refreshTimeAgo;
            // make sure timerTimeout isn't negative, if so set to 0
            timerTimeout = timerTimeout < 0 ? 0 : timerTimeout;
        }

        tokenRefreshTimeout = setTimeout(async () => {
            await authStore.refreshAuthToken();
            resetTokenRefreshTimer();
        }, timerTimeout);
    }

    return { resetLockoutActivityTime: authStore.resetLockoutActivityTime, lockOutCountDown, resetLockoutTimer };
}
