import { BackboneElement } from './index';
import CodeableConcept from './types/CodeableConcept';
import LocationX from './types/LocationX';

import merge from 'lodash/merge';
import get from 'lodash/get';

export default class ClaimAccident extends BackboneElement {
    static __className = 'ClaimAccident';

    __objectStructure = {
        date: String,
        type: CodeableConcept,
        location: LocationX,
    };

    constructor(constructJson, className = 'ClaimAccident') {
        super(constructJson, className);

        this.createAndPopulateStructure(this.__objectStructure, constructJson);

        let fhirLocationReference = get(constructJson, 'locationReference', undefined);
        if (fhirLocationReference) {
            this.locationReference = fhirLocationReference;
        }

        this.originalObjJson = this.toJSON();
    }

    get locationReference() {
        if (!this.location) {
            return undefined;
        }
        return this.location.locationReference;
    }
    set locationReference(value) {
        if (!this.location) {
            this.location = new LocationX();
        }
        this.location.locationReference = value;
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
