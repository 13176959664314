import { DomainResource } from './index';
import Reference from './types/Reference';
import merge from 'lodash/merge';
import { contactPointTraits } from './trait/contactPointTraits';
import CodeableConcept from '@/fhirworks/types/CodeableConcept';
import Period from '@/fhirworks/types/Period';
import Identifier from '@/fhirworks/types/Identifier';
import ConsentProvision from '@/fhirworks/ConsentProvision';
import Coding from '@/fhirworks/types/Coding';
import ConsentRevoke from '@/fhirworks/ConsentRevoke';

export default class Consent extends DomainResource {
    static __className = 'Consent';

    __objectStructure = {
        identifier: [Identifier],
        status: String, // *required draft | proposed | active | rejected | inactive | entered-in-error
        scope: CodeableConcept, // Which of the four areas this resource covers (extensible)
        category: [CodeableConcept], // Classification of the consent statement - for indexing/retrieval
        patient: Reference, // Who this Consent is for
        dateTime: String, // When this Consent was created or indexed
        provision: ConsentProvision, // Constraints to the base Consent.policyRule

        // first class extension
        period: Period,
        periodOtherReason: String,
        grantor: [Reference],
        grantee: [Reference],
        decision: CodeableConcept,
        disclosureFormat: [Coding],
        substanceAbuse: Boolean,
        substanceAbuseDateTo: String,
        substanceAbuseDateFrom: String,
        substanceAbuseText: String,
        hivTesting: Boolean,
        hivTestingDateTo: String,
        hivTestingDateFrom: String,
        hivTestingText: String,
        reproductiveHealth: Boolean,
        reproductiveHealthDateTo: String,
        reproductiveHealthDateFrom: String,
        reproductiveHealthText: String,
        mentalHealth: Boolean,
        mentalHealthDateTo: String,
        mentalHealthDateFrom: String,
        mentalHealthText: String,
        revoke: ConsentRevoke,
        customExpiration: Boolean,
        preparedBy: Reference,
    };

    constructor(constructJson, className = 'Consent') {
        super(constructJson, className);

        contactPointTraits.call(this);

        this.createAndPopulateStructure(this.__objectStructure, constructJson, this.__objectDefaults);

        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
