// pro regular icons (far)
import {
    faAt,
    faAddressBook,
    faAddressCard,
    faAlignCenter,
    faAlignJustify,
    faAlignLeft,
    faAlignRight,
    faAnalytics,
    faAngleDown,
    faAngleLeft,
    faAngleUp,
    faAngleRight,
    faArchive,
    faArrowDown,
    faArrowFromLeft,
    faArrowToRight,
    faArrowLeft,
    faArrowRight,
    faArrowsAlt,
    faArrowAltRight,
    faArrowCircleRight,
    faArrowTrendUp,
    faArrowTrendDown,
    faArrowsToLine,
    faArrowUp,
    faAsterisk,
    faBadge,
    faBadgeCheck,
    faBallot,
    faBars,
    faBan,
    faBell,
    faBook,
    faBold,
    faBookmark,
    faBookBookmark,
    faBookMedical,
    faBracketsCurly,
    faBriefcase,
    faBroomWide,
    faBullhorn,
    faBullseye,
    faBuilding,
    faCakeCandles,
    faCalculatorSimple,
    faCalendar,
    faCalendarAlt,
    faCalendarCheck,
    faCalendarClock,
    faCalendarDay,
    faCalendarEdit,
    faCalendarExclamation,
    faCalendarLines,
    faCalendarMinus,
    faCalendarPlus,
    faCalendarStar,
    faCalendarTimes,
    faCalendarWeek,
    faCamera,
    faCaretSquareDown,
    faCheck,
    faCheckSquare,
    faCheckCircle,
    faChevronDoubleDown,
    faChevronDoubleUp,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faChevronUp,
    faCircle,
    faCircleBolt,
    faCircleNotch,
    faClipboardListCheck,
    faClock,
    faCloudUpload,
    faCloudDownload,
    faCode,
    faCodeBranch,
    faCog,
    faColumns,
    faCommentAlt,
    faCommentAltLines,
    faCommentAltSmile,
    faCommentCheck,
    faCommentEdit,
    faCommentLines,
    faCommentQuote,
    faCommentSlash,
    faCommentsQuestionCheck,
    faCompressAlt,
    faCopy,
    faDiagnoses,
    faDial,
    faDialMax,
    faDialMedLow,
    faDialMin,
    faDirections,
    faDollarSign,
    faDoorClosed,
    faDoorOpen,
    faDotCircle,
    faDna,
    faEdit,
    faEllipsisV,
    faEngineWarning,
    faEnvelope,
    faExclamationCircle,
    faExclamationTriangle,
    faExpandAlt,
    faExternalLink,
    faEye,
    faEyeSlash,
    faFax,
    faFileAlt,
    faFileCertificate,
    faFileChartLine,
    faFileCheck,
    faFileCircleCheck,
    faFileCircleXmark,
    faFileContract,
    faFileDownload,
    faFileEdit,
    faFileExcel,
    faFileImage,
    faFileWord,
    faFile,
    faFiles,
    faFilePdf,
    faFilePowerpoint,
    faFileVideo,
    faFileImport,
    faFileInvoiceDollar,
    faFileMedicalAlt,
    faFilePlus,
    faFileSearch,
    faFileSlash,
    faFileSignature,
    faFileTimes,
    faFilter,
    faFingerprint,
    faFlagCheckered,
    faFolder,
    faFolderArrowDown,
    faFolderImage,
    faFolderPlus,
    faFolders,
    faFont,
    faGavel,
    faGlasses,
    faGlobe,
    faGlobeAmericas,
    faGripLines,
    faGripVertical,
    faHandHoldingHeart,
    faHandHoldingUsd,
    faHandshake,
    faHandDots,
    faHandsUsd,
    faHashtag,
    faHdd,
    faH1,
    faH2,
    faH3,
    faH4,
    faHeartbeat,
    faHistory,
    faHome,
    faHomeLgAlt,
    faHorizontalRule,
    faHouseUser,
    faHourglass,
    faHospitals,
    faIdBadge,
    faIdCard,
    faInboxIn,
    faInboxOut,
    faInfoCircle,
    faItalic,
    faLanguage,
    faLaptopCode,
    faLayerGroup,
    faLayerPlus,
    faLevelDown,
    faLevelUp,
    faLineColumns,
    faLink,
    faLinkSlash,
    faList,
    faListAlt,
    faListUl,
    faListOl,
    faLaptopHouse,
    faLockAlt,
    faLocationDot,
    faKey,
    faMagic,
    faMailbox,
    faMap,
    faMapMarkedAlt,
    faMapMarkerAlt,
    faMapMarkerCheck,
    faMapMarkerEdit,
    faMapMarkerPlus,
    faMedkit,
    faMemoCircleInfo,
    faMessageMedical,
    faMinusCircle,
    faMinusSquare,
    faMobile,
    faMoneyCheckAlt,
    faNoteMedical,
    faNotesMedical,
    faObjectGroup,
    faPager,
    faPalette,
    faPaperclip,
    faPaperPlane,
    faParagraph,
    faPauseCircle,
    faPen,
    faPencil,
    faPenRuler,
    faPeopleArrows,
    faPeopleGroup,
    faPeopleSimple,
    faPhone,
    faPhoneOffice,
    faPhoneRotary,
    faPills,
    faPlateUtensils,
    faPlay,
    faPlayCircle,
    faPlus,
    faPortalEnter,
    faPortalExit,
    faPrint,
    faQuestionCircle,
    faQuoteLeft,
    faQuoteRight,
    faQuotes,
    faRadar,
    faRandom,
    faReceipt,
    faRectangleHistoryCircleUser,
    faRectangleLandscape,
    faRedo,
    faRegistered,
    faRepeat,
    faReplyClock,
    faRightLeftLarge,
    faRoute,
    faSackXmark,
    faSave,
    faSearch,
    faSearchMinus,
    faSearchPlus,
    faShieldCheck,
    faShieldExclamation,
    faSignature,
    faSignatureLock,
    faSignOutAlt,
    faSitemap,
    faSlidersH,
    faSort,
    faSortNumericUpAlt,
    faSpinner,
    faSquare,
    faStar,
    faStepBackward,
    faStepForward,
    faStopCircle,
    faStopwatch20,
    faStreetView,
    faStrikethrough,
    faSync,
    faTag,
    faTags,
    faTasks,
    faTally,
    faText,
    faTextSize,
    faThList,
    faTimelineArrow,
    faTimes,
    faTimesCircle,
    faToggleOn,
    faToggleOff,
    faTransporter2,
    faTrash,
    faTrashAlt,
    faTrees,
    faTruckMedical,
    faUmbrella,
    faUnderline,
    faUndo,
    faUniversity,
    faUnlock,
    faUnlockAlt,
    faUpload,
    faUsbDrive,
    faUser,
    faUserChart,
    faUserCheck,
    faUserCog,
    faUserDoctor,
    faUserDoctorMessage,
    faUserEdit,
    faUserFriends,
    faUserGraduate,
    faUserLock,
    faUserMd,
    faUserPlus,
    faUserShield,
    faUserTag,
    faUserTie,
    faUserUnlock,
    faUsers,
    faUsersClass,
    faUsersCog,
    faUsersMedical,
    faUsersRectangle,
    faWandMagicSparkles,
    faClipboardList,
    faListTimeline,
} from '@fortawesome/pro-regular-svg-icons';

export default (library) => {
    library.add(
        faAt,
        faAddressBook,
        faAddressCard,
        faAlignCenter,
        faAlignJustify,
        faAlignLeft,
        faAlignRight,
        faAnalytics,
        faAngleDown,
        faAngleLeft,
        faAngleRight,
        faAngleUp,
        faArchive,
        faArrowDown,
        faArrowFromLeft,
        faArrowToRight,
        faArrowLeft,
        faArrowRight,
        faArrowsAlt,
        faArrowAltRight,
        faArrowCircleRight,
        faArrowTrendUp,
        faArrowTrendDown,
        faArrowsToLine,
        faArrowUp,
        faAsterisk,
        faBadge,
        faBadgeCheck,
        faBallot,
        faBars,
        faBan,
        faBell,
        faBold,
        faBook,
        faBookmark,
        faBookBookmark,
        faBookMedical,
        faBracketsCurly,
        faBriefcase,
        faBroomWide,
        faBullhorn,
        faBullseye,
        faBuilding,
        faCakeCandles,
        faCalculatorSimple,
        faCalendar,
        faCalendarAlt,
        faCalendarCheck,
        faCalendarClock,
        faCalendarDay,
        faCalendarEdit,
        faCalendarExclamation,
        faCalendarLines,
        faCalendarMinus,
        faCalendarPlus,
        faCalendarStar,
        faCalendarTimes,
        faCalendarWeek,
        faCamera,
        faCaretSquareDown,
        faCheck,
        faCheckSquare,
        faCheckCircle,
        faChevronDoubleDown,
        faChevronDoubleUp,
        faChevronDown,
        faChevronLeft,
        faChevronRight,
        faChevronUp,
        faCircle,
        faCircleBolt,
        faCircleNotch,
        faClipboardListCheck,
        faClock,
        faCloudUpload,
        faCloudDownload,
        faCode,
        faCodeBranch,
        faCog,
        faColumns,
        faCommentAlt,
        faCommentAltLines,
        faCommentAltSmile,
        faCommentCheck,
        faCommentEdit,
        faCommentLines,
        faCommentQuote,
        faCommentSlash,
        faCommentsQuestionCheck,
        faCompressAlt,
        faCopy,
        faDiagnoses,
        faDial,
        faDialMax,
        faDialMedLow,
        faDialMin,
        faDirections,
        faDollarSign,
        faDoorClosed,
        faDoorOpen,
        faDotCircle,
        faDna,
        faEdit,
        faEllipsisV,
        faEngineWarning,
        faEnvelope,
        faExclamationCircle,
        faExclamationTriangle,
        faExpandAlt,
        faExternalLink,
        faEye,
        faEyeSlash,
        faFax,
        faFileAlt,
        faFileCertificate,
        faFileChartLine,
        faFileCheck,
        faFileCircleCheck,
        faFileCircleXmark,
        faFileContract,
        faFileDownload,
        faFileEdit,
        faFileExcel,
        faFileImage,
        faFileWord,
        faFile,
        faFiles,
        faFilePdf,
        faFilePowerpoint,
        faFileVideo,
        faFileImport,
        faFileInvoiceDollar,
        faFileMedicalAlt,
        faFilePlus,
        faFileSearch,
        faFileSlash,
        faFileSignature,
        faFileTimes,
        faFilter,
        faFingerprint,
        faFlagCheckered,
        faFolder,
        faFolderArrowDown,
        faFolderImage,
        faFolderPlus,
        faFolders,
        faFont,
        faGavel,
        faGlasses,
        faGlobe,
        faGlobeAmericas,
        faGripLines,
        faGripVertical,
        faHandHoldingHeart,
        faHandHoldingUsd,
        faHandshake,
        faHandDots,
        faHandsUsd,
        faHashtag,
        faHdd,
        faH1,
        faH2,
        faH3,
        faH4,
        faHeartbeat,
        faHistory,
        faHome,
        faHomeLgAlt,
        faHorizontalRule,
        faHouseUser,
        faHourglass,
        faHospitals,
        faIdBadge,
        faIdCard,
        faInboxIn,
        faInboxOut,
        faInfoCircle,
        faItalic,
        faLanguage,
        faLaptopCode,
        faLayerGroup,
        faLayerPlus,
        faLevelDown,
        faLevelUp,
        faLineColumns,
        faLink,
        faLinkSlash,
        faList,
        faListAlt,
        faListTimeline,
        faListUl,
        faListOl,
        faLaptopHouse,
        faLockAlt,
        faLocationDot,
        faKey,
        faMagic,
        faMailbox,
        faMap,
        faMapMarkedAlt,
        faMapMarkerAlt,
        faMapMarkerCheck,
        faMapMarkerEdit,
        faMapMarkerPlus,
        faMedkit,
        faMemoCircleInfo,
        faMessageMedical,
        faMinusCircle,
        faMinusSquare,
        faMobile,
        faMoneyCheckAlt,
        faNoteMedical,
        faNotesMedical,
        faObjectGroup,
        faPager,
        faPalette,
        faPaperclip,
        faPaperPlane,
        faParagraph,
        faPauseCircle,
        faPen,
        faPencil,
        faPenRuler,
        faPeopleArrows,
        faPeopleGroup,
        faPeopleSimple,
        faPhone,
        faPhoneOffice,
        faPhoneRotary,
        faPills,
        faPlateUtensils,
        faPlay,
        faPlayCircle,
        faPlus,
        faPortalEnter,
        faPortalExit,
        faPrint,
        faQuestionCircle,
        faQuoteLeft,
        faQuoteRight,
        faQuotes,
        faRadar,
        faRandom,
        faReceipt,
        faRectangleHistoryCircleUser,
        faRectangleLandscape,
        faRedo,
        faRegistered,
        faRepeat,
        faReplyClock,
        faRightLeftLarge,
        faRoute,
        faSackXmark,
        faSave,
        faSearch,
        faSearchMinus,
        faSearchPlus,
        faShieldCheck,
        faShieldExclamation,
        faSignature,
        faSignatureLock,
        faSignOutAlt,
        faSitemap,
        faSlidersH,
        faSort,
        faSortNumericUpAlt,
        faSpinner,
        faSquare,
        faStar,
        faStepBackward,
        faStepForward,
        faStopCircle,
        faStopwatch20,
        faStreetView,
        faStrikethrough,
        faSync,
        faTag,
        faTags,
        faTally,
        faTasks,
        faText,
        faTextSize,
        faThList,
        faTimelineArrow,
        faTimes,
        faTimesCircle,
        faToggleOn,
        faToggleOff,
        faTransporter2,
        faTrash,
        faTrashAlt,
        faTrees,
        faTruckMedical,
        faUmbrella,
        faUnderline,
        faUndo,
        faUniversity,
        faUnlock,
        faUnlockAlt,
        faUpload,
        faUsbDrive,
        faUser,
        faUserChart,
        faUserCheck,
        faUserCog,
        faUserDoctor,
        faUserDoctorMessage,
        faUserEdit,
        faUserFriends,
        faUserGraduate,
        faUserLock,
        faUserMd,
        faUserPlus,
        faUserShield,
        faUserTag,
        faUserTie,
        faUserUnlock,
        faUsers,
        faUsersClass,
        faUsersCog,
        faUsersMedical,
        faUsersRectangle,
        faWandMagicSparkles,
        faClipboardList,
    );
};
